import { DurationFormFields } from "../../../../types/formFieldTypes"

export const durationValidate = (values: DurationFormFields) => {
    const errors = {} as { [key: string]: string }
    if (!values.name) errors.name = 'Введите название'
    return errors
}

export const initialDurationFields = {
    name: ''
}

export const clearDurationErrors = {
    name: ''
}
