import React, { useMemo, useState } from 'react'
import NavbarLayout from '../../../components/layout/NavbarLayout/NavbarLayout'
import './TrainingStreamsPage.scss'
import SoloCollapse from '../../../components/ui/SoloCollapse/SoloCollapse';
import {
    ColumnsType,
} from 'antd/lib/table/interface';
import TableObjects from '../../../components/ui/TableObjects/TableObjects';
import { useTableObject } from '../../../utils/hooks/useTableObject';
import { addCollapseIcon, editCollapseIcon } from '../../../components/ui/SoloCollapse/components/icons';
import TableActionButtons from '../../../components/ui/TableObjects/components/TableActionButtons/TableActionButtons';
import { useMutation, useQuery } from 'react-query';
import { CityObj, CourseWithSummaryObj, EducationCourseObj, ObjDB, TrainingStreamObj } from '../../../types/apiTypes';
import { api } from '../../../api/api';
import { getSelectedItems } from '../../../utils/parsers';
import { openNotification, tableLinkId } from '../../../utils/jsxUtils';
import { RouteComponentProps, useParams } from 'react-router';
import { errorNotification, invalidateQueries, optionsQueryGetAll, queryOptionsPreset } from '../../../utils/rest';
import { sortByDate } from '../../../utils/form';
import TrainingStreamsAddForm from './components/TrainingStreamsAddForm/TrainingStreamsAddForm';
import TrainingStreamsEditForm from './components/TrainingStreamsEditForm/TrainingStreamsEditForm';
import ParsedDate from '../../../components/table/ParsedDate/ParsedDate';
import { filterTableLink, sortTableLinkByName } from '../../../utils/table';
import { SwitchTrainingStreamsItems } from '../../../types/propsTypes';
import { queryKeysTrainingStreams } from '../../../static/data/queryKeys';
import useScrollMount from '../../../utils/hooks/useScrollMount';
import { trainingStreamsHelpers } from './formData/trainingStreamsFieldHepler';
import FieldHelper from '../../../components/common/FieldHelper/FieldHelper';

interface TrainingStreamsPageProps extends RouteComponentProps {}

type TableItem = ObjDB<TrainingStreamObj>

const TrainingStreamsPage: React.FC<TrainingStreamsPageProps> = () => {

    const [openedAddForm, setOpenedAddForm] = useState<boolean>(false)
    const [openedEditForm, setOpenedEditForm] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<TableItem | null>(null)
    const [tableHeadLoader, setTableHeadLoader] = useState<boolean>(false)

    const params = useParams<{ id: string }>()

    const {
        selectedRowKeys,
        searchText,
        setSelectedRowKeys,
        getColumnSearchProps,
    } = useTableObject({initialSearchText: params.id})

    const queryCourse = useQuery<CourseWithSummaryObj[], Error, ObjDB<CourseWithSummaryObj>[]>(
        queryKeysTrainingStreams[0],
        api.courses.getAll,
        { ...optionsQueryGetAll },
    )

    const queryCity = useQuery<undefined, Error, ObjDB<CityObj>[]>(
        queryKeysTrainingStreams[1],
        api.cities.getAll,
        { ...optionsQueryGetAll }
    )

    const queryEducationCourse = useQuery<undefined, Error, ObjDB<EducationCourseObj>[]>(
        queryKeysTrainingStreams[3],
        api.educationCourse.getAll,
        { ...optionsQueryGetAll }
    )

    const switchData: SwitchTrainingStreamsItems = {
        courses: queryCourse.data || [],
        cities: queryCity.data || [],
        educationCourses: queryEducationCourse.data || [],
    }

    const queryAll = useQuery<TableItem[], Error, TableItem[]>(
        queryKeysTrainingStreams[2],
        api.trainingStreams.getAll,
        {
            onError: err => errorNotification(err, 'Ошибка при получении списка потоков направлений'),
            ...queryOptionsPreset,
        }
    )

    const queryDelete = useMutation(
        'training-stream-delete',
        api.trainingStreams.delete,
        {
            onSuccess() {
                openNotification('success', 'Объект успешно удален')
                invalidateQueries(queryKeysTrainingStreams[2])
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка при удалении объекта')
        }
    )

    const columns: ColumnsType<TableItem> = useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: {
                    compare: (a: TableItem, b: TableItem) => a.id - b.id,
                },
                ...getColumnSearchProps('id'),
                defaultFilteredValue: params.id === searchText ? [params.id] : undefined,
                width: 100,
            },
            {
                title: 'Имя',
                dataIndex: 'name',
                sorter: {
                    compare: (a, b) => a.name.localeCompare(b.name)
                },
                ...getColumnSearchProps('name'),
                width: 150,
            },
            {
                title: 'Курс',
                dataIndex: 'course_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.course_id, b.course_id, queryCourse.data || []) },
                ...getColumnSearchProps('course_id'),
                onFilter: (val, record) => filterTableLink(val, record.course_id, queryCourse.data || []),
                render: (id: number) => tableLinkId(queryCourse.data || [], id, 'course'),
                width: 200,
            },
            {
                title: 'Город',
                dataIndex: 'city_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.city_id, b.city_id, queryCity.data || []) },
                ...getColumnSearchProps('city_id'),
                onFilter: (val, record) => filterTableLink(val, record.city_id, queryCity.data || []),
                render: (id: number) => tableLinkId(queryCity.data || [], id, 'cities'),
                width: 150,
            },
            {
                title: 'Курс обучения',
                dataIndex: 'education_course_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.education_course_id, b.education_course_id, queryEducationCourse.data || []) },
                ...getColumnSearchProps('education_course_id'),
                onFilter: (val, record) => filterTableLink(val, record.education_course_id, queryEducationCourse.data || []),
                render: (id: number) => tableLinkId(queryEducationCourse.data || [], id, 'educationCourses'),
                width: 150,
            },
            {
                title: 'Дата начала',
                dataIndex: 'date_from',
                sorter: {
                    compare: (a: TableItem, b: TableItem) => sortByDate(a.date_from, b.date_from),
                },
                render: (val: string) => <ParsedDate timeDB={val}/>
            },
            {
                title: 'Дата окончания',
                dataIndex: 'date_to',
                sorter: {
                    compare: (a: TableItem, b: TableItem) => sortByDate(a.date_to, b.date_to),
                },
                render: (val: string) => <ParsedDate timeDB={val}/>
            },
            {
                title: 'Действие',
                dataIndex: '',
                align: 'right',
                render: (value: TableItem) => (
                    <TableActionButtons
                        onEdit={() => handleEditItem(value)}
                        onDelete={() => queryDelete.mutate(value)}
                    />
                )
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumnSearchProps])

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const handleTableAdd = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setOpenedAddForm(true)
    }

    const handleEditItem = (value: TableItem) => {
        setEditItem(value)
        setOpenedEditForm(true)
        setOpenedAddForm(false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleTableDelete = async () => {
        try {
            setTableHeadLoader(true)
            const deleteItems = getSelectedItems<TableItem>(selectedRowKeys, queryAll.data || [])
            await api.trainingStreams.deleteMany(deleteItems)
            invalidateQueries(queryKeysTrainingStreams[2])
            openNotification(
                'success',
                'Выбранные объекты успешно удалены'
            )
            setSelectedRowKeys([])
        } catch (err) {
            errorNotification(err, 'Ошибка при удалении объектов')
        }
        finally { setTableHeadLoader(false) }
    }
    const handleTableRefresh = () => invalidateQueries(queryKeysTrainingStreams)
    useScrollMount()

    return (
        <NavbarLayout>
            <section className="uif-training-streams-page">
                <FieldHelper
                    content={trainingStreamsHelpers.pageTitle}
                    text="Учебный поток | Training stream"
                    isPageTitle
                />
                <div className="uif-training-streams-page__collapses">
                    <SoloCollapse
                        header="Создать новый объект"
                        keyCollapse="add"
                        show={openedAddForm}
                        icon={addCollapseIcon}
                        setShow={setOpenedAddForm}
                    >
                        <TrainingStreamsAddForm
                            switchData={switchData}
                            setOpenedForm={setOpenedAddForm}
                        />
                    </SoloCollapse>
                    <SoloCollapse
                        header="Редактировать объект"
                        keyCollapse="edit"
                        show={openedEditForm}
                        icon={editCollapseIcon}
                        setShow={setOpenedEditForm}

                    >
                        <TrainingStreamsEditForm
                            editItem={editItem}
                            switchData={switchData}
                            setOpenedForm={setOpenedEditForm}
                            setEditItem={setEditItem}
                        />
                    </SoloCollapse>
                </div>

                <TableObjects
                    selectedRowKeys={selectedRowKeys}
                    count={queryAll.data?.length || 0}
                    columns={columns}
                    dataSource={queryAll.data}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: onSelectChange
                    }}
                    onDelete={handleTableDelete}
                    onAdd={handleTableAdd}
                    onRefresh={handleTableRefresh}
                    rowKey="id"
                    isFetching={queryAll.isFetching}
                    headerLoader={tableHeadLoader}
                    scroll={{x: 1200}}
                />
            </section>
        </NavbarLayout >
    )
}

export default TrainingStreamsPage