import { FactorFormFields } from "../../../../types/formFieldTypes"
import { openNotification } from "../../../../utils/jsxUtils"

export const factorValidate = (values: FactorFormFields) => {
    const errors = {} as { [key: string]: string }

    if (!values.course_id) {
        errors.course_id = 'Выберите курс'
        openNotification('error', 'Неверный id курса', 'Попробуйте вернуться к списку курсов, а затем заново создать данный объект')
    }
    if (!values.name) errors.name = 'Введите имя'
    return errors
}

export const initialFactorFields = (course_id: number | string): FactorFormFields => ({
    course_id: +course_id || 0,
    name: '',
    number: 0,
    is_hide: false,
})

export const clearFactorErrors = {
    course_id: '',
    name: '',
    number: '',
    is_hide: '',
}
