import React from 'react'
import { Form, Input, InputNumber } from 'antd';
import { FormikErrors } from 'formik';
import { TrainingProgramsFormFields } from '../../../../../types/formFieldTypes';
import FormControls from '../../../../../components/form/FormControls/FormControls';
import { SwitchTrainingProgramsItems } from '../../../../../types/propsTypes';
import Memo from '../../../../../components/hoc/Memo';
import TextArea from 'antd/lib/input/TextArea';


type TrainingProgramsFormInputsProps = {
    switchData?: SwitchTrainingProgramsItems
    values?: TrainingProgramsFormFields
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<TrainingProgramsFormFields>> | Promise<void>
    handleChange: React.ChangeEventHandler
    submitForm: () => void
    clearForm?: () => void
    resetChanges?: () => void
}

const TrainingProgramsFormInputs: React.FC<TrainingProgramsFormInputsProps> = (props) => {
    const {
        handleChange,
        setFieldValue, clearForm,
        submitForm, resetChanges,
    } = props

    return (
        <>
            {/* <Memo
                data={switchData.streams}
                val={values.trainings_stream_id}
                func={setFieldValue}
            >
                <Form.Item
                    name="trainings_stream_id"
                    label="Курс"
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите курс"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('trainings_stream_id', id)}
                    >
                        {
                            switchData.streams.map(c => (
                                <Option value={c.id} key={c.id}>
                                    {c.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo> */}

            <Form.Item
                name="title"
                label="Заголовок"
                className="uif-ant-form-item"
            >
                <Input onChange={handleChange} />
            </Form.Item>

            <Memo hdl={handleChange}>
                <Form.Item
                    name="text"
                    label="Текст"
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={e => handleChange(e)} />
                </Form.Item>
            </Memo>

            <Memo hdl={handleChange}>
                <Form.Item
                    name="count_hours"
                    label="Количество часов"
                    className="uif-ant-form-item"
                >
                    <InputNumber
                        min={0}
                        onChange={val => setFieldValue('count_hours', Math.ceil(val))}
                    />
                </Form.Item>
            </Memo>

            <FormControls
                submitForm={submitForm}
                clearForm={clearForm}
                resetChanges={resetChanges}
            />
        </>
    )
}

export default TrainingProgramsFormInputs