import React from "react"
import { Link } from 'react-router-dom'
import CourseListPage from "../../pages/listPages/CourseListPage/CourseListPage"
import EducationCourseListPage from '../../pages/listPages/EducationCourseListPage/EducationCourseListPage'
import CityPage from "../../pages/objectsPages/CityPage/CityPage"
import CoachPage from "../../pages/objectsPages/CoachPage/CoachPage"
import CourseBGPage from "../../pages/objectsPages/CourseBGPage/CourseBGPage"
import CoursePage from "../../pages/objectsPages/CoursePage/CoursePage"
import CourseReviewPage from "../../pages/objectsPages/CourseReviewPage/CourseReviewPage"
import DirectionGroupsPage from "../../pages/objectsPages/DirectionGroupsPage/DirectionGroupsPage"
import DirectionPage from "../../pages/objectsPages/DirectionPage/DirectionPage"
import DiscountPage from "../../pages/objectsPages/DiscountPage/DiscountPage"
import DurationPage from "../../pages/objectsPages/DurationPage/DurationPage"
import EducationCoursePage from '../../pages/objectsPages/EducationCoursePage/EducationCoursePage'
import FactorPage from "../../pages/objectsPages/FactorPage/FactorPage"
import FormTrainingPage from "../../pages/objectsPages/FormTrainingPage/FormTrainingPage"
import GoalPage from "../../pages/objectsPages/GoalPage/GoalPage"
import LevelPage from "../../pages/objectsPages/LevelPage/LevelPage"
import PricePage from "../../pages/objectsPages/PricePage/PricePage"
import QAndAPage from "../../pages/objectsPages/QAndAPage/QAndAPage"
import SalaryPage from "../../pages/objectsPages/SalaryPage/SalaryPage"
import SkillPage from "../../pages/objectsPages/SkillPage/SkillPage"
import TagPage from "../../pages/objectsPages/TagPage/TagPage"
import TrainingProgramsPage from "../../pages/objectsPages/TrainingProgramsPage/TrainingProgramsPage"
import TrainingStreamsPage from "../../pages/objectsPages/TrainingStreamsPage/TrainingStreamsPage"
import TypeOfTrainingPage from "../../pages/objectsPages/TypeOfTraining/TypeOfTrainingPage"

type RouteObj = {
    path: string
    component: React.ComponentType<any>
    props?: { [key: string]: any }
}

export type UpwardObject = {
    name: string
    routePath: string
    routes: RouteObj[]
}

export interface UpwardSummaryObject extends UpwardObject { }

export const upwardObjectsArray = (
    sortField?: keyof UpwardObject,
    sortModificator: 1 | -1 = 1,
    objContainer = upwardObjects as { [key: string]: UpwardObject }
) => {
    const keys = Object.keys(objContainer) as Array<keyof typeof objContainer>
    const result = keys.map(obj => objContainer[obj])
    if (sortField) {
        result.sort((a, b) => {
            const aValue = a[sortField]
            const bValue = b[sortField]
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return aValue.localeCompare(bValue) * sortModificator
            }
            return 0
        })
    }
    return result
}

export const upwardObjects = {
    course: {
        name: 'Курс',
        routePath: 'course',
        routes: [
            { path: 'course', component: CoursePage },
            { path: 'course/:id', component: CoursePage },
        ]
    } as UpwardObject,
    factors: {
        name: 'Факторы',
        routePath: 'factors',
        routes: [
            {
                path: 'factors',
                component: CourseListPage,
                props: {
                    pageDescription: 'Чтобы редактировать факторы, прикрепленные к курсу, выберите один из курсов в таблице ниже',
                    pageTitle: 'Факторы (список курсов)',
                    linkPath: '/object/factors',
                }
            },
            {
                path: 'factors/:courseId',
                component: FactorPage,
            },
        ]
    } as UpwardObject,
    salaries: {
        name: 'Зарплаты',
        routePath: 'salaries',
        routes: [
            { path: 'salaries', component: SalaryPage },
            { path: 'salaries/:id', component: SalaryPage },
        ],
    } as UpwardObject,
    trainingsPrograms: {
        name: 'Программы обучения',
        routePath: 'trainingsPrograms',
        routes: [
            {
                path: 'trainingsPrograms',
                component: EducationCourseListPage,
                props: {
                    pageTitle: 'Программы обучения (список курсов обучения)',
                    Description: <p>
                        Программы обучения закреплены за <Link to="/object/education-courses"> "Курсами обучения"</Link>. Для редактирования программы обучения выберите один из Курсов обучения.
                    </p>,
                    linkPath: '/object/trainingsPrograms',
                }
            },
            {
                path: 'trainingsPrograms/:educationCourseId',
                component: TrainingProgramsPage,
            },
        ]
    } as UpwardObject,
    coaches: {
        name: 'Преподаватели',
        routePath: 'coaches',
        routes: [
            { path: 'coaches', component: CoachPage },
            { path: 'coaches/:id', component: CoachPage },
        ],
    } as UpwardObject,
    courseReviews: {
        name: 'Отзывы о курсе',
        routePath: 'course-reviews',
        routes: [
            { path: 'course-reviews', component: CourseReviewPage },
            { path: 'course-reviews/:id', component: CourseReviewPage },
        ],
    } as UpwardObject,
    skills: {
        name: 'Навыки',
        routePath: 'skills',
        routes: [
            { path: 'skills', component: SkillPage },
            { path: 'skills/:id', component: SkillPage },
        ],
    } as UpwardObject,
    courseQAndA: {
        name: 'Вопрос-Ответ',
        routePath: 'course-q-and-a',
        routes: [
            { path: 'course-q-and-a', component: QAndAPage },
            { path: 'course-q-and-a/:id', component: QAndAPage },
        ],
    } as UpwardObject,
    directions: {
        name: 'Направления',
        routePath: 'directions',
        routes: [
            { path: 'directions', component: DirectionPage },
            { path: 'directions/:id', component: DirectionPage },
        ],
    } as UpwardObject,
    directionsGroups: {
        name: 'Группы направлений',
        routePath: 'directions-groups',
        routes: [
            { path: 'directions-groups', component: DirectionGroupsPage },
            { path: 'directions-groups/:id', component: DirectionGroupsPage },
        ],
    } as UpwardObject,
    cities: {
        name: 'Города',
        routePath: 'cities',
        routes: [
            { path: 'cities', component: CityPage },
            { path: 'cities/:id', component: CityPage },
        ],
    } as UpwardObject,
    educationCourses: {
        name: 'Курсы обучения',
        routePath: 'education-courses',
        routes: [
            { path: 'education-courses', component: EducationCoursePage },
            { path: 'education-courses/:id', component: EducationCoursePage },
        ],
    } as UpwardObject,
    goals: {
        name: 'Цели',
        routePath: 'goals',
        routes: [
            { path: 'goals', component: GoalPage },
            { path: 'goals/:id', component: GoalPage },
        ],
    } as UpwardObject,
    durations: {
        name: 'Длительность',
        routePath: 'durations',
        routes: [
            { path: 'durations', component: DurationPage },
            { path: 'durations/:id', component: DurationPage },
        ],
    } as UpwardObject,
    levels: {
        name: 'Уровни сложности',
        routePath: 'levels',
        routes: [
            { path: 'levels', component: LevelPage },
            { path: 'levels/:id', component: LevelPage },
        ],
    } as UpwardObject,
    tags: {
        name: 'Тэг',
        routePath: 'tags',
        routes: [
            { path: 'tags', component: TagPage },
            { path: 'tags/:id', component: TagPage },
        ],
    } as UpwardObject,
    trainingsStreams: {
        name: 'Учебный поток',
        routePath: 'trainings-streams',
        routes: [
            { path: 'trainings-streams', component: TrainingStreamsPage },
            { path: 'trainings-streams/:id', component: TrainingStreamsPage },
        ],
    } as UpwardObject,
    prices: {
        name: 'Цены',
        routePath: 'prices',
        routes: [
            { path: 'prices', component: PricePage },
            { path: 'prices/:id', component: PricePage },
        ],
    } as UpwardObject,
    discounts: {
        name: 'Скидки',
        routePath: 'discounts',
        routes: [
            { path: 'discounts', component: DiscountPage },
            { path: 'discounts/:id', component: DiscountPage },
        ],
    } as UpwardObject,
    typeOfTraining: {
        name: 'Типы обучения',
        routePath: 'typeOfTraining',
        routes: [
            { path: 'typeOfTraining', component: TypeOfTrainingPage },
            { path: 'typeOfTraining/:id', component: TypeOfTrainingPage },
        ],
    } as UpwardObject,
    courseBG: {
        name: 'Фон курса',
        routePath: 'background-course',
        routes: [
            { path: 'background-course', component: CourseBGPage },
            { path: 'background-course/:id', component: CourseBGPage },
        ],
    } as UpwardObject,
    formTraining: {
        name: 'Формы обучения',
        routePath: 'formTraining',
        routes: [
            { path: 'formTraining', component: FormTrainingPage },
            { path: 'formTraining/:id', component: FormTrainingPage },
        ],
    } as UpwardObject,
}

// export const upwardSummaryObjects = {
//     cities: {
//         name: 'Город - Курс',
//         routePath: 'citiesForCourse',
//         routes: [
//             { path: 'citiesForCourse', component: CitiesForCoursePage },
//             { path: 'citiesForCourse/:id', component: CitiesForCoursePage },
//         ]
//     } as UpwardSummaryObject,
//     tags: {
//         name: 'Тег - Курс',
//         routePath: 'tagsForCourse',
//         routes: [
//             { path: 'tagsForCourse', component: TagsForCoursePage },
//             { path: 'tagsForCourse/:id', component: TagsForCoursePage },
//         ]
//     } as UpwardSummaryObject,
//     coaches: {
//         name: 'Тренер - Курс',
//         routePath: 'coachesForCourse',
//         routes: [
//             { path: 'coachesForCourse', component: CoachesForCoursePage },
//             { path: 'coachesForCourse/:id', component: CoachesForCoursePage },
//         ]
//     } as UpwardSummaryObject,
// }