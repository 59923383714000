import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { DirectionFormFields } from '../../../../../types/formFieldTypes';
import { getAntField } from '../../../../../utils/form';
import { DirectionGroupObj, ObjDB } from '../../../../../types/apiTypes';
import DirectionFormInputs from '../DirectionFormInputs/DirectionFormInputs';
import { clearDirectionErrors, directionValidate, initialDirectionFields } from '../../formData/DirectionFormData';
import { queryKeysDirection } from '../../../../../static/data/queryKeys';
import { invalidateQueries } from '../../../../../utils/rest';

type DirectionAddFormProps = {
    directionGroups: ObjDB<DirectionGroupObj>[]
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
}

const DirectionAddForm: React.FC<DirectionAddFormProps> = React.memo((props) => {
    const {
        directionGroups
    } = props
    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange, setFieldValue,
        submitForm, setValues, setErrors,
    } = useFormik<DirectionFormFields>({
        initialValues: initialDirectionFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: directionValidate
    })

    const antField = getAntField<DirectionFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.directions.create,
        {
            mutationKey: 'direction-add',
            onSuccess() {
                invalidateQueries(queryKeysDirection[1])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.log('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 4000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialDirectionFields)
        setErrors(clearDirectionErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[
            antField('name'),
            antField('group_directions', {withFalsy: true}),
        ]}>
            <DirectionFormInputs
                directionGroups={directionGroups}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default DirectionAddForm