import React from 'react'
import ReactDatePicker from 'react-datepicker'

import { Form, Input } from 'antd';
import { Select } from 'antd';
import { FormikErrors } from 'formik';
import { CourseReviewFormFields } from '../../../../../types/formFieldTypes';
import FormControls from '../../../../../components/form/FormControls/FormControls';
import { SwitchCourseReviewItems } from '../../../../../types/propsTypes';
import TextArea from 'antd/lib/input/TextArea';
import ImageUpload from '../../../../../components/form/ImageUpload/ImageUpload';
import { parseDateToDBTime, parseDBTimeToDate } from '../../../../../utils/parsers';
import Memo from '../../../../../components/hoc/Memo';
const { Option } = Select;

type CourseReviewFormInputsProps = {
    switchData: SwitchCourseReviewItems
    values: CourseReviewFormFields
    errors: FormikErrors<CourseReviewFormFields>
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<CourseReviewFormFields>> | Promise<void>
    handleChange: React.ChangeEventHandler
    submitForm: () => void
    defaultAvatarSrc?: string
    isFetchingImg?: boolean
    clearForm?: () => void
    resetChanges?: () => void
    onChangeImg: (file: File | null) => void
}
const CourseReviewFormInputs: React.FC<CourseReviewFormInputsProps> = (props) => {
    const {
        switchData, values, errors,
        defaultAvatarSrc, isFetchingImg,
        handleChange, onChangeImg,
        setFieldValue, clearForm,
        submitForm, resetChanges,
    } = props

    return (
        <>
            <Memo
                val={values.date}
                func={setFieldValue}
                err={errors.date}
            >
                <Form.Item
                    name="date"
                    label="Дата"
                    className="uif-ant-form-item"
                >
                    <ReactDatePicker
                        locale="ru"
                        placeholderText="Выберите дату"
                        selected={parseDBTimeToDate(values.date)}
                        onChange={date => setFieldValue('date', parseDateToDBTime(date))}
                        timeInputLabel="Время:"
                        dateFormat="dd/MM/yyyy HH:mm"
                        autoComplete="off"
                        showTimeInput
                    />
                </Form.Item>
            </Memo>

            <Memo
                val={values.course_id}
                func={setFieldValue}
                err={errors.course_id}
            >
                <Form.Item
                    name="course_id"
                    label="Курс"
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите курс"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('course_id', id)}
                    >
                        {
                            switchData.courses.map(c => (
                                <Option value={c.id} key={c.id}>
                                    {c.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo
                val={values.text}
                func={handleChange}
                err={errors.text}
            >
                <Form.Item
                    name="text"
                    label="Текст отзыва"
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={handleChange} />
                </Form.Item>
            </Memo>

            <Memo
                val={values.first_name_review}
                func={handleChange}
                err={errors.first_name_review}
            >
                <Form.Item
                    name="first_name_review"
                    label="Имя"
                    className="uif-ant-form-item"
                >
                    <Input onChange={handleChange} />
                </Form.Item>
            </Memo>

            <Memo
                val={values.last_name_review}
                func={handleChange}
                err={errors.last_name_review}
            >
                <Form.Item
                    name="last_name_review"
                    label="Фамилия"
                    className="uif-ant-form-item"
                >
                    <Input onChange={handleChange} />
                </Form.Item>
            </Memo>

            <Memo
                val={values._avatarSrc}
                func={onChangeImg}
                err={errors.avatar_file_id}
                def={defaultAvatarSrc}
            >
                <Form.Item
                    label="Аватар"
                    className="uif-ant-form-item"
                >
                    <ImageUpload
                        error={errors.avatar_file_id}
                        isFetching={isFetchingImg}
                        defaultImgSrc={values._avatarSrc || defaultAvatarSrc}
                        onlyImg={true}
                        onChange={onChangeImg}
                    />
                </Form.Item>
            </Memo>

            <FormControls
                submitForm={submitForm}
                clearForm={clearForm}
                resetChanges={resetChanges}
            />
        </>
    )
}

export default CourseReviewFormInputs