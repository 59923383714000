import { makeAutoObservable, runInAction } from "mobx"
import { authApi } from "../api/authApi"
import { accessTokenCookieName, refreshTokenCookieName } from "../config/vars"
import { AuthResponseData } from "../types/apiTypes"
import { deleteCookie, getCookie, writeUserToCookie } from "../utils/cookie"

class AuthStore {
    isAuth: boolean | null = null

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    login(data: AuthResponseData) {
        writeUserToCookie(data)
        this.isAuth = true
    }

    async logout() {
        try {
            await authApi.logout()
        } catch(err) {
            console.warn('logout request error:', err.message)
        } finally {
            runInAction(() => this.isAuth = false)
            deleteCookie(accessTokenCookieName)
            deleteCookie(refreshTokenCookieName)
        }
    }

    async authFromCookie() {
        try {
            const refreshToken = getCookie(refreshTokenCookieName)
            if (!refreshToken) return this.logout()
            const refreshData = await authApi.refresh(refreshToken)
            if (refreshData.access_token) return this.login(refreshData)
            this.logout()
        } catch(err) {
            console.warn('auth from cookie error', err.message)
            this.logout()
        }
    }
}

export const authStore = new AuthStore()