import React from 'react'
import './RadioImageArea.scss'

type RadioImageAreaProps = {
    error?: string
    wrapClasses?: string[]
}

const RadioImageArea: React.FC<RadioImageAreaProps> = (props) => {

    const {
        error, wrapClasses,
        children,
    } = props

    const areaClasses = () => {
        let classes = ['radio-image-area']
        if (wrapClasses) classes = [...classes, ...wrapClasses]
        if (error) classes.push('error')
        return classes.join(' ')
    }

    return (
        <div className={areaClasses()}>
            <div className="radio-image-area__body">
                {
                    children
                }
            </div>
            {
                error &&
                <p className="radio-image-area__error">
                    { error }
                </p>
            }
        </div>
    )
}

export default RadioImageArea