import { TypeOfTrainingFormFields } from "../../../../types/formFieldTypes"

export const typeOfTrainingValidate = (values: TypeOfTrainingFormFields) => {
    const errors = {} as { [key: string]: string }
    if (!values.name) errors.name = 'Введите имя'
    return errors
}

export const initialTypeOfTrainingFields: TypeOfTrainingFormFields = {
    name: '',
}

export const clearTypeOfTrainingErrors = {
    name: '',
}
