import React from 'react'

export default class Memo extends React.Component<any, {}> {
    shouldComponentUpdate(nextProps: any) {
        let isEqual = true
        for (let key in this.props) {
            if (this.props.hasOwnProperty(key) && key !== 'children' && isEqual) {
                isEqual = this.props[key] === nextProps[key]
            }
        }
        return !isEqual
    }
    render() {
        return <>
            {this.props.children}
        </>
    }
}