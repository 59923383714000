import { FormTrainingFormFields } from "../../../../types/formFieldTypes"

export const formTrainingValidate = (values: FormTrainingFormFields) => {
    const errors = {} as { [key: string]: string }
    if (!values.name) errors.name = 'Введите название'
    return errors
}

export const initialFormTrainingFields = {
    name: ''
}

export const clearFormTrainingErrors = {
    name: ''
}
