import { HelprerContentItem } from "../../../../types/propsTypes";

export const factorHelpers = {
    pageTitle: [
        {
            type: 'title',
            text: 'Фактор курса'
        },
        'Отобажается на странице курса в блоке "Перспективы". Данный объект поддерживает сортировку (порядок расположения)',
        {
            type: 'image',
            imgSrc: '/static/img/helper/page-title/factor.jpg',
        }
    ] as HelprerContentItem[]
}