export const lockBody = () => document.body.classList.add('lock')
export const unlockBody = () => document.body.classList.remove('lock')

export const lockBodySmall = () => document.body.classList.add('lock-sm')
export const unlockBodySmall = () => document.body.classList.remove('lock-sm')

export const isDarken = () => window.localStorage.getItem('theme') === 'dark'
export const activeDark = () => {
    window.localStorage.setItem('theme', 'dark')
    document.body.classList.add('dark')
}
export const disableDark = () => {
    window.localStorage.removeItem('theme')
    document.body.classList.remove('dark')
}

export const toggleTheme = () => {
    if (isDarken()) return disableDark()
    activeDark()
}

export const isOpenedMenuStorage = () => !!localStorage.getItem('showSideMenu')