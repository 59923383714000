import { HelprerContentItem } from "../../../../types/propsTypes";

export const durationHelpers = {
    pageTitle: [
        {
            type: 'title',
            text: 'Длительность'
        },
        'Выступает в качестве фильтра для каталога курсов',
        {
            type: 'image',
            imgSrc: '/static/img/helper/page-title/duration.jpg',
        }
    ] as HelprerContentItem[]
}