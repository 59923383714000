import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { LevelFormFields } from '../../../../../types/formFieldTypes';
import { clearLevelErrors, initialLevelFields, levelValidate, } from '../../formData/LevelFormData';
import LevelFormInputs from '../LevelFormInputs/LevelFormInputs';
import { getAntField } from '../../../../../utils/form';
import { queryKeysLevel } from '../../../../../static/data/queryKeys';
import { invalidateQueries } from '../../../../../utils/rest';


type LevelAddFormProps = {}

const LevelAddForm: React.FC<LevelAddFormProps> = React.memo(() => {

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<LevelFormFields>({
        initialValues: initialLevelFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: levelValidate
    })

    const antField = getAntField<LevelFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.levels.create,
        {
            mutationKey: 'level-add',
            onSuccess() {
                invalidateQueries(queryKeysLevel[0])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.log('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 4000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialLevelFields)
        setErrors(clearLevelErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[antField('name')]}>
            <LevelFormInputs
                handleChange={handleChange}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default LevelAddForm