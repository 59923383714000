import React, { useState } from 'react'
import { Form, Input, Select } from 'antd';
import { FormikErrors } from 'formik';
import FormControls from '../../../components/form/FormControls/FormControls';
import { SiteSettingFormFields } from '../../../types/formFieldTypes';
import { SwitchSiteSettingItems } from '../../../types/propsTypes';
import Memo from '../../../components/hoc/Memo';
import TextArea from 'antd/lib/input/TextArea';
import FieldHelper from '../../../components/common/FieldHelper/FieldHelper';
import { siteSettingHelpers } from '../formData/siteSettingsFieldHelper';
import { courseAboutName } from '../../../utils/form';
import { coursesAboutCount } from '../formData/siteSettingFormData';
import SoloCollapse from '../../../components/ui/SoloCollapse/SoloCollapse';
import { ProfileTwoTone } from '@ant-design/icons';
const { Option } = Select;

type SiteSettingFormInputsProps = {
    switchData: SwitchSiteSettingItems
    values: SiteSettingFormFields
    errors: FormikErrors<SiteSettingFormFields>
    setFieldValue: (field: keyof SiteSettingFormFields, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<SiteSettingFormFields>> | Promise<void>
    submitForm: () => void
    resetChanges?: () => void
}

export const SiteSettingFormInputs: React.FC<SiteSettingFormInputsProps> = (props) => {
    const {
        values, errors, switchData,
        submitForm, setFieldValue,
        resetChanges,
    } = props

    const [openedCoursesAbout, setOpenedCoursesAbout] = useState<boolean>(false)

    return (
        <>
            <SoloCollapse
                header="Курсы на странице 'Об институте'"
                keyCollapse="add"
                show={openedCoursesAbout}
                icon={<ProfileTwoTone />}
                setShow={setOpenedCoursesAbout}
            >
                {
                    new Array(coursesAboutCount).fill('').map((_, idx) => (
                        <Memo
                            key={idx}
                            val={values[courseAboutName(idx + 1)]}
                            data={switchData.courses}
                            func={setFieldValue}
                        >
                            <Form.Item
                                name={courseAboutName(idx + 1)}
                                label={`Курс №${idx + 1}`}
                                className="uif-ant-form-item"
                            >
                                <Select
                                    showSearch
                                    placeholder={`Курс №${idx + 1}`}
                                    optionFilterProp="children"
                                    onChange={id => setFieldValue(courseAboutName(idx + 1), id)}
                                >
                                    <Option value={''} key={-1}>
                                        Очистить
                                    </Option>
                                    {
                                        switchData.courses.map(c => (
                                            <Option value={c.id + ''} key={c.id}>
                                                {c.name}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Memo>
                    ))
                }
            </SoloCollapse>

            <Form.Item
                name="tel_number"
                label={<FieldHelper
                    content={siteSettingHelpers.tel}
                    text="Телефон"
                />}
                className="uif-ant-form-item"
            >
                <Input onChange={e => setFieldValue('tel_number', e.target.value)} />
            </Form.Item>

            <Memo
                data={switchData.tags}
                val={values.tag_id}
                err={errors.tag_id}
                func={setFieldValue}
            >
                <Form.Item
                    name="tag_id"
                    label={<FieldHelper
                        content={siteSettingHelpers.tag}
                        text="Тег"
                    />}
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите тег"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('tag_id', id + '')}
                    >
                        {
                            switchData.tags.map(t => (
                                <Option value={t.id + ''} key={t.id}>
                                    {t.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo hdl={setFieldValue}>
                <Form.Item
                    name="tag_text"
                    label={<FieldHelper
                        content={siteSettingHelpers.tag}
                        text="Текст тега"
                    />}
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={e => setFieldValue('tag_text', e.target.value)} />
                </Form.Item>
            </Memo>

            <Memo hdl={setFieldValue}>
                <Form.Item
                    name="offline_text"
                    label="Текст Offline-блока на главной странице"
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={e => setFieldValue('offline_text', e.target.value)} />
                </Form.Item>
            </Memo>

            <Memo
                data={switchData.formTrainings}
                val={values.offline_id}
                err={errors.offline_id}
                func={setFieldValue}
            >
                <Form.Item
                    name="offline_id"
                    label={<FieldHelper
                        content={siteSettingHelpers.offlineForm}
                        text="Оффлайн форма обучения"
                    />}
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите форму обучения"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('offline_id', id + '')}
                    >
                        {
                            switchData.formTrainings.map(t => (
                                <Option value={t.id + ''} key={t.id}>
                                    {t.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo
                data={switchData.formTrainings}
                val={values.online_id}
                err={errors.online_id}
                func={setFieldValue}
            >
                <Form.Item
                    name="online_id"
                    label={<FieldHelper
                        content={siteSettingHelpers.onlineForm}
                        text="Онлайн форма обучения"
                    />}
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите форму обучения"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('online_id', id + '')}
                    >
                        {
                            switchData.formTrainings.map(t => (
                                <Option value={t.id + ''} key={t.id}>
                                    {t.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <FormControls
                submitForm={submitForm}
                resetChanges={resetChanges}
            />
        </>
    )
}

export default SiteSettingFormInputs