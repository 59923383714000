import { profileUrl } from "../config/urls";
import axios from "axios"
import { freshAccessToken } from "../utils/cookie";
import { UserResponseData } from "../types/apiTypes";
import { authOptions } from "../utils/rest";

export const userApi = {
    async initial() {
        const access = await freshAccessToken()
        const res = await axios.get(profileUrl, authOptions(access))
        const data = res.data as UserResponseData
        return data
    }
}