import React from 'react'
import { Form, Input } from 'antd';
import { Select } from 'antd';
import { FormikErrors } from 'formik';
import { DirectionFormFields } from '../../../../../types/formFieldTypes';
import { DirectionGroupObj, ObjDB } from '../../../../../types/apiTypes';
import FormControls from '../../../../../components/form/FormControls/FormControls';
const { Option } = Select;


type DirectionFormInputsProps = {
    directionGroups: ObjDB<DirectionGroupObj>[]
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<DirectionFormFields>> | Promise<void>
    clearForm?: () => void
    resetChanges?: () => void
    submitForm: () => void
}

const DirectionFormInputs: React.FC<DirectionFormInputsProps> = (props) => {
    const {
        directionGroups, handleChange,
        setFieldValue, clearForm,
        submitForm, resetChanges,
    } = props

    return (
        <>
            <Form.Item
                name="name"
                label="Название направления"
                className="uif-ant-form-item"
            >
                <Input onInput={handleChange} />
            </Form.Item>

            <Form.Item
                name="group_directions"
                label="Группа направлений"
                className="uif-ant-form-item"
            >
                <Select
                    showSearch
                    placeholder="Выберите группу"
                    optionFilterProp="children"
                    onChange={id => setFieldValue('group_directions', id)}
                >
                    {
                        directionGroups.map(d => (
                            <Option value={d.id} key={d.id}>
                                {d.name}
                            </Option>
                        ))
                    }
                </Select>
            </Form.Item>

            <FormControls
                submitForm={submitForm}
                resetChanges={resetChanges}
                clearForm={clearForm}
            />
        </>
    )
}

export default DirectionFormInputs