import { FormikErrors, FormikTouched } from "formik"
import moment from 'moment'
import { SiteSettingFormFields } from '../types/formFieldTypes'

type Options<Value> = {
    withFalsy?: boolean
    value?: Value | moment.Moment | null | undefined
    notSetValue?: boolean
}

export const getAntField = <Fields>(
    values: Fields,
    errors: FormikErrors<Fields>,
    touched: FormikTouched<Fields>
) => {
    type FieldValue = Fields[keyof Fields]
    return (
        field: keyof Fields,
        options?: Options<FieldValue>
    ) => {
        let value: FieldValue | moment.Moment | null | undefined = options?.notSetValue ? undefined : values[field];
        if (options?.value) value = options.value
        if (options?.withFalsy) value = values[field] || null
        const result = {
            value,
            name: field,
            errors: errors[field] ? [errors[field] as string] : undefined,
            touched: touched[field],
        }
        return result
    }
}

export const sortByDate = (fullDateFirst: string, fullDateSecond: string): number => {
    /// example: '20210405230000'
    const trimDateFirst = +fullDateFirst.slice(0, 8)
    const trimDateSecond = +fullDateSecond.slice(0, 8)
    const trimTimeFirst = +fullDateFirst.slice(8)
    const trimTimeSecond = +fullDateSecond.slice(8)

    const deltaDate = trimDateFirst - trimDateSecond
    const deltaTime = trimTimeFirst - trimTimeSecond
    if (deltaDate !== 0) return deltaDate
    return deltaTime
}

export const isValidTypeImg = (file: File | null | undefined, types = ['jpg', 'png', 'jpeg']) => {
    if (!file) return false
    const fileType = file.type.split('/').slice(-1)[0]
    return types.includes(fileType)
}

export const courseAboutName = (idx: number) => `course_${idx}` as keyof SiteSettingFormFields
