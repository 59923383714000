import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { GoalObj, ObjDB } from '../../../../../types/apiTypes';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import { GoalFormFields } from '../../../../../types/formFieldTypes';
import EditPlaceholder from '../../../../../components/common/EditPlaceholder/EditPlaceholder';
import { clearGoalErrors, goalValidate, initialGoalFields } from '../../formData/GoalFormData';
import { getAntField } from '../../../../../utils/form';
import GoalFormInputs from '../GoalFormInputs/GoalFormInputs';
import { queryKeysGoal } from '../../../../../static/data/queryKeys';
import { invalidateQueries } from '../../../../../utils/rest';


type GoalEditFormProps = {
    editItem: ObjDB<GoalObj> | null
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
    setEditItem: React.Dispatch<React.SetStateAction<ObjDB<GoalObj> | null>>
}

const GoalEditForm: React.FC<GoalEditFormProps> = React.memo((props) => {
    const {
        editItem, setOpenedForm, setEditItem
    } = props

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<GoalFormFields>({
        initialValues: initialGoalFields,
        onSubmit: (values: GoalFormFields) => {
            mutate(values)
        },
        validate: goalValidate
    })

    const antField = getAntField<GoalFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        async (values: GoalFormFields) => {
            if (editItem) return await api.goals.edit(values, editItem)
        },
        {
            mutationKey: 'goal-edit',
            onSuccess() {
                invalidateQueries(queryKeysGoal[0])
                formStatus.setMessage('Данные успешно изменены')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.error('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 5000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialGoalFields)
        setErrors(clearGoalErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setOpenedForm(false)
        setTimeout(() => {
            setEditItem(null)
            formStatus.reset()
        }, 300)
    }

    const resetChanges = () => {
        if (editItem) {
            const { id, ...values } = editItem
            setValues(values)
            setErrors(clearGoalErrors)
        }
    }

    useEffect(() => {
        if (editItem) return resetChanges()
        clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])


    if (!editItem) return <EditPlaceholder />

    return (
        <Form fields={[
            antField('name'),
            antField('description')
        ]}>
            {
                editItem &&
                <p className="uif-ant-form-title">
                    ID редактируемого объекта: <span>{editItem.id}</span>
                </p>
            }
            <GoalFormInputs
                handleChange={handleChange}
                resetChanges={resetChanges}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})


export default GoalEditForm