import { HelprerContentItem } from "../../../../types/propsTypes";

export const salaryHelpers = {
    pageTitle: [
        {
            type: 'title',
            text: 'Зарплаты'
        },
        'Зарплата по итогам обучения. Отобажается на странице курса',
        {
            type: 'image',
            imgSrc: '/static/img/helper/page-title/salary.jpg',
        }
    ] as HelprerContentItem[]
}