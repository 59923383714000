import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { DiscountFormFields } from '../../../../../types/formFieldTypes';
import { getAntField } from '../../../../../utils/form';
import { clearDiscountErrors, discountValidate, initialDiscountFields } from '../../formData/DiscountFormData';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { SwitchDiscountItems } from '../../../../../types/propsTypes';
import DiscountFormInputs from '../DiscountFormInputs/DiscountFormInputs';
import { queryKeysDiscount } from '../../../../../static/data/queryKeys';

type DiscountAddFormProps = {
    switchData: SwitchDiscountItems
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
}

const DiscountAddForm: React.FC<DiscountAddFormProps> = React.memo((props) => {
    const {
        switchData
    } = props
    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        setFieldValue, handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<DiscountFormFields>({
        initialValues: initialDiscountFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: discountValidate
    })

    const antField = getAntField<DiscountFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.discounts.create,
        {
            mutationKey: 'discount-add',
            onSuccess() {
                invalidateQueries(queryKeysDiscount[2])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка создания', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialDiscountFields)
        setErrors(clearDiscountErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[
            antField('course_id', {withFalsy: true}),
            antField('city_id'),
            antField('name'),
            antField('discount'),
            antField('fix_price'),
            antField('type_discount'),
            antField('date_from', {notSetValue: true}),
            antField('date_to', {notSetValue: true}),
        ]}>
            <DiscountFormInputs
                switchData={switchData}
                values={values}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default DiscountAddForm