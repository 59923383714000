import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { PriceFormFields } from '../../../../../types/formFieldTypes';
import { getAntField } from '../../../../../utils/form';
import { clearPriceErrors, initialPriceFields, priceValidate } from '../../formData/PriceFormData';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { SwitchPriceItems } from '../../../../../types/propsTypes';
import PriceFormInputs from '../PriceFormInputs/PriceFormInputs';
import { queryKeysPrice } from '../../../../../static/data/queryKeys';

type PriceAddFormProps = {
    switchData: SwitchPriceItems
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
}

const PriceAddForm: React.FC<PriceAddFormProps> = React.memo((props) => {
    const {
        switchData
    } = props
    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        setFieldValue, handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<PriceFormFields>({
        initialValues: initialPriceFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: priceValidate
    })

    const antField = getAntField<PriceFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.prices.create,
        {
            mutationKey: 'price-add',
            onSuccess() {
                invalidateQueries(queryKeysPrice[2])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка создания', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialPriceFields)
        setErrors(clearPriceErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[
            antField('course_id', {withFalsy: true}),
            antField('city_id'),
            antField('name'),
            antField('price'),
            antField('date_from', {notSetValue: true}),
            antField('date_to', {notSetValue: true}),
        ]}>
            <PriceFormInputs
                switchData={switchData}
                values={values}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default PriceAddForm