import { HelprerContentItem } from "../../../../types/propsTypes";

export const educationCourseHelpers = {
    pageTitle: [
        {
            type: 'title',
            text: 'Курсы обучения'
        },
        'Предназначены для прикрепления за собой программ обучения',
    ] as HelprerContentItem[]
}