import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { CoachFormFields } from '../../../../../types/formFieldTypes';
import { getAntField } from '../../../../../utils/form';
import { clearCoachErrors, coachValidate, initialCoachFields } from '../../formData/CoachFormData';
import { CoachFormInputs } from '../CoachFormInputs/CoachFormInputs';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { ObjDB, UploadObj } from '../../../../../types/apiTypes';
import { queryKeysCoach } from '../../../../../static/data/queryKeys';

type CoachAddFormProps = {}

const CoachAddForm: React.FC<CoachAddFormProps> = React.memo(() => {

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange, setFieldValue,
        submitForm, setValues, setErrors,
    } = useFormik<CoachFormFields>({
        initialValues: initialCoachFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: coachValidate
    })

    const antField = getAntField<CoachFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.coaches.create,
        {
            mutationKey: 'coach-add',
            onSuccess() {
                invalidateQueries(queryKeysCoach[0])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка создания', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            },
        }
    )

    const mutationFile = useMutation(
        api.file.add,
        {
            mutationKey: 'avatar-add',
            onSuccess(data: ObjDB<UploadObj>) {
                setFieldValue('avatar_id', data.id)
                setFieldValue('_avatarSrc', data.path)
            },
            onError: (err: Error) => {
                errorNotification(err, 'Ошибка при загрузке файла, загрузите файл заново', formStatus)
                setFieldValue('avatar_id', 0)
                setFieldValue('_avatarSrc', '')
            },
        }
    )

    // mutationFile.isError

    const handleChangeImg = (file: File | null) => {
        if (file) mutationFile.mutate(file)
    }

    const clearForm = () => {
        setValues(initialCoachFields)
        setErrors(clearCoachErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[
            antField('first_name'),
            antField('last_name'),
            antField('quality'),
            antField('experience'),
            antField('education'),
        ]}>
            <CoachFormInputs
                values={values}
                errors={errors}
                isFetchingImg={mutationFile.isLoading}
                onChangeImg={handleChangeImg}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default CoachAddForm