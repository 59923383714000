import { HelprerContentItem } from "../../../../types/propsTypes";

export const trainingStreamsHelpers = {
    pageTitle: [
        {
            type: 'title',
            text: 'Учебный поток'
        },
        'Объект, предназначенный для связи Курса с городом и отрезком времени действия курса',
    ] as HelprerContentItem[],
    educationCourse: [
        {
            type: 'title',
            text: 'Курс обучения'
        },
        'За курсом обучения закреплены программы обучения. Таким образом, выбрав один из курсов обучения, вы прикрепляете его программы обучения к данному потоку',
    ] as HelprerContentItem[],
}