import { CourseBGFormFields } from "../../../../types/formFieldTypes"

export const courseBGValidate = (values: CourseBGFormFields) => {
    const errors = {} as { [key: string]: string }
    if (!values.background_file_id) errors.background_file_id = 'Загрузите фото'
    return errors
}

export const initialCourseBGFields  = {
    background_file_id: 0,

    _imgSrc: '',
}

export const clearCourseBGErrors = {
    background_file_id: '',

    _imgSrc: '',
}
