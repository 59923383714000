import { HelprerContentItem } from "../../../../types/propsTypes";

export const coachHelpers = {
    level: [
        {
            type: 'title',
            text: 'Уровень квалификации'
        },
        'Отобажается в карточках преподавателя',
        {
            type: 'image',
            imgSrc: '/static/img/helper/coach-level.jpg',
        }
    ] as HelprerContentItem[],
    education: [
        {
            type: 'title',
            text: 'Образование'
        },
        'Отобажается на странице преподавателя',
        {
            type: 'image',
            imgSrc: '/static/img/helper/coach-education.jpg',
        }
    ] as HelprerContentItem[],
    experience: [
        {
            type: 'title',
            text: 'Опыт работы'
        },
        'Отобажается на странице преподавателя',
        {
            type: 'image',
            imgSrc: '/static/img/helper/coach-experience.jpg',
        }
    ] as HelprerContentItem[],
}