import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { getAntField } from '../../../../../utils/form';
import { factorValidate, clearFactorErrors, initialFactorFields } from '../../formData/FactorFormData';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { SwitchFactorItems } from '../../../../../types/propsTypes';
import { FactorFormFields } from '../../../../../types/formFieldTypes';
import FactorFormInputs from '../FactorFormInputs/FactorFormInputs';
import { FactorObj, ObjDB } from '../../../../../types/apiTypes';
import { sortDataByFieldId } from '../../../../../utils/table';
import { useParams } from 'react-router';
import { queryKeysFactor } from '../../../../../static/data/queryKeys';

type FactorAddFormProps = {
    switchData: SwitchFactorItems
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
}

const FactorAddForm: React.FC<FactorAddFormProps> = React.memo((props) => {
    const {
        switchData
    } = props
    const formStatus = useFormStatus()

    const params = useParams<{courseId: string}>()

    const {
        errors, values, touched,
        setFieldValue, handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<FactorFormFields>({
        initialValues: initialFactorFields(params.courseId),
        validateOnChange: false,
        onSubmit: () => lastFactorsMutate.mutate(),
        validate: factorValidate
    })

    const antField = getAntField<FactorFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.factors.create,
        {
            mutationKey: 'factor-add',
            onSuccess() {
                invalidateQueries(queryKeysFactor[1])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка создания', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const lastFactorsMutate = useMutation<ObjDB<FactorObj>[], Error, void, void>(
        api.factors.getAll,
        {
            mutationKey: 'factor-all',
            onSuccess(data) {
                const sortedData = sortDataByFieldId(data, +params.courseId, 'course_id')
                setFieldValue('number', sortedData.length + 1)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка получения последнего индекса для сортировки', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialFactorFields(params.courseId))
        setErrors(clearFactorErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    useEffect(() => {
        if (values.number > 0) mutate(values)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.number])

    useEffect(() => {
        if (+params.courseId) setFieldValue('course_id', +params.courseId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.courseId])

    return (
        <Form fields={[
            // antField('course_id', {withFalsy: true}),
            antField('name'),
            antField('is_hide'),
        ]}>
            <FactorFormInputs
                switchData={switchData}
                values={values}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default FactorAddForm