import React from 'react'
import './PageTitle.scss'

type PageTitleProps = {
    text: string
}

const PageTitle: React.FC<PageTitleProps> = (props) => {
    const {
        text
    } = props

    return (
        <h2 className="uif-page-title">{ text }</h2>
    )
}

export default PageTitle