import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { CourseReviewFormFields } from '../../../../../types/formFieldTypes';
import { getAntField } from '../../../../../utils/form';
import { clearCourseReviewsErrors, courseReviewsValidate, initialCourseReviewsFields } from '../../formData/CourseReviewFormData';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { SwitchCourseReviewItems } from '../../../../../types/propsTypes';
import CourseReviewFormInputs from '../CourseReviewFormInputs/CourseReviewFormInputs';
import { ObjDB, UploadObj } from '../../../../../types/apiTypes';
import { queryKeysCourseReview } from '../../../../../static/data/queryKeys';

type CourseReviewAddFormProps = {
    switchData: SwitchCourseReviewItems
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
}

const CourseReviewAddForm: React.FC<CourseReviewAddFormProps> = React.memo((props) => {
    const {
        switchData
    } = props
    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        setFieldValue, handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<CourseReviewFormFields>({
        initialValues: initialCourseReviewsFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: courseReviewsValidate
    })

    const antField = getAntField<CourseReviewFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.courseReviews.create,
        {
            mutationKey: 'course-review-add',
            onSuccess() {
                invalidateQueries(queryKeysCourseReview[1])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка создания', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const mutationAvatar = useMutation(
        api.file.add,
        {
            mutationKey: 'course-review-avatar-add',
            onSuccess(data: ObjDB<UploadObj>) {
                setFieldValue('avatar_file_id', data.id)
                setFieldValue('_avatarSrc', data.path)
            },
            onError: (err: Error) => {
                errorNotification(err, 'Ошибка при загрузке файла, загрузите файл заново', formStatus)
                setFieldValue('avatar_file_id', 0)
                setFieldValue('_avatarSrc', '')
            }
        }
    )

    const handleChangeImg = (file: File | null) => {
        if (file) mutationAvatar.mutate(file)
    }

    const clearForm = () => {
        setValues(initialCourseReviewsFields)
        setErrors(clearCourseReviewsErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[
            antField('course_id', {withFalsy: true}),
            antField('text'),
            antField('date', {notSetValue: true}),
            antField('first_name_review'),
            antField('last_name_review'),
        ]}>
            <CourseReviewFormInputs
                switchData={switchData}
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                isFetchingImg={mutationAvatar.isLoading}
                onChangeImg={handleChangeImg}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default CourseReviewAddForm