import { TagFormFields } from "../../../../types/formFieldTypes"

export const tagValidate = (values: TagFormFields) => {
    const errors = {} as { [key: string]: string }
    if (!values.name) errors.name = 'Введите название'
    return errors
}

export const initialTagFields = {
    name: ''
}

export const clearTagErrors = {
    name: ''
}
