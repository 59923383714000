import { CheckCircleOutlined } from '@ant-design/icons'
import React from 'react'
import './RadioImage.scss'

interface RadioImageProps extends React.InputHTMLAttributes<HTMLInputElement> {
    imgSrc: string
}

const RadioImage: React.FC<RadioImageProps> = (props) => {

    const {
        imgSrc, ...inputProps
    } = props

    return (
        <label className="radio-image">
            <input
                type="radio"
                className="radio-image__input"
                {...inputProps}
            />
            <div className="radio-image__check-bg">
                <CheckCircleOutlined />
            </div>
            <div className="radio-image__img">
                <img src={imgSrc} alt="radio" />
            </div>
        </label>
    )
}

export default RadioImage