import { HelprerContentItem } from "../../../../types/propsTypes";

export const courseBGHelpers = {
    pageTitle: [
        {
            type: 'title',
            text: 'Фон курса'
        },
        'Отобажается на странице каталога курсов. Позволяет визуально объединить курсы в группу',
        {
            type: 'image',
            imgSrc: '/static/img/helper/page-title/course-bg.jpg',
        }
    ] as HelprerContentItem[]
}