import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { Form, Input, InputNumber } from 'antd';
import { Select } from 'antd';
import { FormikErrors } from 'formik';
import { PriceFormFields } from '../../../../../types/formFieldTypes';
import FormControls from '../../../../../components/form/FormControls/FormControls';
import { SwitchPriceItems } from '../../../../../types/propsTypes';
import { parseDateToDBTime, parseDBTimeToDate } from '../../../../../utils/parsers';
import Memo from '../../../../../components/hoc/Memo';
const { Option } = Select;

type PriceFormInputsProps = {
    switchData: SwitchPriceItems
    values: PriceFormFields
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<PriceFormFields>> | Promise<void>
    handleChange: React.ChangeEventHandler
    submitForm: () => void
    clearForm?: () => void
    resetChanges?: () => void
}

const PriceFormInputs: React.FC<PriceFormInputsProps> = (props) => {
    const {
        switchData, values,
        handleChange,
        setFieldValue, clearForm,
        submitForm, resetChanges,
    } = props

    console.log('values', values);
    

    return (
        <>
            <Memo
                data={switchData.courses}
                val={values.course_id}
                func={setFieldValue}
            >
                <Form.Item
                    name="course_id"
                    label="Курс"
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите курс"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('course_id', id)}
                    >
                        {
                            switchData.courses.map(c => (
                                <Option value={c.id} key={c.id}>
                                    {c.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo
                data={switchData.cities}
                val={values.city_id}
                func={setFieldValue}
            >
                <Form.Item
                    name="city_id"
                    label="Город"
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите город"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('city_id', id)}
                    >
                        {
                            switchData.cities.map(c => (
                                <Option value={c.id} key={c.id}>
                                    {c.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Form.Item
                name="name"
                label="Название"
                className="uif-ant-form-item"
            >
                <Input onChange={handleChange} />
            </Form.Item>

            <Memo
                val={values.price}
                func={setFieldValue}
            >
                <Form.Item
                    name="price"
                    label="Цена"
                    className="uif-ant-form-item"
                >
                    <InputNumber
                        min={0}
                        formatter={val => `${val} ₽`}
                        onChange={val => setFieldValue('price', Math.ceil(val))}
                    />
                </Form.Item>
            </Memo>

            <Memo
                val={values.date_from}
                func={setFieldValue}
            >
                <Form.Item
                    name="date_from"
                    label="Дата начала"
                    className="uif-ant-form-item"
                >
                    <ReactDatePicker
                        locale="ru"
                        placeholderText="Выберите дату начала"
                        selected={parseDBTimeToDate(values.date_from)}
                        onChange={date => setFieldValue('date_from', parseDateToDBTime(date))}
                        timeInputLabel="Время:"
                        dateFormat="dd/MM/yyyy HH:mm"
                        autoComplete="off"
                        showTimeInput
                    />
                </Form.Item>
            </Memo>

            <Memo
                val={values.date_to}
                func={setFieldValue}
            >
                <Form.Item
                    name="date_to"
                    label="Дата окончания"
                    className="uif-ant-form-item"
                >
                    <ReactDatePicker
                        locale="ru"
                        placeholderText="Выберите дату окончания"
                        selected={parseDBTimeToDate(values.date_to)}
                        onChange={date => setFieldValue('date_to', parseDateToDBTime(date))}
                        timeInputLabel="Время:"
                        dateFormat="dd/MM/yyyy HH:mm"
                        autoComplete="off"
                        showTimeInput
                    />
                </Form.Item>
            </Memo>


            <FormControls
                submitForm={submitForm}
                clearForm={clearForm}
                resetChanges={resetChanges}
            />
        </>
    )
}

export default PriceFormInputs