import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { PriceObj, ObjDB } from '../../../../../types/apiTypes';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import EditPlaceholder from '../../../../../components/common/EditPlaceholder/EditPlaceholder';
import { getAntField } from '../../../../../utils/form';
import { PriceFormFields } from '../../../../../types/formFieldTypes';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { SwitchPriceItems } from '../../../../../types/propsTypes';
import { clearPriceErrors, initialPriceFields, priceValidate } from '../../formData/PriceFormData';
import PriceFormInputs from '../PriceFormInputs/PriceFormInputs';
import { queryKeysPrice } from '../../../../../static/data/queryKeys';

type PriceEditFormProps = {
    editItem: ObjDB<PriceObj> | null
    switchData: SwitchPriceItems
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
    setEditItem: React.Dispatch<React.SetStateAction<ObjDB<PriceObj> | null>>
}

const PriceEditForm: React.FC<PriceEditFormProps> = React.memo((props) => {
    const {
        editItem, setOpenedForm, setEditItem,
        switchData,
    } = props

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        setFieldValue, handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<PriceFormFields>({
        initialValues: initialPriceFields,
        onSubmit: (values) => {
            mutate(values)
        },
        validateOnChange: false,
        validate: priceValidate
    })

    const antField = getAntField<PriceFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        async (values: PriceFormFields) => {
            if (editItem) return await api.prices.edit(values, editItem)
        },
        {
            mutationKey: 'price-edit',
            onSuccess() {
                invalidateQueries(queryKeysPrice[2])
                formStatus.setMessage('Данные успешно изменены')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка редактирования', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialPriceFields)
        setErrors(clearPriceErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setOpenedForm(false)
        setTimeout(() => {
            setEditItem(null)
            formStatus.reset()
        }, 300)
    }

    const resetChanges = () => {
        if (editItem) {
            const { id, ...values } = editItem
            setValues(values)
            setErrors(clearPriceErrors)
        }
    }

    useEffect(() => {
        if (editItem) return resetChanges()
        clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    if (!editItem) return <EditPlaceholder />

    return (
        <Form
            fields={[
                antField('course_id', { withFalsy: true }),
                antField('city_id'),
                antField('name'),
                antField('price'),
                antField('date_from', { notSetValue: true }),
                antField('date_to', { notSetValue: true }),
            ]}
        >
            {
                editItem &&
                <p className="uif-ant-form-title">
                    ID редактируемого объекта: <span>{editItem.id}</span>
                </p>
            }

            <PriceFormInputs
                switchData={switchData}
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                resetChanges={resetChanges}
                submitForm={submitForm}
            />

            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default PriceEditForm