import { LevelFormFields } from "../../../../types/formFieldTypes"

export const levelValidate = (values: LevelFormFields) => {
    const errors = {} as { [key: string]: string }
    if (!values.name) errors.name = 'Введите название'
    return errors
}

export const initialLevelFields = {
    name: ''
}

export const clearLevelErrors = {
    name: ''
}
