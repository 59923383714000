import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { ObjDB, SalaryObj } from '../../../../../types/apiTypes';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import { SalaryFormFields } from '../../../../../types/formFieldTypes';
import EditPlaceholder from '../../../../../components/common/EditPlaceholder/EditPlaceholder';
import { getAntField } from '../../../../../utils/form';
import { clearSalaryErrors, initialSalaryFields, salaryValidate } from '../../formData/SalaryFormData';
import { SalaryFormInputs } from '../SalaryFormInputs/SalaryFormInputs';
import { SwitchSalaryItems } from '../../../../../types/propsTypes';
import { queryKeysSalary } from '../../../../../static/data/queryKeys';
import { invalidateQueries } from '../../../../../utils/rest';

type SalaryEditFormProps = {
    editItem: ObjDB<SalaryObj> | null
    switchDataItems: SwitchSalaryItems
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
    setEditItem: React.Dispatch<React.SetStateAction<ObjDB<SalaryObj> | null>>
}

const SalaryEditForm: React.FC<SalaryEditFormProps> = React.memo((props) => {
    const {
        editItem, setOpenedForm, setEditItem,
        switchDataItems,
    } = props

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange, setFieldValue,
        submitForm, setValues, setErrors,
    } = useFormik<SalaryFormFields>({
        initialValues: initialSalaryFields,
        onSubmit: (values: SalaryFormFields) => {
            mutate(values)
        },
        validate: salaryValidate,
        validateOnChange: false,
    })

    const antField = getAntField<SalaryFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        async (values: SalaryFormFields) => {
            if (editItem) return await api.salaries.edit(values, editItem)
        },
        {
            mutationKey: 'salary-edit',
            onSuccess() {
                invalidateQueries(queryKeysSalary[0])
                formStatus.setMessage('Данные успешно изменены')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.error('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 5000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialSalaryFields)
        setErrors(clearSalaryErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setOpenedForm(false)
        setTimeout(() => {
            setEditItem(null)
            formStatus.reset()
        }, 300)
    }

    const resetChanges = () => {
        if (editItem) {
            setValues({...editItem})
            setErrors(clearSalaryErrors)
        }
    }

    useEffect(() => {
        if (editItem) return resetChanges()
        clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    if (!editItem) return <EditPlaceholder />

    return (
        <Form
            fields={[
                antField('salary'),
                antField('course_id', {withFalsy: true}),
                antField('text'),
            ]}
        >
            {
                editItem &&
                <p className="uif-ant-form-title">
                    ID редактируемого объекта: <span>{editItem.id}</span>
                </p>
            }
            <SalaryFormInputs
                switchDataItems={switchDataItems}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                resetChanges={resetChanges}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default SalaryEditForm