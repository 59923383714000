import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { GoalFormFields } from '../../../../../types/formFieldTypes';
import { getAntField } from '../../../../../utils/form';
import { clearGoalErrors, goalValidate, initialGoalFields } from '../../formData/GoalFormData';
import GoalFormInputs from '../GoalFormInputs/GoalFormInputs';
import { queryKeysGoal } from '../../../../../static/data/queryKeys';
import { invalidateQueries } from '../../../../../utils/rest';


type GoalAddFormProps = {
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
}

const GoalAddForm: React.FC<GoalAddFormProps> = React.memo(() => {

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<GoalFormFields>({
        initialValues: initialGoalFields,
        validateOnChange: false,
        onSubmit: (values: GoalFormFields) => {
            mutate(values)
        },
        validate: goalValidate
    })

    const antField = getAntField<GoalFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.goals.create,
        {
            mutationKey: 'goal-add',
            onSuccess() {
                invalidateQueries(queryKeysGoal[0])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.log('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 4000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialGoalFields)
        setErrors(clearGoalErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[
            antField('name'),
            antField('description')
        ]}>
            <GoalFormInputs
                handleChange={handleChange}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default GoalAddForm