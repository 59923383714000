import React from 'react'
import { Form, Switch } from 'antd';
import { FormikErrors } from 'formik';
import { FactorFormFields } from '../../../../../types/formFieldTypes';
import FormControls from '../../../../../components/form/FormControls/FormControls';
import { SwitchFactorItems } from '../../../../../types/propsTypes';
import Memo from '../../../../../components/hoc/Memo';
import TextArea from 'antd/lib/input/TextArea';

type FactorFormInputsProps = {
    switchData: SwitchFactorItems
    values: FactorFormFields
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<FactorFormFields>> | Promise<void>
    handleChange: React.ChangeEventHandler
    submitForm: () => void
    clearForm?: () => void
    resetChanges?: () => void
}

const FactorFormInputs: React.FC<FactorFormInputsProps> = (props) => {
    const {
        values,
        handleChange,
        setFieldValue, clearForm,
        submitForm, resetChanges,
    } = props

    return (
        <>
            <Memo hdl={handleChange}>
                <Form.Item
                    name="name"
                    label="Текст фактора"
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={e => handleChange(e)} />
                </Form.Item>
            </Memo>

            <Memo
                val={values.is_hide}
                func={setFieldValue}
            >
                <Form.Item
                    label="Скрыто"
                    className="uif-ant-form-item"
                >
                    <Switch checked={values.is_hide} onChange={val => setFieldValue('is_hide', val)} />
                </Form.Item>
            </Memo>

            <FormControls
                submitForm={submitForm}
                clearForm={clearForm}
                resetChanges={resetChanges}
            />
        </>
    )
}

export default FactorFormInputs