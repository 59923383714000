import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { getAntField } from '../../../../../utils/form';
import { trainingProgramsValidate, clearTrainingProgramsErrors, initialTrainingProgramsFields } from '../../formData/TrainingProgramsFormData';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { SwitchTrainingProgramsItems } from '../../../../../types/propsTypes';
import { TrainingProgramsFormFields } from '../../../../../types/formFieldTypes';
import TrainingProgramsFormInputs from '../TrainingProgramsFormInputs/TrainingProgramsFormInputs';
import { sortDataByFieldId } from '../../../../../utils/table';
import { useParams } from 'react-router';
import { ObjDB, TrainingProgramsObj } from '../../../../../types/apiTypes';
import { queryKeysTrainingPrograms } from '../../../../../static/data/queryKeys';

type TrainingProgramsAddFormProps = {
    switchData: SwitchTrainingProgramsItems
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
}

const TrainingProgramsAddForm: React.FC<TrainingProgramsAddFormProps> = React.memo((props) => {
    const {
        switchData
    } = props

    const formStatus = useFormStatus()

    const params = useParams<{educationCourseId: string}>()

    const {
        errors, values, touched,
        setFieldValue, handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<TrainingProgramsFormFields>({
        initialValues: initialTrainingProgramsFields(params.educationCourseId),
        validateOnChange: false,
        onSubmit: () => lastTrainingProgramsMutate.mutate(),
        validate: trainingProgramsValidate
    })

    const antField = getAntField<TrainingProgramsFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.trainingPrograms.create,
        {
            mutationKey: 'training-program-add',
            onSuccess() {
                invalidateQueries(queryKeysTrainingPrograms[1])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка создания', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const lastTrainingProgramsMutate = useMutation<ObjDB<TrainingProgramsObj>[], Error, void, void>(
        api.trainingPrograms.getAll,
        {
            mutationKey: 'training-program-all',
            onSuccess(data) {
                const sortedData = sortDataByFieldId(data, +params.educationCourseId, 'education_course_id')
                setFieldValue('number', sortedData.length + 1)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка получения последнего индекса для сортировки', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialTrainingProgramsFields(params.educationCourseId))
        setErrors(clearTrainingProgramsErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    useEffect(() => {
        if (values.number > 0) mutate(values)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.number])

    useEffect(() => {
        if (+params.educationCourseId) setFieldValue('trainings_stream_id', +params.educationCourseId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.educationCourseId])

    return (
        <Form fields={[
            // antField('trainings_stream_id', {withFalsy: true}),
            antField('title'),
            antField('text'),
            antField('count_hours'),
        ]}>
            <TrainingProgramsFormInputs
                switchData={switchData}
                values={values}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default TrainingProgramsAddForm