import React from 'react'
import { Form, Input, InputNumber, Select, Switch } from 'antd';
import ImageUpload from '../../../../../components/form/ImageUpload/ImageUpload';
import { FormikErrors } from 'formik';
import { CourseFormFields } from '../../../../../types/formFieldTypes';
import FormControls from '../../../../../components/form/FormControls/FormControls';
import TextArea from 'antd/lib/input/TextArea';
import { SwitchCourseItems } from '../../../../../types/propsTypes';
import Memo from '../../../../../components/hoc/Memo';
import RadioImageArea from '../../../../../components/form/RadioImageArea/RadioImageArea';
import RadioImage from '../../../../../components/form/RadioImageArea/components/RadioImage/RadioImage';
import { baseUrl } from '../../../../../config/urls';
import FieldHelper from '../../../../../components/common/FieldHelper/FieldHelper';
import { typeOfTrainingHelpers } from '../../../TypeOfTraining/formData/typeOfTrainingFieldHepler';
import { formTrainingHelpers } from '../../../FormTrainingPage/formData/formTrainingFieldHepler';
import { courseHelpers } from '../../formData/courseFieldHepler';
import { courseBGHelpers } from '../../../CourseBGPage/formData/courseBGFieldHepler';
import { goalHelpers } from '../../../GoalPage/formData/goalFieldHepler';
import { durationHelpers } from '../../../DurationPage/formData/durationHepler';
import { levelHelpers } from '../../../LevelPage/formData/levelFieldHepler';
const { Option } = Select

type ImgPreviewProp = {
    isFetching: boolean
    defaultSrc?: string
    onChangeImg: (file: File | null) => void
}

type CourseFormInputsProps = {
    values: CourseFormFields
    errors: FormikErrors<CourseFormFields>
    switchDataItems: SwitchCourseItems
    handleChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<CourseFormFields>> | Promise<void>
    submitForm: () => void
    clearForm?: () => void
    resetChanges?: () => void
    documentPreview: ImgPreviewProp
    coursePreview: ImgPreviewProp
}

export const CourseFormInputs: React.FC<CourseFormInputsProps> = (props) => {
    const {
        values, errors, documentPreview,
        switchDataItems, coursePreview,
        handleChange, clearForm, submitForm,
        setFieldValue, resetChanges,
    } = props

    const handleCityId = (val: number[]) => {
        if (val.includes(0)) return setFieldValue('city_ids', [0])
        setFieldValue('city_ids', val)
    }

    const handleChangeInstallment = (val: boolean) => {
        if (!val) setFieldValue('installment_months', 0)
        setFieldValue('is_installment', val)
    }

    const handleChangeMaxSeats = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (value === '') {
            setFieldValue('max_seats', null)
            return
        }
        if (!Number.isNaN(+value)) {
            console.log(value);
            setFieldValue('max_seats', Math.ceil(+value))
            return
        }
        setFieldValue('max_seats', values.max_seats)
    }

    return (
        <>
            <Memo hdl={handleChange}>
                <Form.Item
                    name="name"
                    label="Название курса"
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={e => handleChange(e)} />
                </Form.Item>
            </Memo>

            <Memo hdl={handleChange}>
                <Form.Item
                    name="description"
                    label="Описание"
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={e => handleChange(e)} />
                </Form.Item>
            </Memo>

            <Memo
                err={errors.code}
                func={setFieldValue}
            >
                <Form.Item
                    name="code"
                    label="Код номенклатуры"
                    className="uif-ant-form-item uif-course-page__wide-number"
                >
                    <InputNumber
                        min={0}
                        onChange={val => setFieldValue('code', Math.ceil(val))}
                    />
                </Form.Item>
            </Memo>

            <Memo
                dirs={switchDataItems.directions}
                vals={values.direction_id}
                err={errors.direction_id}
                func={setFieldValue}
            >
                <Form.Item
                    name="direction_id"
                    label="Направление"
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите направление"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('direction_id', id)}
                    >
                        {
                            switchDataItems.directions.map(d => (
                                <Option value={d.id} key={d.id}>
                                    {d.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo
                dirs={switchDataItems.typesOfTraining}
                vals={values.type_of_training_id}
                err={errors.type_of_training_id}
                func={setFieldValue}
            >
                <Form.Item
                    name="type_of_training_id"
                    label={<FieldHelper
                        content={typeOfTrainingHelpers.pageTitle}
                        text="Тип обучения"
                    />}
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите тип обучения"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('type_of_training_id', id)}
                    >
                        {
                            switchDataItems.typesOfTraining.map(d => (
                                <Option value={d.id} key={d.id}>
                                    {d.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo
                dirs={switchDataItems.formTrainings}
                vals={values.form_training_id}
                err={errors.form_training_id}
                func={setFieldValue}
            >
                <Form.Item
                    name="form_training_id"
                    label={<FieldHelper
                        content={formTrainingHelpers.pageTitle}
                        text="Форма обучения"
                    />}
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите форму обучения"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('form_training_id', id)}
                    >
                        {
                            switchDataItems.formTrainings.map(f => (
                                <Option value={f.id} key={f.id}>
                                    {f.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo hdl={handleChange}>
                <Form.Item
                    name="document_name"
                    label={<FieldHelper
                        content={courseHelpers.document}
                        text="Имя документа"
                    />}
                    className="uif-ant-form-item"
                >
                    <Input onChange={e => handleChange(e)} />
                </Form.Item>
            </Memo>

            <Memo hdl={handleChange}>
                <Form.Item
                    name="document_description"
                    label={<FieldHelper
                        content={courseHelpers.document}
                        text="Описание документа"
                    />}
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={e => handleChange(e)} />
                </Form.Item>
            </Memo>

            <Memo
                val={values._documentPreviewSrc}
                err={errors.document_preview_id}
                def={documentPreview.defaultSrc}
                isFetch={documentPreview.isFetching}
                func={setFieldValue}
            >
                <Form.Item
                    label={<FieldHelper
                        content={courseHelpers.document}
                        text="Превью документа"
                    />}
                    className="uif-ant-form-item"
                >
                    <ImageUpload
                        error={errors.document_preview_id}
                        onlyImg={true}
                        name="document-preview"
                        isFetching={documentPreview.isFetching}
                        defaultImgSrc={values._documentPreviewSrc || documentPreview.defaultSrc}
                        onChange={documentPreview.onChangeImg}
                    />
                </Form.Item>
            </Memo>

            <Memo
                val={values._previewCourseSrc}
                err={errors.preview_course_id}
                def={coursePreview.defaultSrc}
                isFetch={coursePreview.isFetching}
                func={setFieldValue}
            >
                <Form.Item
                    label={<FieldHelper
                        content={courseHelpers.coursePreview}
                        text="Превью курса"
                    />}
                    className="uif-ant-form-item"
                >
                    <ImageUpload
                        error={errors.preview_course_id}
                        onlyImg={true}
                        name="course-preview"
                        isFetching={coursePreview.isFetching}
                        defaultImgSrc={values._previewCourseSrc || coursePreview.defaultSrc}
                        onChange={coursePreview.onChangeImg}
                    />
                </Form.Item>
            </Memo>

            <Memo
                data={switchDataItems.backgrounds}
                err={errors.background_id}
                values={values.background_id}
                hdl={setFieldValue}
            >
                <Form.Item
                    label={<FieldHelper
                        content={courseBGHelpers.pageTitle}
                        text="Фоновый рисунок"
                    />}
                    className="uif-ant-form-item"
                >
                    <RadioImageArea
                        error={errors.background_id}
                        wrapClasses={['course-bgs']}
                    >
                        {
                            switchDataItems.backgrounds.map(bg => (
                                <RadioImage
                                    name="bg"
                                    value={bg.id}
                                    key={bg.id}
                                    imgSrc={`${baseUrl}${bg.img.path}`}
                                    checked={values.background_id === bg.id}
                                    onChange={e => setFieldValue('background_id', bg.id)}
                                />
                            ))
                        }
                    </RadioImageArea>
                </Form.Item>
            </Memo>

            <Memo
                cont={switchDataItems.cities}
                err={errors.city_ids}
                func={setFieldValue}
            >
                <Form.Item
                    name="city_ids"
                    label="Города"
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        allowClear
                        mode="multiple"
                        placeholder="Выберите города"
                        optionFilterProp="children"
                        onChange={handleCityId}
                    >
                        {
                            switchDataItems.cities.map(c => (
                                <Option value={c.id} key={c.id}>
                                    {c.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo
                cont={switchDataItems}
                err={errors.coach_ids}
                func={setFieldValue}
            >
                <Form.Item
                    name="coach_ids"
                    label="Преподаватели"
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        allowClear
                        mode="multiple"
                        placeholder="Выберите преподавателей"
                        optionFilterProp="children"
                        onChange={(val: number[]) => setFieldValue('coach_ids', val)}
                    >
                        {
                            switchDataItems.coaches.map(c => (
                                <Option value={c.id} key={c.id}>
                                    {c.first_name + ' ' + c.last_name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo
                cont={switchDataItems}
                err={errors.tag_ids}
                func={setFieldValue}
            >
                <Form.Item
                    name="tag_ids"
                    label="Теги (необязательное)"
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        allowClear
                        mode="multiple"
                        placeholder="Выберите теги"
                        optionFilterProp="children"
                        onChange={(val: number[]) => setFieldValue('tag_ids', val)}
                    >
                        {
                            switchDataItems.tags.map(t => (
                                <Option value={t.id} key={t.id}>
                                    {t.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo
                cont={switchDataItems.goals}
                err={errors.goal_id}
                func={setFieldValue}
            >
                <Form.Item
                    name="goal_id"
                    label={<FieldHelper
                        content={goalHelpers.pageTitle}
                        text="Цель"
                    />}
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите цель"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('goal_id', id)}
                    >
                        {
                            switchDataItems.goals.map(g => (
                                <Option value={g.id} key={g.id}>
                                    {g.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo
                cont={switchDataItems.durations}
                err={errors.duration_id}
                func={setFieldValue}
            >
                <Form.Item
                    name="duration_id"
                    label={<FieldHelper
                        content={durationHelpers.pageTitle}
                        text="Длительность"
                    />}
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите длительность"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('duration_id', id)}
                    >
                        {
                            switchDataItems.durations.map(d => (
                                <Option value={d.id} key={d.id}>
                                    {d.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo
                cont={switchDataItems.levels}
                err={errors.level_id}
                func={setFieldValue}
            >
                <Form.Item
                    name="level_id"
                    label={<FieldHelper
                        content={levelHelpers.pageTitle}
                        text="Уровень сложности"
                    />}
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите уровень"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('level_id', id)}
                    >
                        {
                            switchDataItems.levels.map(l => (
                                <Option value={l.id} key={l.id}>
                                    {l.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo hdl={handleChange}>
                <Form.Item
                    name="position"
                    label={<FieldHelper
                        content={courseHelpers.position}
                        text="Должность по окончанию обучения"
                    />}
                    className="uif-ant-form-item"
                >
                    <Input onChange={e => handleChange(e)} />
                </Form.Item>
            </Memo>

            <Memo hdl={handleChange}>
                <Form.Item
                    name="training_result"
                    label={<FieldHelper
                        content={courseHelpers.trainingResultDescription}
                        text='Описание блока "Итоги обучения"'
                    />}
                    className="uif-ant-form-item"
                >
                    <TextArea rows={3} onChange={e => handleChange(e)} />
                </Form.Item>
            </Memo>

            <Memo
                err={errors.hours}
                func={setFieldValue}
            >
                <Form.Item
                    name="hours"
                    label="Количество часов"
                    className="uif-ant-form-item"
                >
                    <InputNumber
                        min={0}
                        onChange={val => setFieldValue('hours', Math.ceil(val))}
                    />
                </Form.Item>
            </Memo>

            <Memo
                hdl={handleChange}
                err={errors.amount_of_time}
            >
                <Form.Item
                    name="amount_of_time"
                    label={<FieldHelper
                        content={courseHelpers.amountOfTime}
                        text="Количество недель"
                    />}
                    className="uif-ant-form-item"
                >
                    <Input placeholder="4 недели" onChange={e => handleChange(e)} />
                </Form.Item>
            </Memo>

            <Memo
                err={errors.months}
                func={setFieldValue}
            >
                <Form.Item
                    name="months"
                    label="Количество месяцев"
                    className="uif-ant-form-item"
                >
                    <InputNumber
                        min={0}
                        onChange={val => setFieldValue('months', Math.ceil(val))}
                    />
                </Form.Item>
            </Memo>

            <Memo
                err={errors.max_seats}
                func={setFieldValue}
            >
                <Form.Item
                    name="max_seats"
                    label={<FieldHelper
                        content={courseHelpers.maxSeats}
                        text="Количество мест"
                    />}
                    className="uif-ant-form-item"
                >
                    <Input onInput={handleChangeMaxSeats} />
                </Form.Item>
            </Memo>

            <Memo
                val={values.is_hide}
                func={setFieldValue}
            >
                <Form.Item
                    label="Скрыто"
                    className="uif-ant-form-item"
                >
                    <Switch
                        checked={!!values.is_hide}
                        onChange={val => setFieldValue('is_hide', val)}
                    />
                </Form.Item>
            </Memo>

            <Memo
                val={values.is_internship}
                func={setFieldValue}
            >
                <Form.Item
                    label={<FieldHelper
                        content={courseHelpers.practice}
                        text="Показывать блок практики"
                    />}
                    className="uif-ant-form-item"
                >
                    <Switch
                        checked={!!values.is_internship}
                        onChange={val => setFieldValue('is_internship', val)}
                    />
                </Form.Item>
            </Memo>

            <Memo
                val={values.is_installment}
                func={setFieldValue}
            >
                <Form.Item
                    label="Наличие рассрочки"
                    className="uif-ant-form-item"
                >
                    <Switch
                        checked={!!values.is_installment}
                        onChange={handleChangeInstallment}
                    />
                </Form.Item>
            </Memo>

            {
                values.is_installment &&
                <Memo
                    err={errors.installment_months}
                    func={setFieldValue}
                >
                    <Form.Item
                        name="installment_months"
                        label="Количество месяцев рассрочки"
                        className="uif-ant-form-item uif-course-page__wide-number"
                    >
                        <InputNumber
                            min={0}
                            onChange={val => setFieldValue('installment_months', Math.ceil(val))}
                        />
                    </Form.Item>
                </Memo>
            }

            <FormControls
                submitForm={submitForm}
                clearForm={clearForm}
                resetChanges={resetChanges}
            />
        </>
    )
}

export default CourseFormInputs