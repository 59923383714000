import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { TrainingStreamObj, ObjDB } from '../../../../../types/apiTypes';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import EditPlaceholder from '../../../../../components/common/EditPlaceholder/EditPlaceholder';
import { getAntField } from '../../../../../utils/form';
import { clearTrainingStreamsErrors, initialTrainingStreamsFields, trainingStreamsValidate } from '../../formData/TrainingStreamsFormData';
import { TrainingStreamsFormFields } from '../../../../../types/formFieldTypes';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import TrainingStreamsFormInputs from '../TrainingStreamsFormInputs/TrainingStreamsFormInputs';
import { SwitchTrainingStreamsItems } from '../../../../../types/propsTypes';
import { queryKeysTrainingStreams } from '../../../../../static/data/queryKeys';

type TrainingStreamsEditFormProps = {
    editItem: ObjDB<TrainingStreamObj> | null
    switchData: SwitchTrainingStreamsItems
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
    setEditItem: React.Dispatch<React.SetStateAction<ObjDB<TrainingStreamObj> | null>>
}

const TrainingStreamsEditForm: React.FC<TrainingStreamsEditFormProps> = React.memo((props) => {
    const {
        editItem, setOpenedForm, setEditItem,
        switchData,
    } = props

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        setFieldValue,
        submitForm, setValues, setErrors,
    } = useFormik<TrainingStreamsFormFields>({
        initialValues: initialTrainingStreamsFields,
        onSubmit: (values) => {
            mutate(values)
        },
        validateOnChange: false,
        validate: trainingStreamsValidate
    })

    const antField = getAntField<TrainingStreamsFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        async (values: TrainingStreamsFormFields) => {
            if (editItem) return await api.trainingStreams.edit(values, editItem)
        },
        {
            mutationKey: 'training-stream-edit',
            onSuccess() {
                invalidateQueries(queryKeysTrainingStreams[2])
                formStatus.setMessage('Данные успешно изменены')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка редактирования', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const resetChanges = () => {
        if (editItem) {
            const { id, ...itemFields } = editItem
            setValues(itemFields)
            setErrors(clearTrainingStreamsErrors)
        }
    }

    const clearForm = () => {
        setValues(initialTrainingStreamsFields)
        setErrors(clearTrainingStreamsErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setOpenedForm(false)
        setTimeout(() => {
            setEditItem(null)
            formStatus.reset()
        }, 300)
    }

    useEffect(() => {
        if (editItem) {
            const { id, ...fields } = editItem
            setValues(fields)
            setErrors(clearTrainingStreamsErrors)
            return
        }
        clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    if (!editItem) return <EditPlaceholder />

    return (
        <Form
            fields={[
                antField('name'),
                antField('course_id', {withFalsy: true}),
                antField('city_id'),
                antField('education_course_id', {withFalsy: true}),
                antField('date_from', {notSetValue: true}),
                antField('date_to', {notSetValue: true}),
            ]}
        >
            {
                editItem &&
                <p className="uif-ant-form-title">
                    ID редактируемого объекта: <span>{editItem.id}</span>
                </p>
            }

            <TrainingStreamsFormInputs
                switchData={switchData}
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
                resetChanges={resetChanges}
                submitForm={submitForm}
            />

            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default TrainingStreamsEditForm