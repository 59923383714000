import React from 'react'

type MemoChildrenProps = {
    memoChildren: React.ReactNode
}

const MemoChildren: React.FC<MemoChildrenProps> = React.memo(({memoChildren}) => {
    return (
        <>
            { memoChildren }
        </>
    )
})

export default MemoChildren