import { SiteSettingFormFields } from "../../../types/formFieldTypes"

const aboutCourses = {
    course_1: '',
    course_2: '',
    course_3: '',
    course_4: '',
    course_5: '',
    course_6: '',
    course_7: '',
    course_8: '',
}

export const siteSettingValidate = (values: SiteSettingFormFields) => {
    const errors = {} as { [key: string]: string }
    if (!values.tag_id) errors.tag_id = 'Выберите тег'
    if (!values.tag_text) errors.tag_text = 'Введите текст тега'
    if (!values.tel_number) errors.tel_number = 'Укажите номер телефона'
    if (!values.offline_text) errors.offline_text = 'Укажите текст offline-блока'
    if (!values.offline_id) errors.offline_id = 'Выберите тег'
    if (!values.online_id) errors.online_id = 'Выберите тег'
    return errors
}

export const coursesAboutCount = 8

export const initialSiteSettingsFields: SiteSettingFormFields = {
    tag_id: '',
    tag_text: '',
    tel_number: '',
    offline_text: '',
    offline_id: '',
    online_id: '',

    ...aboutCourses,
}

export const clearSiteSettingsErrors = {
    tag_id: '',
    tag_text: '',
    tel_number: '',
    offline_text: '',
    offline_id: '',
    online_id: '',

    ...aboutCourses,
}
