import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { CityObj, ObjDB } from '../../../../../types/apiTypes';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import { CityFormFields } from '../../../../../types/formFieldTypes';
import EditPlaceholder from '../../../../../components/common/EditPlaceholder/EditPlaceholder';
import { getAntField } from '../../../../../utils/form';
import { cityValidate, clearCityErrors, initialCityFields } from '../../formData/CityFormData';
import CityFormInputs from '../CityFormInputs/CityFormInputs';
import { queryKeysCity } from '../../../../../static/data/queryKeys';
import { invalidateQueries } from '../../../../../utils/rest';


type CityEditFormProps = {
    editItem: ObjDB<CityObj> | null
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
    setEditItem: React.Dispatch<React.SetStateAction<ObjDB<CityObj> | null>>
}

const CityEditForm: React.FC<CityEditFormProps> = React.memo((props) => {
    const {
        editItem, setOpenedForm, setEditItem
    } = props

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<CityFormFields>({
        initialValues: initialCityFields,
        onSubmit: (values: CityFormFields) => {
            mutate(values)
        },
        validate: cityValidate
    })

    const antField = getAntField<CityFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        async (values: CityFormFields) => {
            if (editItem) return await api.cities.edit(values, editItem)
        },
        {
            mutationKey: 'city-edit',
            onSuccess() {
                invalidateQueries(queryKeysCity[0])
                formStatus.setMessage('Данные успешно изменены')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.error('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 5000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialCityFields)
        setErrors(clearCityErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setOpenedForm(false)
        setTimeout(() => {
            setEditItem(null)
            formStatus.reset()
        }, 300)
    }

    const resetChanges = () => {
        if (editItem) {
            const { id, ...values } = editItem
            setValues(values)
            setErrors(clearCityErrors)
        }
    }

    useEffect(() => {
        if (editItem) return resetChanges()
        clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    if (!editItem) return <EditPlaceholder />

    return (
        <Form
            fields={[antField('name')]}
        >
            {
                editItem &&
                <p className="uif-ant-form-title">
                    ID редактируемого объекта: <span>{editItem.id}</span>
                </p>
            }
            <CityFormInputs
                handleChange={handleChange}
                resetChanges={resetChanges}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default CityEditForm