import { SkillFormFields } from "../../../../types/formFieldTypes"

export const skillValidate = (values: SkillFormFields) => {
    const errors = {} as { [key: string]: string }

    if (!values.course_id) errors.course_id = 'Выберите курс'
    if (!values.name) errors.name = 'Введите имя'
    return errors
}

export const initialSkillFields: SkillFormFields = {
    course_id: 0,
    name: '',
}

export const clearSkillErrors = {
    course_id: '',
    name: '',
}
