import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { SkillObj, ObjDB } from '../../../../../types/apiTypes';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import EditPlaceholder from '../../../../../components/common/EditPlaceholder/EditPlaceholder';
import { getAntField } from '../../../../../utils/form';
import { SkillFormFields } from '../../../../../types/formFieldTypes';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { SwitchSkillItems } from '../../../../../types/propsTypes';
import { clearSkillErrors, initialSkillFields, skillValidate } from '../../formData/SkillFormData';
import SkillFormInputs from '../SkillFormInputs/SkillFormInputs';
import { queryKeysSkill } from '../../../../../static/data/queryKeys';

type SkillEditFormProps = {
    editItem: ObjDB<SkillObj> | null
    switchData: SwitchSkillItems
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
    setEditItem: React.Dispatch<React.SetStateAction<ObjDB<SkillObj> | null>>
}

const SkillEditForm: React.FC<SkillEditFormProps> = React.memo((props) => {
    const {
        editItem, setOpenedForm, setEditItem,
        switchData,
    } = props

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        setFieldValue, handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<SkillFormFields>({
        initialValues: initialSkillFields,
        onSubmit: (values) => {
            mutate(values)
        },
        validateOnChange: false,
        validate: skillValidate
    })

    const antField = getAntField<SkillFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        async (values: SkillFormFields) => {
            if (editItem) return await api.skills.edit(values, editItem)
        },
        {
            mutationKey: 'skill-edit',
            onSuccess() {
                invalidateQueries(queryKeysSkill[1])
                formStatus.setMessage('Данные успешно изменены')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка редактирования', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialSkillFields)
        setErrors(clearSkillErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setOpenedForm(false)
        setTimeout(() => {
            setEditItem(null)
            formStatus.reset()
        }, 300)
    }

    const resetChanges = () => {
        if (editItem) {
            const { id, ...values } = editItem
            setValues(values)
            setErrors(clearSkillErrors)
        }
    }

    useEffect(() => {
        if (editItem) return resetChanges()
        clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    if (!editItem) return <EditPlaceholder />

    return (
        <Form
            fields={[
                antField('course_id', {withFalsy: true}),
                antField('name'),
            ]}
        >
            {
                editItem &&
                <p className="uif-ant-form-title">
                    ID редактируемого объекта: <span>{editItem.id}</span>
                </p>
            }

            <SkillFormInputs
                switchData={switchData}
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                resetChanges={resetChanges}
                submitForm={submitForm}
            />

            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default SkillEditForm