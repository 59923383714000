import React from 'react'
import { Link } from 'react-router-dom'
import './TableLinksBlock.scss'

type Item = {
    id: number,
    name?: string
    first_name?: string
    last_name?: string
}

type TableLinksBlockProps = {
    allItems: Item[]
    checkedIds: number[]
    basePath: string
    shouldSort?: boolean
}

const TableLinksBlock: React.FC<TableLinksBlockProps> = (props) => {
    const {
        allItems, checkedIds, shouldSort = true,
        basePath,
    } = props

    let checkedItems = allItems.filter(item => checkedIds.includes(item.id))

    const sortItems = () => {
        checkedItems = checkedItems.sort(
            (a, b) => {
                if (a.name && b.name) return a.name.localeCompare(b.name)
                if (a.first_name && b.first_name) {
                    const nameStringA = a.first_name + (a.last_name || '')
                    const nameStringB = b.first_name + (b.last_name || '')
                    return nameStringA.localeCompare(nameStringB)
                }
                return 0
            }
        )
    }

    const linkText = (item: Item) => {
        if (item.name) return item.name
        if (item.first_name || item.last_name) {
            return `${item.first_name} ${item.last_name}`
        }
    }

    if (shouldSort) sortItems()

    return (
        <div>
            {
                checkedItems.map(item => (
                    <Link
                        to={`${basePath}/${item.id}`}
                        key={item.id}
                        className="table-block-link"
                    >
                        {linkText(item)}
                    </Link>
                ))
            }
        </div>
    )
}

export default TableLinksBlock