import { EducationCourseFormFields } from "../../../../types/formFieldTypes"

export const educationCourseValidate = (values: EducationCourseFormFields) => {
    const errors = {} as { [key: string]: string }
    if (!values.name) errors.name = 'Введите название'
    return errors
}

export const initialEducationCourseFields = {
    name: ''
}

export const clearEducationCourseErrors = {
    name: ''
}
