import { HelprerContentItem } from "../../../../types/propsTypes";

export const levelHelpers = {
    pageTitle: [
        {
            type: 'title',
            text: 'Уровни сложности'
        },
        'Выступает в качестве фильтра для каталога курсов',
    ] as HelprerContentItem[]
}