import { ColumnsType } from 'antd/lib/table'
import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { api } from '../../../api/api'
import PageTitle from '../../../components/common/PageTitle/PageTitle'
import NavbarLayout from '../../../components/layout/NavbarLayout/NavbarLayout'
import TableObjects from '../../../components/ui/TableObjects/TableObjects'
import { CourseWithSummaryObj, ObjDB } from '../../../types/apiTypes'
import useScrollMount from '../../../utils/hooks/useScrollMount'
import { useTableObject } from '../../../utils/hooks/useTableObject'
import { optionsQueryGetAll } from '../../../utils/rest'
import './CourseListPage.scss'

type CourseListPageProps = {
    pageTitle: string
    pageDescription: string
    linkPath: string
}

type TableItem = ObjDB<CourseWithSummaryObj>

const CourseListPage: React.FC<CourseListPageProps> = (props) => {

    const {
        pageTitle,
        pageDescription,
        linkPath,
    } = props

    const {
        selectedRowKeys,
        getColumnSearchProps,
    } = useTableObject()

    const queryCourse = useQuery<CourseWithSummaryObj[], Error, ObjDB<CourseWithSummaryObj>[]>(
        'course-all',
        api.courses.getAll,
        { ...optionsQueryGetAll }
    )

    const columns: ColumnsType<TableItem> = useMemo(() => {
        return [
            {
                title: 'ID курса',
                dataIndex: 'id',
                sorter: { compare: (a, b) => a.id - b.id },
                ...getColumnSearchProps('id'),
                width: 100,
            },
            {
                title: 'Название курса',
                dataIndex: 'name',
                sorter: { compare: (a, b) => a.name.localeCompare(b.name) },
                ...getColumnSearchProps('name'),
                render: (name, record) => <Link to={`${linkPath}/${record.id}`}>
                    {name}
                </Link>,
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumnSearchProps])

    useScrollMount()

    return (
        <NavbarLayout>
            <section className="uif-course-list-page">
                <PageTitle text={pageTitle} />

                <p className="uif-course-list-page__description">
                    {pageDescription}
                </p>

                <TableObjects
                    selectedRowKeys={selectedRowKeys}
                    count={queryCourse.data?.length || 0}
                    columns={columns}
                    dataSource={queryCourse.data}
                    rowKey="id"
                    isFetching={queryCourse.isFetching}
                    scroll={{x: 440}}
                />
            </section>
        </NavbarLayout >
    )
}

export default CourseListPage