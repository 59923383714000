import React from 'react'
import { Form, Input } from 'antd';
import ImageUpload from '../../../../../components/form/ImageUpload/ImageUpload';
import { FormikErrors } from 'formik';
import { CoachFormFields } from '../../../../../types/formFieldTypes';
import FormControls from '../../../../../components/form/FormControls/FormControls';
import FieldHelper from '../../../../../components/common/FieldHelper/FieldHelper';
import { coachHelpers } from '../../formData/coachFieldHepler';
import TextArea from 'antd/lib/input/TextArea';
import Memo from '../../../../../components/hoc/Memo';

type CoachFormInputsProps = {
    values: CoachFormFields
    errors: FormikErrors<CoachFormFields>
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<CoachFormFields>> | Promise<void>
    submitForm: () => void
    clearForm?: () => void
    resetChanges?: () => void
    defaultImgSrc?: string
    isFetchingImg?: boolean
    onChangeImg: (file: File | null) => void
    handleChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
}

export const CoachFormInputs: React.FC<CoachFormInputsProps> = (props) => {
    const {
        values, errors, defaultImgSrc,
        isFetchingImg,
        handleChange, clearForm, submitForm,
        resetChanges, onChangeImg,
    } = props

    console.log('vals', values);

    return (
        <>
            <Form.Item
                name="first_name"
                label="Имя"
                className="uif-ant-form-item"
            >
                <Input onChange={handleChange} />
            </Form.Item>
            <Form.Item
                name="last_name"
                label="Фамилия"
                className="uif-ant-form-item"
            >
                <Input onChange={handleChange} />
            </Form.Item>

            <Memo
                hdl={handleChange}
                val={values.quality}
                err={errors.quality}
            >
                <Form.Item
                    name="quality"
                    label={
                        <FieldHelper
                            content={coachHelpers.level}
                            text="Уровень квалификации"
                        />
                    }
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={e => handleChange(e)} />
                </Form.Item>
            </Memo>

            <Memo
                hdl={handleChange}
                val={values.education}
                err={errors.education}
            >
                <Form.Item
                    name="education"
                    label={
                        <FieldHelper
                            content={coachHelpers.education}
                            text="Образование"
                        />
                    }
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={e => handleChange(e)} />
                </Form.Item>
            </Memo>

            <Memo
                hdl={handleChange}
                val={values.experience}
                err={errors.experience}
            >
                <Form.Item
                    name="experience"
                    label={
                        <FieldHelper
                            content={coachHelpers.experience}
                            text="Опыт работы"
                        />
                    }
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={e => handleChange(e)} />
                </Form.Item>
            </Memo>

            <Form.Item
                label="Изображение"
                className="uif-ant-form-item"
            >
                <ImageUpload
                    error={errors.avatar_id}
                    onlyImg={true}
                    isFetching={isFetchingImg}
                    defaultImgSrc={values._avatarSrc || defaultImgSrc}
                    onChange={onChangeImg}
                />
            </Form.Item>

            <FormControls
                submitForm={submitForm}
                clearForm={clearForm}
                resetChanges={resetChanges}
            />
        </>
    )
}

export default CoachFormInputs