type FilterTableBlockData = {
    id: number,
    name?: string,
    first_name?: string
    last_name?: string
}

type FilterCoachTableLinkData = {
    id: number,
    first_name: string
    last_name: string
}

type SortTableLinkByNameData = {
    id: number
    name: string
}

type SortTableLinkByCoachNameData = {
    id: number
    first_name: string
    last_name: string
}

export const filterTableLink = <Data extends Array<{ id: number, name: string }>>(
    valueFinder: string | number | boolean,
    recordIdItem: number,
    items: Data
) => {
    let lowerValue = valueFinder.toString().toLowerCase()
    const item = items.find(it => it.id === recordIdItem)
    if (!item) {
        console.warn('В фильтр передан несуществующий id', items)
        return false
    }
    const loverName = item.name.toLowerCase()
    return loverName.includes(lowerValue)
}

export const filterCoachTableLink = <Data extends FilterCoachTableLinkData[]>(
    valueFinder: string | number | boolean,
    recordIdItem: number,
    items: Data
) => {
    let lowerValue = valueFinder.toString().toLowerCase()
    const item = items.find(it => it.id === recordIdItem)
    if (!item) {
        console.warn('В фильтр передан несуществующий id', items)
        return false
    }
    const loverName = `${item.first_name} ${item.last_name}`.toLowerCase()
    return loverName.includes(lowerValue)
}

export const filterTableBlockLinks = <Data extends FilterTableBlockData[]>(
    valueFinder: string | number | boolean,
    recordItemIds: number[],
    allItems: Data
) => {
    let lowerValue = valueFinder.toString().toLowerCase()
    let linksString = ''
    if (allItems.some(it => it.name)) {
        const items = allItems.filter(item => recordItemIds.includes(item.id))
        linksString = items.map(it => it.name).join('').toLowerCase()
    }
    else if (allItems.some(it => it.first_name)) {
        const items = allItems.filter(item => recordItemIds.includes(item.id))
        linksString = items.map(it => `${it.first_name} ${it.last_name}`).join('').toLowerCase()
    }
    return linksString.includes(lowerValue)
}

export const sortTableLinkByName = <Data extends SortTableLinkByNameData[]>(aId: number, bId: number, data: Data) => {
    const itemA = data.find(d => d.id === aId)
    const itemB = data.find(d => d.id === bId)
    if (itemA && itemB) {
        return itemA.name.localeCompare(itemB.name)
    }
    return 0
}

export const sortTableLinkByCoachName = <Data extends SortTableLinkByCoachNameData[]>(aId: number, bId: number, data: Data) => {
    const itemA = data.find(d => d.id === aId)
    const itemB = data.find(d => d.id === bId)
    if (itemA && itemB) {
        const aName = `${itemA.first_name} ${itemA.last_name}`
        const bName = `${itemB.first_name} ${itemB.last_name}`
        return aName.localeCompare(bName)
    }
    return 0
}

export const sortDataByFieldId = <DataItem extends { number: number }>(
    data: DataItem[],
    paramId: number,
    field: keyof DataItem
): DataItem[] => {
    const dataById = data.filter(item => +item[field] === paramId)
    const sortedData = dataById.sort((a, b) => a.number - b.number)
    const changedSortedData = sortedData.map((item, idx) => {
        const newItem = { ...item }
        if (newItem.number !== idx + 1) {
            newItem.number = idx + 1
        }
        return newItem
    })
    return changedSortedData
}