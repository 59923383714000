import React, { useMemo, useState } from 'react'
import NavbarLayout from '../../../components/layout/NavbarLayout/NavbarLayout'
import './FactorPage.scss'
import SoloCollapse from '../../../components/ui/SoloCollapse/SoloCollapse';
import {
    ColumnsType,
} from 'antd/lib/table/interface';
import TableObjects from '../../../components/ui/TableObjects/TableObjects';
import { useTableObject } from '../../../utils/hooks/useTableObject';
import { addCollapseIcon, editCollapseIcon } from '../../../components/ui/SoloCollapse/components/icons';
import TableActionButtons from '../../../components/ui/TableObjects/components/TableActionButtons/TableActionButtons';
import { useMutation, useQuery } from 'react-query';
import { CourseWithSummaryObj, ObjDB, FactorObj } from '../../../types/apiTypes';
import { api } from '../../../api/api';
import { getSelectedItems } from '../../../utils/parsers';
import { openNotification, tableLinkId } from '../../../utils/jsxUtils';
import { RouteComponentProps, useParams } from 'react-router';
import { errorNotification, invalidateQueries, optionsQueryGetAll } from '../../../utils/rest';
import { SwitchFactorItems } from '../../../types/propsTypes';
import { sortDataByFieldId } from '../../../utils/table';
import FactorAddForm from './components/FactorAddForm/FactorAddForm';
import { SortableContainerProps } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { DraggableBodyRow, DraggableContainer, DragHandle } from '../../../components/table/dragTable/dragTable';
import FactorEditForm from './components/FactorEditForm/FactorEditForm';
import { queryKeysFactor } from '../../../static/data/queryKeys';
import useScrollMount from '../../../utils/hooks/useScrollMount';
import { useEffect } from 'react';
import FieldHelper from '../../../components/common/FieldHelper/FieldHelper';
import { factorHelpers } from './formData/factorFieldHepler';
import { notification } from 'antd';


interface FactorPageProps extends RouteComponentProps { }

type TableItem = ObjDB<FactorObj>

const FactorPage: React.FC<FactorPageProps> = () => {

    const [openedAddForm, setOpenedAddForm] = useState<boolean>(false)
    const [openedEditForm, setOpenedEditForm] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<TableItem | null>(null)
    const [tableHeadLoader, setTableHeadLoader] = useState<boolean>(false)
    const [allData, setAllData] = useState<TableItem[]>([])
    const [shouldShowSortNote, setShouldShowSortNote] = useState<boolean>(true)

    const params = useParams<{courseId: string}>()

    const {
        selectedRowKeys,
        setSelectedRowKeys,
        getColumnSearchProps,
    } = useTableObject()

    const queryCourse = useQuery<CourseWithSummaryObj[], Error, ObjDB<CourseWithSummaryObj>[]>(
        queryKeysFactor[0],
        api.courses.getAll,
        { ...optionsQueryGetAll }
    )

    const courseById = queryCourse.data?.find(c => c.id === +params.courseId)

    const deleteManyMutate = useMutation(
        api.factors.deleteMany.bind(api.factors),
        {
            mutationKey: 'factor-delete-many',
            onSuccess() {
                invalidateQueries(queryKeysFactor[1])
                openNotification(
                    'success',
                    'Выбранные объекты успешно удалены'
                )
                setSelectedRowKeys([])
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка при удалении объектов'),
            onSettled() { setTableHeadLoader(false) },
            onMutate() { setTableHeadLoader(true) }
        }
    )

    const sortAllMutate = useMutation(
        api.factors.sortMany.bind(api.factors),
        {
            mutationKey: 'factor-sort-all',
            onSuccess() {
                invalidateQueries(queryKeysFactor[1])
                openNotification(
                    'success',
                    'Сортировка выполнена успешно'
                )
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка при сортировке объектов'),
            onSettled() { setTableHeadLoader(false) },
            onMutate() { setTableHeadLoader(true) }
        }
    )

    const switchData: SwitchFactorItems = { courses: queryCourse.data || [] }

    const queryAll = useQuery<undefined, Error, TableItem[]>(
        queryKeysFactor[1],
        api.factors.getAll,
        { ...optionsQueryGetAll }
    )

    const queryDelete = useMutation(
        'factor-delete',
        api.factors.delete,
        {
            onSuccess() {
                openNotification('success', 'Объект успешно удален')
                invalidateQueries(queryKeysFactor[1])
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка при удалении объекта')
        }
    )

    const columns: ColumnsType<TableItem> = useMemo(() => {
        return [
            {
                title: '№',
                dataIndex: 'sort',
                width: 30,
                className: 'drag-visible',
                render: () => <DragHandle />,
            },
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: 'Название',
                dataIndex: 'name',
            },
            {
                title: 'Индекс (сортировка)',
                dataIndex: 'number',
                render: (val: number) => val,
                width: 130,
            },
            {
                title: 'Скрыто',
                dataIndex: 'is_hide',
                render: (isHide: number) => isHide ? 'Да' : 'Нет',
                width: 110,
            },
            {
                title: 'Курс',
                dataIndex: 'course_id',
                render: (id) => tableLinkId(queryCourse.data || [], id, 'course'),
            },
            {
                title: 'Действие',
                dataIndex: '',
                align: 'right',
                render: (value: TableItem) => (
                    <TableActionButtons
                        onEdit={() => handleEditItem(value)}
                        onDelete={() => queryDelete.mutate(value)}
                    />
                )
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumnSearchProps])

    const showSortNote = () => {
        if (shouldShowSortNote) {
            notification.open({
                message: 'Для сохранения последовательности элементов нажмите иконку "№" в заголовке таблицы',
                placement: 'bottomRight',
                onClose: () => setShouldShowSortNote(false)
            })
        }
    }

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const handleTableAdd = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setOpenedAddForm(true)
    }

    const handleEditItem = (value: TableItem) => {
        setEditItem(value)
        setOpenedEditForm(true)
        setOpenedAddForm(false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleTableDelete = async () => {
        const deleteItems = getSelectedItems<TableItem>(selectedRowKeys, allData)
        deleteManyMutate.mutate(deleteItems)
    }

    const handleTableRefresh = () => invalidateQueries(queryKeysFactor)

    const handleSaveSort = async () => {
        sortAllMutate.mutate(allData)
    }

    const onSortEnd: SortableContainerProps['onSortEnd'] = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            showSortNote()
            const newData = arrayMove([...allData], oldIndex, newIndex).filter(el => !!el);
            setAllData(newData)
        }
    };

    useEffect(() => {
        const responseData = queryAll.data
        if (Array.isArray(responseData)) {
            setAllData(sortDataByFieldId(responseData, +params.courseId, 'course_id'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryAll.data])

    useScrollMount()

    return (
        <NavbarLayout>
            <section className="uif-factor-page">
                <FieldHelper
                    content={factorHelpers.pageTitle}
                    text={`Факторы курса "${courseById?.name}"`}
                    isPageTitle
                />

                <div className="uif-factor-page__collapses">
                    <SoloCollapse
                        header="Создать новый объект"
                        keyCollapse="add"
                        show={openedAddForm}
                        icon={addCollapseIcon}
                        setShow={setOpenedAddForm}
                    >
                        <FactorAddForm
                            switchData={switchData}
                            setOpenedForm={setOpenedAddForm}
                        />
                    </SoloCollapse>
                    <SoloCollapse
                        header="Редактировать объект"
                        keyCollapse="edit"
                        show={openedEditForm}
                        icon={editCollapseIcon}
                        setShow={setOpenedEditForm}
                    >
                        <FactorEditForm
                            editItem={editItem}
                            switchData={switchData}
                            setOpenedForm={setOpenedEditForm}
                            setEditItem={setEditItem}
                        />
                    </SoloCollapse>
                </div>

                <TableObjects
                    selectedRowKeys={selectedRowKeys}
                    count={allData.length}
                    columns={columns}
                    dataSource={allData}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: onSelectChange
                    }}
                    components={{
                        body: {
                            wrapper: DraggableContainer(onSortEnd),
                            row: DraggableBodyRow(allData),
                        },
                    }}
                    onDelete={handleTableDelete}
                    onAdd={handleTableAdd}
                    onRefresh={handleTableRefresh}
                    onSaveSort={handleSaveSort}
                    rowKey="id"
                    isFetching={queryAll.isFetching}
                    headerLoader={tableHeadLoader}
                    scroll={{x: 800}}
                />
            </section>
        </NavbarLayout >
    )
}

export default FactorPage