import React from 'react'
import { Form, Input, InputNumber, Select } from 'antd';
import { FormikErrors } from 'formik';
import { SalaryFormFields } from '../../../../../types/formFieldTypes';
import FormControls from '../../../../../components/form/FormControls/FormControls';
import { SwitchSalaryItems } from '../../../../../types/propsTypes';
const { Option } = Select

type SalaryFormInputsProps = {
    switchDataItems: SwitchSalaryItems
    handleChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<SalaryFormFields>> | Promise<void>
    submitForm: () => void
    clearForm?: () => void
    resetChanges?: () => void
}

export const SalaryFormInputs: React.FC<SalaryFormInputsProps> = (props) => {
    const {
        switchDataItems,
        handleChange, clearForm, submitForm,
        setFieldValue, resetChanges,
    } = props

    return (
        <>
            <Form.Item
                name="salary"
                label="Величина зарплаты"
                className="uif-ant-form-item"
            >
                <InputNumber
                    min={0}
                    onChange={val => setFieldValue('salary', Math.ceil(val))}
                />
            </Form.Item>


            <Form.Item
                name="text"
                label="Описание"
                className="uif-ant-form-item"
            >
                <Input onChange={e => handleChange(e)} />
            </Form.Item>

            <Form.Item
                name="course_id"
                label="Курс"
                className="uif-ant-form-item"
            >
                <Select
                    showSearch
                    placeholder="Выберите курс"
                    optionFilterProp="children"
                    onChange={id => setFieldValue('course_id', id)}
                >
                    {
                        switchDataItems.courses.map(c => (
                            <Option value={c.id} key={c.id}>
                                {c.name}
                            </Option>
                        ))
                    }
                </Select>
            </Form.Item>

            <FormControls
                submitForm={submitForm}
                clearForm={clearForm}
                resetChanges={resetChanges}
            />
        </>
    )
}

export default SalaryFormInputs