import React from 'react'
import { Form, Input } from 'antd';
import ImageUpload from '../../../../../components/form/ImageUpload/ImageUpload';
import { DirectionGroupsFormFields } from '../../../../../types/formFieldTypes';
import { FormikErrors } from 'formik';
import FormControls from '../../../../../components/form/FormControls/FormControls';

type DirectionGroupsFormInputsProps = {
    values: DirectionGroupsFormFields
    errors: FormikErrors<DirectionGroupsFormFields>
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    submitForm: () => void
    defaultImgSrc?: string
    isFetchingImg?: boolean
    onChangeImg: (file: File | null) => void
    resetChanges?: () => void
    clearForm?: () => void
}

export const DirectionGroupsFormInputs: React.FC<DirectionGroupsFormInputsProps> = (props) => {
    const {
        values, errors, defaultImgSrc = '',
        isFetchingImg,
        handleChange, clearForm, submitForm,
        onChangeImg, resetChanges,
    } = props

    return (
        <>
            <Form.Item
                name="name"
                label="Название группы"
                className="uif-ant-form-item"
            >
                <Input onInput={handleChange} />
            </Form.Item>

            <Form.Item
                label="Изображение"
                className="uif-ant-form-item"
            >
                <ImageUpload
                    error={errors.avatar_id}
                    onlyImg={true}
                    defaultImgSrc={values._avatarSrc || defaultImgSrc}
                    isFetching={isFetchingImg}
                    onChange={onChangeImg}
                />
            </Form.Item>

            <FormControls
                submitForm={submitForm}
                clearForm={clearForm}
                resetChanges={resetChanges}
            />
        </>
    )
}

export default DirectionGroupsFormInputs