import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { CourseFormFields } from '../../../../../types/formFieldTypes';
import { getAntField } from '../../../../../utils/form';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { ObjDB, UploadObj } from '../../../../../types/apiTypes';
import { clearCourseErrors, courseValidate, initialCourseFields } from '../../formData/CourseFormData';
import CourseFormInputs from '../CourseFormInputs/CourseFormInputs'
import { SwitchCourseItems } from '../../../../../types/propsTypes';
import { openNotification } from '../../../../../utils/jsxUtils';
import { queryKeysCourse } from '../../../../../static/data/queryKeys';

type CourseAddFormProps = {
    switchDataItems: SwitchCourseItems
}

const CourseAddForm: React.FC<CourseAddFormProps> = React.memo(({switchDataItems}) => {

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange, setFieldValue,
        submitForm, setValues, setErrors,
    } = useFormik<CourseFormFields>({
        initialValues: initialCourseFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate({
                ...values,
                name: values.name.trim()
            })
        },
        validate: courseValidate
    })

    const antField = getAntField<CourseFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.courses.create,
        {
            mutationKey: 'course-add',
            onSuccess() {
                invalidateQueries(queryKeysCourse[11])
                openNotification('success', 'Курс успешно создан. Не забудьте создать учебный поток для курса')
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка создания курса'),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            },
        }
    )

    const mutationCoursePreview = useMutation(
        api.file.add,
        {
            mutationKey: 'course-preview-add',
            onSuccess(data: ObjDB<UploadObj>) {
                setFieldValue('preview_course_id', data.id)
                setFieldValue('_previewCourseSrc', data.path)
            },
            onError: (err: Error) => {
                errorNotification(err, 'Ошибка при загрузке файла, загрузите файл заново')
                setFieldValue('preview_course_id', 0)
                setFieldValue('_previewCourseSrc', '')
            },
        }
    )

    const mutationDocumentPreview = useMutation(
        api.file.add,
        {
            mutationKey: 'document-preview-add',
            onSuccess(data: ObjDB<UploadObj>) {
                setFieldValue('document_preview_id', data.id)
                setFieldValue('_documentPreviewSrc', data.path)
            },
            onError: (err: Error) => {
                errorNotification(err, 'Ошибка при загрузке файла, загрузите файл заново')
                setFieldValue('document_preview_id', 0)
                setFieldValue('_documentPreviewSrc', '')
            },
        }
    )

    const handleChangeDocumentPreview = (file: File | null) => {
        if (file) mutationDocumentPreview.mutate(file)
    }

    const handleChangeCoursePreview = (file: File | null) => {
        if (file) mutationCoursePreview.mutate(file)
    }

    const clearForm = () => {
        setValues(initialCourseFields)
        setErrors(clearCourseErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[
            antField('name'),
            antField('position'),
            antField('code'),
            antField('direction_id', {withFalsy: true}),
            antField('type_of_training_id', {withFalsy: true}),
            antField('form_training_id', {withFalsy: true}),
            antField('background_id', {withFalsy: true}),
            antField('description'),
            antField('document_name'),
            antField('document_description'),
            antField('city_ids', {withFalsy: true}),
            antField('coach_ids', {withFalsy: true}),
            antField('tag_ids', {withFalsy: true}),
            antField('goal_id', {withFalsy: true}),
            antField('duration_id', {withFalsy: true}),
            antField('level_id', {withFalsy: true}),
            antField('hours'),
            antField('months'),
            antField('amount_of_time'),
            antField('max_seats'),
            antField('is_installment'),
            antField('installment_months'),
            antField('training_result'),
        ]}>
            <CourseFormInputs
                values={values}
                errors={errors}
                switchDataItems={switchDataItems}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                clearForm={clearForm}
                submitForm={submitForm}
                documentPreview={{
                    isFetching: mutationDocumentPreview.isLoading,
                    onChangeImg: handleChangeDocumentPreview,
                }}
                coursePreview={{
                    isFetching: mutationCoursePreview.isLoading,
                    onChangeImg: handleChangeCoursePreview,
                }}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default CourseAddForm