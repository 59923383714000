import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { CityFormFields } from '../../../../../types/formFieldTypes';
import { getAntField } from '../../../../../utils/form';
import { cityValidate, clearCityErrors, initialCityFields } from '../../formData/CityFormData';
import CityFormInputs from '../CityFormInputs/CityFormInputs';
import { invalidateQueries } from '../../../../../utils/rest';
import { queryKeysCity } from '../../../../../static/data/queryKeys';


type CityAddFormProps = {}

const CityAddForm: React.FC<CityAddFormProps> = React.memo(() => {

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<CityFormFields>({
        initialValues: initialCityFields,
        validateOnChange: false,
        onSubmit: (values: CityFormFields) => {
            mutate(values)
        },
        validate: cityValidate
    })

    const antField = getAntField<CityFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.cities.create,
        {
            mutationKey: 'city-add',
            onSuccess() {
                invalidateQueries(queryKeysCity[0])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.log('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 4000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialCityFields)
        setErrors(clearCityErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[antField('name')]}>
            <CityFormInputs
                handleChange={handleChange}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default CityAddForm