import React from 'react'
import './FieldHelper.scss'
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ImageFieldHelper, TitleFieldHelper } from '../../../types/propsTypes';
import FieldHelperContent from './components/FieldHelperContent/FieldHelperContent';

type FieldHelperProps = {
    text: string
    content: Array<string | ImageFieldHelper | TitleFieldHelper>
    title?: string
    isPageTitle?: boolean
}

const FieldHelper: React.FC<FieldHelperProps> = (props) => {

    const {
        content, title, text,
        isPageTitle,
    } = props

    return (
        <div className="filed-helper">
            <p className={isPageTitle ? "uif-page-title" : "filed-helper__label-text"}>
                {text}
                <Popover
                    content={<FieldHelperContent data={content} />}
                    title={title}
                    placement="bottomLeft"
                >
                    <QuestionCircleOutlined className="filed-helper__icon" />
                </Popover>
            </p>
        </div>
    )
}

export default FieldHelper