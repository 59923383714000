import Form from 'antd/lib/form/Form'
import { useFormik } from 'formik'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { api } from '../../api/api'
import PageTitle from '../../components/common/PageTitle/PageTitle'
import FormStatus from '../../components/form/FormStatus/FormStatus'
import { queryClient } from '../../components/hoc/ReactQuery'
import NavbarLayout from '../../components/layout/NavbarLayout/NavbarLayout'
import { CourseWithSummaryObj, FormTrainingObj, ObjDB, SiteSettingObj, TagObj } from '../../types/apiTypes'
import { SiteSettingFormFields } from '../../types/formFieldTypes'
import { CreateSettingData, SwitchSiteSettingItems } from '../../types/propsTypes'
import { courseAboutName, getAntField } from '../../utils/form'
import { useFormStatus } from '../../utils/hooks/useFormStatus'
import useScrollMount from '../../utils/hooks/useScrollMount'
import { openNotification } from '../../utils/jsxUtils'
import { errorNotification, optionsQueryGetAll } from '../../utils/rest'
import { initialSiteSettingsFields, siteSettingValidate, clearSiteSettingsErrors, coursesAboutCount } from './formData/siteSettingFormData'
import SiteSettingFormInputs from './SiteSettingFormInputs/SiteSettingFormInputs'
import './SiteSettingPage.scss'

type SiteSettingPageProps = {}

type Item = ObjDB<SiteSettingObj>

const SiteSettingPage: React.FC<SiteSettingPageProps> = () => {

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        setFieldValue,
        submitForm, setErrors,
    } = useFormik<SiteSettingFormFields>({
        initialValues: initialSiteSettingsFields,
        validateOnChange: false,
        onSubmit: (values) => {
            // if the settings are created for the first time
            const newSettings = getNewSetting(values)
            const oldSettings = Object
                .keys(values)
                .filter(field => !newSettings.includes(field as keyof SiteSettingFormFields)) as Array<keyof SiteSettingFormFields>
            addSettingMutate.mutate({ set_keys: newSettings, fields: values })
            editMutate.mutate({
                edit_keys: oldSettings,
                fields: values, settingItems:
                    queryAll.data || []
            })
        },
        validate: siteSettingValidate
    })

    const queryAll = useQuery<Item[], Error, Item[]>(
        'site-setting-all',
        async () => {
            formStatus.setMessage('Обновление данных')
            formStatus.setStatus('fetching')
            return await api.siteSettings.getAll()
        },
        {
            onSuccess(data) {
                settingsToForm(data)
                formStatus.reset()
            },
            onError(err: Error) {
                console.log('ERROR get all objects', err)
                openNotification(
                    'error',
                    'Ошибка получения настроек сайта',
                    err.message
                )
            },
            retry: 1,
            refetchOnWindowFocus: false,
        }
    )

    const addSettingMutate = useMutation<Item[], Error, CreateSettingData>(
        'site-setting-create',
        api.siteSettings.createMany,
        {
            onError(err: Error) {
                console.log('ERROR get all objects', err)
                openNotification(
                    'error',
                    'Ошибка при создании объекта настройки сайта',
                    err.message
                )
            },
            onSettled() { formStatus.reset() },
            onMutate() {
                formStatus.setMessage('Создание объекта')
                formStatus.setStatus('fetching')
            }
        }
    )

    const queryTags = useQuery<undefined, Error, ObjDB<TagObj>[]>(
        'tag-all',
        api.tags.getAll,
        { ...optionsQueryGetAll }
    )

    const queryCourses = useQuery<CourseWithSummaryObj[], Error, CourseWithSummaryObj[]>(
        'course-all',
        api.courses.getAll,
        { ...optionsQueryGetAll }
    )


    const queryFormTrainings = useQuery<undefined, Error, ObjDB<FormTrainingObj>[]>(
        'form-training-all',
        api.formTraining.getAll,
        { ...optionsQueryGetAll }
    )

    const switchData = {
        tags: queryTags.data || [],
        formTrainings: queryFormTrainings.data || [],
        courses: queryCourses.data || [],
    } as SwitchSiteSettingItems

    const editMutate = useMutation(
        api.siteSettings.edit,
        {
            mutationKey: 'site-setting-add',
            onSuccess() {
                formStatus.setMessage('Настройки успешно изменены')
                formStatus.setStatus('success')
                setTimeout(() => {
                    queryClient.invalidateQueries('site-setting-all')
                    formStatus.reset()
                }, 2000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка редактирования', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            },
        }
    )

    const antField = getAntField<SiteSettingFormFields>(values, errors, touched)

    const settingsToForm = (data: Item[]) => {
        data.forEach(setting => {
            setFieldValue(setting.set_key, setting.set_value)
        })
    }

    console.log('vals', values);


    const resetChanges = () => {
        settingsToForm(queryAll?.data || [])
        setErrors(clearSiteSettingsErrors)
    }

    const coursesFields = () => {
        return new Array(coursesAboutCount)
            .fill('')
            .map((_, idx) => antField(courseAboutName(idx + 1), { withFalsy: true }))
    }

    const getNewSetting = (values: SiteSettingFormFields) => {
        if (queryAll.data) {
            const existSettings = queryAll.data.map(s => s.set_key)
            const formFields = Object.keys(values) as Array<keyof SiteSettingFormFields>
            const notCreatedSettings = formFields.filter(setting => !existSettings.includes(setting))
            const notCreatedWithoutEmptyCourses = notCreatedSettings.filter(setting => {
                // if course_"i" not fill and not exists in settings
                return !(setting.startsWith('course_') && values[setting] === '')
            })
            return notCreatedWithoutEmptyCourses
        }
        return [] as Array<keyof SiteSettingFormFields>
    }
    useScrollMount()

    return (
        <NavbarLayout>
            <section className="uif-site-setting-page">
                <PageTitle text="Настройки сайта | Site settings" />

                <Form
                    fields={[
                        antField('tag_id', { withFalsy: true }),
                        antField('tag_text'),
                        antField('tel_number'),
                        antField('offline_text'),
                        antField('offline_id', { withFalsy: true }),
                        antField('online_id', { withFalsy: true }),

                        ...coursesFields(),
                    ]}
                    className="uif-site-setting-page__form"
                >
                    <SiteSettingFormInputs
                        switchData={switchData}
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        submitForm={submitForm}
                        resetChanges={resetChanges}
                    />
                    <FormStatus formStatus={formStatus} />
                </Form>
            </section>
        </NavbarLayout>
    )
}

export default SiteSettingPage