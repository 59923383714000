import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { CourseReviewObj, ObjDB, UploadObj } from '../../../../../types/apiTypes';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import EditPlaceholder from '../../../../../components/common/EditPlaceholder/EditPlaceholder';
import { getAntField } from '../../../../../utils/form';
import { CourseReviewFormFields } from '../../../../../types/formFieldTypes';
import { errorNotification, invalidateQueries, previewQueryOptions } from '../../../../../utils/rest';
import { SwitchCourseReviewItems } from '../../../../../types/propsTypes';
import { clearCourseReviewsErrors, courseReviewsValidate, initialCourseReviewsFields } from '../../formData/CourseReviewFormData';
import CourseReviewFormInputs from '../CourseReviewFormInputs/CourseReviewFormInputs';
import { queryKeysCourseReview } from '../../../../../static/data/queryKeys';

type CourseReviewEditFormProps = {
    editItem: ObjDB<CourseReviewObj> | null
    switchData: SwitchCourseReviewItems
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
    setEditItem: React.Dispatch<React.SetStateAction<ObjDB<CourseReviewObj> | null>>
}

const CourseReviewEditForm: React.FC<CourseReviewEditFormProps> = React.memo((props) => {
    const {
        editItem, setOpenedForm, setEditItem,
        switchData,
    } = props

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        setFieldValue, handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<CourseReviewFormFields>({
        initialValues: initialCourseReviewsFields,
        onSubmit: (values) => {
            mutate(values)
        },
        validateOnChange: false,
        validate: courseReviewsValidate
    })

    const antField = getAntField<CourseReviewFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        async (values: CourseReviewFormFields) => {
            if (editItem) return await api.courseReviews.edit(values, editItem)
        },
        {
            mutationKey: 'course-review-edit',
            onSuccess() {
                invalidateQueries(queryKeysCourseReview[1])
                formStatus.setMessage('Данные успешно изменены')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка редактирования', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            },
        }
    )

    const defaultAvatarMutate = useMutation<ObjDB<UploadObj>, Error, number, void>(
        'course-review-avatar-get',
        api.file.get,
        {...previewQueryOptions(formStatus)}
    )

    const mutationAvatar = useMutation(
        api.file.add,
        {
            mutationKey: 'course-review-avatar-add',
            onSuccess(data: ObjDB<UploadObj>) {
                setFieldValue('avatar_file_id', data.id)
                setFieldValue('_avatarSrc', data.path)
            },
            onError: (err: Error) => {
                errorNotification(err, 'Ошибка при загрузке файла, загрузите файл заново', formStatus)
                setFieldValue('avatar_file_id', 0)
                setFieldValue('_avatarSrc', '')
            },
        }
    )

    const handleChangeImg = (file: File | null) => {
        if (file) mutationAvatar.mutate(file)
    }

    const clearForm = () => {
        setValues(initialCourseReviewsFields)
        setErrors(clearCourseReviewsErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setOpenedForm(false)
        setTimeout(() => {
            setEditItem(null)
            formStatus.reset()
        }, 300)
    }

    const resetChanges = () => {
        if (editItem) {
            const { id, ...itemFields } = editItem
            setValues({...itemFields, _avatarSrc: ''})
            setErrors(clearCourseReviewsErrors)
        }
    }

    useEffect(() => {
        if (editItem) {
            if (editItem.avatar_file_id) defaultAvatarMutate.mutate(editItem.avatar_file_id)
            return resetChanges()
        }
        clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    if (!editItem) return <EditPlaceholder />

    return (
        <Form
            fields={[
                antField('course_id', {withFalsy: true}),
                antField('text'),
                antField('date', {notSetValue: true}),
                antField('first_name_review'),
                antField('last_name_review'),
            ]}
        >
            {
                editItem &&
                <p className="uif-ant-form-title">
                    ID редактируемого объекта: <span>{editItem.id}</span>
                </p>
            }

            <CourseReviewFormInputs
                switchData={switchData}
                errors={errors}
                values={values}
                defaultAvatarSrc={defaultAvatarMutate.data?.path}
                isFetchingImg={mutationAvatar.isLoading}
                onChangeImg={handleChangeImg}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                resetChanges={resetChanges}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default CourseReviewEditForm