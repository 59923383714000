import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { TrainingStreamsFormFields } from '../../../../../types/formFieldTypes';
import { getAntField } from '../../../../../utils/form';
import { clearTrainingStreamsErrors, initialTrainingStreamsFields, trainingStreamsValidate } from '../../formData/TrainingStreamsFormData';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import TrainingStreamsFormInputs from '../TrainingStreamsFormInputs/TrainingStreamsFormInputs'
import { SwitchTrainingStreamsItems } from '../../../../../types/propsTypes';
import { queryKeysTrainingStreams } from '../../../../../static/data/queryKeys';

type TrainingStreamsAddFormProps = {
    switchData: SwitchTrainingStreamsItems
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
}

const TrainingStreamsAddForm: React.FC<TrainingStreamsAddFormProps> = React.memo((props) => {
    const {
        switchData
    } = props
    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        setFieldValue,
        submitForm, setValues, setErrors,
    } = useFormik<TrainingStreamsFormFields>({
        initialValues: initialTrainingStreamsFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: trainingStreamsValidate
    })

    const antField = getAntField<TrainingStreamsFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.trainingStreams.create,
        {
            mutationKey: 'training-stream-add',
            onSuccess() {
                invalidateQueries(queryKeysTrainingStreams[2])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка создания', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialTrainingStreamsFields)
        setErrors(clearTrainingStreamsErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[
            antField('name'),
            antField('course_id', {withFalsy: true}),
            antField('city_id'),
            antField('education_course_id', {withFalsy: true}),
            antField('date_from', {notSetValue: true}),
            antField('date_to', {notSetValue: true}),
        ]}>
            <TrainingStreamsFormInputs
                switchData={switchData}
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default TrainingStreamsAddForm