import React, { Profiler, useMemo, useState } from 'react'
import NavbarLayout from '../../../components/layout/NavbarLayout/NavbarLayout'
import './QAndAPage.scss'
import SoloCollapse from '../../../components/ui/SoloCollapse/SoloCollapse';
import {
    ColumnsType,
} from 'antd/lib/table/interface';
import TableObjects from '../../../components/ui/TableObjects/TableObjects';
import { useTableObject } from '../../../utils/hooks/useTableObject';
import { addCollapseIcon, editCollapseIcon } from '../../../components/ui/SoloCollapse/components/icons';
import TableActionButtons from '../../../components/ui/TableObjects/components/TableActionButtons/TableActionButtons';
import { useMutation, useQuery } from 'react-query';
import { CourseWithSummaryObj, ObjDB, QAndAObj } from '../../../types/apiTypes';
import { api } from '../../../api/api';
import { getSelectedItems } from '../../../utils/parsers';
import { openNotification, tableLinkId } from '../../../utils/jsxUtils';
import { RouteComponentProps, useParams } from 'react-router';
import { errorNotification, invalidateQueries, optionsQueryGetAll } from '../../../utils/rest';
import { SwitchQAndAItems } from '../../../types/propsTypes';
import PriceEditForm from './components/QAndAEditForm/QAndAEditForm';
import QAndAAddForm from './components/QAndAAddForm/QAndAAddForm';
import { filterTableLink, sortTableLinkByName } from '../../../utils/table';
import FormDropDescription from '../../../components/table/TableDropDescription/TableDropDescription';
import TableCellPlaceholder from '../../../components/table/TableCellPlaceholder/TableCellPlaceholder';
import { queryKeysQAndA } from '../../../static/data/queryKeys';
import useScrollMount from '../../../utils/hooks/useScrollMount';
import FieldHelper from '../../../components/common/FieldHelper/FieldHelper';
import { qAndAHelpers } from './formData/qAndAFieldHepler';

interface QAndAPageProps extends RouteComponentProps { }

type TableItem = ObjDB<QAndAObj>

const QAndAPage: React.FC<QAndAPageProps> = () => {

    const [openedAddForm, setOpenedAddForm] = useState<boolean>(false)
    const [openedEditForm, setOpenedEditForm] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<TableItem | null>(null)
    const [tableHeadLoader, setTableHeadLoader] = useState<boolean>(false)

    const params = useParams<{ id: string }>()

    const {
        selectedRowKeys,
        searchText,
        setSelectedRowKeys,
        getColumnSearchProps,
    } = useTableObject({ initialSearchText: params.id })

    const queryCourse = useQuery<CourseWithSummaryObj[], Error, ObjDB<CourseWithSummaryObj>[]>(
        queryKeysQAndA[0],
        api.courses.getAll,
        { ...optionsQueryGetAll }
    )

    const switchData: SwitchQAndAItems = { courses: queryCourse.data || [] }

    const queryAll = useQuery<undefined, Error, TableItem[]>(
        queryKeysQAndA[1],
        api.qAndA.getAll,
        { ...optionsQueryGetAll }
    )

    const queryDelete = useMutation(
        'qAndA-delete',
        api.qAndA.delete,
        {
            onSuccess() {
                openNotification('success', 'Объект успешно удален')
                invalidateQueries(queryKeysQAndA[1])
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка при удалении объекта')
        }
    )

    const columns: ColumnsType<TableItem> = useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: {
                    compare: (a, b) => a.id - b.id,
                },
                ...getColumnSearchProps('id'),
                defaultFilteredValue: params.id === searchText ? [params.id] : undefined,
                width: 90,
            },
            {
                title: 'Курс',
                dataIndex: 'course_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.course_id, b.course_id, queryCourse.data || []) },
                ...getColumnSearchProps('course_id'),
                onFilter: (val, record) => filterTableLink(val, record.course_id, queryCourse.data || []),
                width: 150,
                render: (id) => tableLinkId(queryCourse.data || [], id, 'course'),
            },
            {
                title: 'Вопрос',
                dataIndex: 'question',
                ...getColumnSearchProps('question'),
                width: 150,
                render: () => <TableCellPlaceholder text="Раскройте запись" />
            },
            {
                title: 'Ответ',
                dataIndex: 'answer',
                ...getColumnSearchProps('answer'),
                width: 150,
                render: () => <TableCellPlaceholder text="Раскройте запись" />
            },
            {
                title: 'Действие',
                dataIndex: '',
                align: 'right',
                render: (value: TableItem) => (
                    <TableActionButtons
                        onEdit={() => handleEditItem(value)}
                        onDelete={() => queryDelete.mutate(value)}
                    />
                ),
                width: 110,
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumnSearchProps])

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const handleTableAdd = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setOpenedAddForm(true)
    }

    const handleEditItem = (value: TableItem) => {
        setEditItem(value)
        setOpenedEditForm(true)
        setOpenedAddForm(false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleTableDelete = async () => {
        try {
            setTableHeadLoader(true)
            const deleteItems = getSelectedItems<TableItem>(selectedRowKeys, queryAll.data || [])
            await api.qAndA.deleteMany(deleteItems)
            invalidateQueries(queryKeysQAndA[1])
            openNotification(
                'success',
                'Выбранные объекты успешно удалены'
            )
            setSelectedRowKeys([])
        } catch (err) {
            errorNotification(err, 'Ошибка при удалении объектов')
        }
        finally { setTableHeadLoader(false) }
    }
    const handleTableRefresh = () => invalidateQueries(queryKeysQAndA)
    useScrollMount()

    return (
        <NavbarLayout>
            <Profiler id="23123" onRender={() => { }}>
                <section className="uif-q-and-a-page">
                    <FieldHelper
                        content={qAndAHelpers.pageTitle}
                        text="Вопрос - ответ | Questions and answers"
                        isPageTitle
                    />

                    <div className="uif-q-and-a-page__collapses">
                        <SoloCollapse
                            header="Создать новый объект"
                            keyCollapse="add"
                            show={openedAddForm}
                            icon={addCollapseIcon}
                            setShow={setOpenedAddForm}
                        >
                            <QAndAAddForm
                                switchData={switchData}
                                setOpenedForm={setOpenedAddForm}
                            />
                        </SoloCollapse>
                        <SoloCollapse
                            header="Редактировать объект"
                            keyCollapse="edit"
                            show={openedEditForm}
                            icon={editCollapseIcon}
                            setShow={setOpenedEditForm}
                        >
                            <PriceEditForm
                                editItem={editItem}
                                switchData={switchData}
                                setOpenedForm={setOpenedEditForm}
                                setEditItem={setEditItem}
                            />
                        </SoloCollapse>
                    </div>

                    <TableObjects
                        selectedRowKeys={selectedRowKeys}
                        count={queryAll.data?.length || 0}
                        columns={columns}
                        dataSource={queryAll.data}
                        rowSelection={{
                            selectedRowKeys: selectedRowKeys,
                            onChange: onSelectChange
                        }}
                        expandable={{
                            expandedRowRender: (record: TableItem) => (
                                <FormDropDescription
                                    data={[
                                        { title: 'Вопрос', text: record.question },
                                        { title: 'Ответ', text: record.answer },
                                    ]}
                                />
                            ),
                        }}
                        onDelete={handleTableDelete}
                        onAdd={handleTableAdd}
                        onRefresh={handleTableRefresh}
                        rowKey="id"
                        isFetching={queryAll.isFetching}
                        headerLoader={tableHeadLoader}
                        scroll={{ x: 800 }}
                    />
                </section>
            </Profiler>
        </NavbarLayout >
    )
}

export default QAndAPage