import React from 'react'
import { Form, Input } from 'antd';
import { Select } from 'antd';
import { FormikErrors } from 'formik';
import { TrainingStreamsFormFields } from '../../../../../types/formFieldTypes';
import FormControls from '../../../../../components/form/FormControls/FormControls';
import { parseDateToDBTime, parseDBTimeToDate } from '../../../../../utils/parsers';
import ReactDatePicker from 'react-datepicker';
import { SwitchTrainingStreamsItems } from '../../../../../types/propsTypes';
import Memo from '../../../../../components/hoc/Memo';
import FieldHelper from '../../../../../components/common/FieldHelper/FieldHelper';
import { trainingStreamsHelpers } from '../../formData/trainingStreamsFieldHepler';
const { Option } = Select;

type TrainingStreamsFormInputsProps = {
    switchData: SwitchTrainingStreamsItems
    values: TrainingStreamsFormFields
    errors: FormikErrors<TrainingStreamsFormFields>
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<TrainingStreamsFormFields>> | Promise<void>
    submitForm: () => void
    clearForm?: () => void
    resetChanges?: () => void
}

const TrainingStreamsFormInputs: React.FC<TrainingStreamsFormInputsProps> = (props) => {
    const {
        switchData, values, errors,
        setFieldValue, clearForm,
        submitForm, resetChanges,
    } = props

    return (
        <>
            <Memo
                hdl={setFieldValue}
                err={errors.name}
            >
                <Form.Item
                    name="name"
                    label="Имя"
                    className="uif-ant-form-item"
                >
                    <Input onChange={e => setFieldValue('name', e.target.value)} />
                </Form.Item>
            </Memo>

            <Memo
                data={switchData.courses}
                hdl={setFieldValue}
                err={errors.course_id}
            >
                <Form.Item
                    name="course_id"
                    label="Курс"
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите курс"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('course_id', id)}
                    >
                        {
                            switchData.courses.map(c => (
                                <Option value={c.id} key={c.id}>
                                    {c.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo
                data={switchData.educationCourses}
                hdl={setFieldValue}
                err={errors.education_course_id}
            >
                <Form.Item
                    name="education_course_id"
                    label={<FieldHelper
                        content={trainingStreamsHelpers.educationCourse}
                        text="Курс обучения"
                    />}
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите курс обучения"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('education_course_id', id)}
                    >
                        {
                            switchData.educationCourses.map(c => (
                                <Option value={c.id} key={c.id}>
                                    {c.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo
                data={switchData.cities}
                hdl={setFieldValue}
                err={errors.city_id}
            >
                <Form.Item
                    name="city_id"
                    label="Город"
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите город"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('city_id', id)}
                    >
                        {
                            switchData.cities.map(c => (
                                <Option value={c.id} key={c.id}>
                                    {c.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo
                val={values.date_from}
                hdl={setFieldValue}
                err={errors.date_from}
            >
                <Form.Item
                    name="date_from"
                    label="Дата начала"
                    className="uif-ant-form-item"
                >
                    <ReactDatePicker
                        locale="ru"
                        placeholderText="Выберите дату начала"
                        selected={parseDBTimeToDate(values.date_from)}
                        onChange={date => setFieldValue('date_from', parseDateToDBTime(date))}
                        timeInputLabel="Время:"
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeInput
                        autoComplete="off"
                    />
                </Form.Item>
            </Memo>

            <Memo
                val={values.date_to}
                hdl={setFieldValue}
                err={errors.date_to}
            >
                <Form.Item
                    name="date_to"
                    label="Дата окончания"
                    className="uif-ant-form-item"
                >
                    <ReactDatePicker
                        locale="ru"
                        placeholderText="Выберите дату окончания"
                        selected={parseDBTimeToDate(values.date_to)}
                        onChange={date => setFieldValue('date_to', parseDateToDBTime(date))}
                        timeInputLabel="Время:"
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeInput
                        autoComplete="off"
                    />
                </Form.Item>
            </Memo>

            <FormControls
                submitForm={submitForm}
                clearForm={clearForm}
                resetChanges={resetChanges}
            />
        </>
    )
}

export default TrainingStreamsFormInputs