import { ColumnsType } from 'antd/lib/table'
import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { api } from '../../../api/api'
import PageTitle from '../../../components/common/PageTitle/PageTitle'
import NavbarLayout from '../../../components/layout/NavbarLayout/NavbarLayout'
import TableObjects from '../../../components/ui/TableObjects/TableObjects'
import { ObjDB, EducationCourseObj } from '../../../types/apiTypes'
import useScrollMount from '../../../utils/hooks/useScrollMount'
import { useTableObject } from '../../../utils/hooks/useTableObject'
import { optionsQueryGetAll } from '../../../utils/rest'
import './EducationCourseListPage.scss'

type EducationCourseListPageProps = {
    pageTitle: string
    pageDescription?: string
    Description?: JSX.Element
    linkPath: string
}

type TableItem = ObjDB<EducationCourseObj>

const EducationCourseListPage: React.FC<EducationCourseListPageProps> = (props) => {

    const {
        pageTitle,
        pageDescription,
        linkPath,
        Description,
    } = props

    const {
        selectedRowKeys,
        getColumnSearchProps,
    } = useTableObject()

    const queryStreams = useQuery<ObjDB<EducationCourseObj>[], Error, ObjDB<EducationCourseObj>[]>(
        'education-course-all',
        api.educationCourse.getAll,
        { ...optionsQueryGetAll }
    )

    const columns: ColumnsType<TableItem> = useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: { compare: (a, b) => a.id - b.id },
                ...getColumnSearchProps('id'),
                width: 100,
            },
            {
                title: 'Название учебного курса',
                dataIndex: 'name',
                sorter: { compare: (a, b) => a.name.localeCompare(b.name) },
                ...getColumnSearchProps('name'),
                render: (name, record) => <Link to={`${linkPath}/${record.id}`}>
                    {name}
                </Link>,
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumnSearchProps])
    useScrollMount()

    return (
        <NavbarLayout>
            <section className="uif-education-course-list-page">
                <PageTitle text={pageTitle} />

                {
                    pageDescription &&
                    <p className="uif-education-course-list-page__description">
                        {pageDescription}
                    </p>
                }

                {
                    Description &&
                    Description
                }

                <TableObjects
                    selectedRowKeys={selectedRowKeys}
                    count={queryStreams.data?.length || 0}
                    columns={columns}
                    dataSource={queryStreams.data}
                    rowKey="id"
                    isFetching={queryStreams.isFetching}
                    scroll={{ x: 440 }}
                />
            </section>
        </NavbarLayout >
    )
}

export default EducationCourseListPage