import { CourseReviewFormFields } from "../../../../types/formFieldTypes"

export const courseReviewsValidate = (values: CourseReviewFormFields) => {
    const errors = {} as { [key: string]: string }

    if (!values.course_id) errors.course_id = 'Выберите курс'
    if (!values.text) errors.text = 'Введите текст'
    if (!values.date) errors.date = 'Укажите дату'
    if (!values.first_name_review) errors.first_name_review = 'Введите имя'
    if (!values.last_name_review) errors.last_name_review = 'Введите фамилию'

    if (!values.avatar_file_id) errors.avatar_file_id = 'Загрузите файл'
    return errors
}

export const initialCourseReviewsFields: CourseReviewFormFields = {
    course_id: 0,
    date: '',
    text: '',
    first_name_review: '',
    last_name_review: '',
    avatar_file_id: 0,

    _avatarSrc: '',
}

export const clearCourseReviewsErrors = {
    course_id: '',
    date: '',
    text: '',
    first_name_review: '',
    last_name_review: '',
    avatar_file_id: '',

    _avatarSrc: '',
}
