import { CityFormFields } from "../../../../types/formFieldTypes"

export const cityValidate = (values: CityFormFields) => {
    const errors = {} as { [key: string]: string }
    if (!values.name) errors.name = 'Введите название'
    return errors
}

export const initialCityFields = {
    name: ''
}

export const clearCityErrors = {
    name: ''
}
