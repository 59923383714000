import React from 'react'
import { useFormStatus } from '../../../utils/hooks/useFormStatus'
import './FormStatus.scss'
import { Spin } from 'antd';
import { LoadingOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

type FormStatusProps = {
    formStatus: ReturnType<typeof useFormStatus>
}

const FormStatus: React.FC<FormStatusProps> = ({ formStatus }) => {

    if (formStatus.status === '') return null

    return (
        <div className="uif-status-form">
            <div className="uif-status-form__body">
                <div className="uif-status-form__icon-wrapper">
                    {
                        formStatus.status === 'fetching' &&
                            <Spin
                                indicator={
                                    <LoadingOutlined className="uif-status-form__icon"spin />
                                }
                            />
                    }
                    {
                        formStatus.status === 'success' &&
                            <CheckCircleTwoTone
                                twoToneColor="#52c41a"
                                className="uif-status-form__icon"
                            />
                    }
                    {
                        formStatus.status === 'error' &&
                            <CloseCircleTwoTone
                                twoToneColor="#eb2f96"
                                className="uif-status-form__icon"
                            />
                    }
                </div>
                <p className="uif-status-form__text">
                    {formStatus.message}
                </p>
            </div>
        </div>
    )
}

export default FormStatus
