import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { DirectionGroupsFormFields } from '../../../../../types/formFieldTypes';
import { clearDirectionGroupsErrors, directionGroupsValidate, initialDirectionGroupsFields } from '../../formData/DirectionGroupsFormData';
import { getAntField } from '../../../../../utils/form';
import DirectionGroupsFormInputs from '../DirectionGroupsFormInputs/DirectionGroupsFormInputs';
import { ObjDB, UploadObj } from '../../../../../types/apiTypes';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { queryKeysDirectionGroups } from '../../../../../static/data/queryKeys';

type DirectionGroupsAddFormProps = {}

const DirectionGroupsAddForm: React.FC<DirectionGroupsAddFormProps> = React.memo(() => {

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange, setFieldValue,
        submitForm, setValues, setErrors,
    } = useFormik<DirectionGroupsFormFields>({
        initialValues: initialDirectionGroupsFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: directionGroupsValidate
    })

    const antField = getAntField<DirectionGroupsFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.directionGroups.create,
        {
            mutationKey: 'direction-group-add',
            onSuccess() {
                invalidateQueries(queryKeysDirectionGroups[0])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.log('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 4000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const mutationAvatar = useMutation(
        api.file.add,
        {
            mutationKey: 'direction-group-avatar-add',
            onSuccess(data: ObjDB<UploadObj>) {
                setFieldValue('avatar_id', data.id)
                setFieldValue('_avatarSrc', data.path)
            },
            onError: (err: Error) => {
                errorNotification(err, 'Ошибка при загрузке файла, загрузите файл заново')
                setFieldValue('avatar_id', 0)
                setFieldValue('_avatarSrc', '')
            },
        }
    )

    const handleChangeImg = (file: File | null) => {
        if (file) mutationAvatar.mutate(file)
    }

    const clearForm = () => {
        setValues(initialDirectionGroupsFields)
        setErrors(clearDirectionGroupsErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[antField('name')]}>
            <DirectionGroupsFormInputs
                values={values}
                errors={errors}
                handleChange={handleChange}
                isFetchingImg={mutationAvatar.isLoading}
                onChangeImg={handleChangeImg}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default DirectionGroupsAddForm