import { DirectionFormFields } from "../../../../types/formFieldTypes"

export const directionValidate = (values: DirectionFormFields) => {
    const errors = {} as { [key: string]: string }
    if (!values.name) errors.name = 'Введите название'
    if (!values.group_directions) errors.group_directions = 'Выберите группу направлений'
    return errors
}

export const initialDirectionFields = {
    name: '',
    group_directions: 0,
}

export const clearDirectionErrors = {
    name: '',
    group_directions: '',
}
