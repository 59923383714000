import axios from "axios"
import { cityUrl, goalUrl, directionGroupUrl, typeOfTrainingUrl, citiesForCourseUrl, tagsForCourseUrl, coachesForCourseUrl, courseBGUrl, durationUrl, trainingProgramsUrl, priceUrl, factorUrl, skillUrl, qAndAUrl, levelUrl, courseReviewUrl, salaryUrl, fileUrl, tagUrl, directionUrl, trainingStreamUrl, coachUrl, courseUrl, discountUrl, siteSettingUrl, formTrainingUrl, educationCourseUrl } from "../config/urls"
import { CityObj, GoalObj, ObjDB, DirectionGroupObj, TrainingProgramsObj, SiteSettingObj, DurationObj, DiscountObj, LevelObj, TagObj, DirectionObj, TrainingStreamObj, CoachObj, SalaryObj, CourseReviewObj, PriceObj, QAndAObj, SkillObj, FactorObj, TypeOfTrainingObj, CourseBGObj, UploadObj, SummaryCititesForCourseObj, SummaryTagsForCourseObj, SummaryCoachesForCourseObj, CourseWithSummaryObj, FormTrainingObj, EducationCourseObj } from "../types/apiTypes"
import { CityFormFields, GoalFormFields, DirectionGroupsFormFields, DurationFormFields, LevelFormFields, TagFormFields, DirectionFormFields, TrainingStreamsFormFields, CoachFormFields, CourseFormFields, SalaryFormFields, CourseReviewFormFields, PriceFormFields, DiscountFormFields, QAndAFormFields, SkillFormFields, FactorFormFields, TrainingProgramsFormFields, TypeOfTrainingFormFields, CourseBGFormFields, FormTrainingFormFields, EducationCourseFormFields } from "../types/formFieldTypes"
import { CreateSettingData, EditSettingData } from "../types/propsTypes"
import { freshAccessToken } from "../utils/cookie"
import { authOptions, getLastSiteSettingItem, getLastSiteSettings, mixLocalOffsetTime, getDateByUTC } from "../utils/rest"

export const commonApi = {
    async createObject<T extends object>(path: string, apiFields: T) {
        const access = await freshAccessToken()
        const { data } = await axios.post(
            path,
            apiFields,
            authOptions(access)
        )
        return data
    },
    async editObject<T extends object>(path: string, objId: number, newFields: T) {
        const access = await freshAccessToken()
        const { data } = await axios.patch(
            `${path}/${objId}`,
            newFields,
            authOptions(access)
        )
        return data
    },
    async getAllObjects(path: string) {
        const access = await freshAccessToken()
        const { data } = await axios.get(path, authOptions(access))
        return data
    },
    async deleteObject(path: string, id: number) {
        const access = await freshAccessToken()
        const { data } = await axios.delete(
            `${path}/${id}`,
            authOptions(access)
            )
        return data
    },
    async deleteManyObject(path: string, items: {id: number}[]) {
        const res = await Promise.all(
            items.map(item => this.deleteObject(path, item.id))
        )
        return res
    },
    async sortMany<T extends {number: number}>(items: ObjDB<T>[], path: string) {
        return await Promise.all(
            items.map((item, idx) => {
                const { id, ...itemFields } = item
                itemFields.number = idx + 1
                return this.editObject(path, id, itemFields)
            }))
    },
}

export const summaryTableApi = {
    courses: {
        async create(
            course_id: number,
            cityIds: number[],
            tagIds: number[],
            coachIds: number[]
            ) {
            await Promise.all(
                cityIds.map(id => {
                    const body = {city_id: id, course_id}
                    return commonApi.createObject(citiesForCourseUrl, body)
                })
            )
            await Promise.all(
                tagIds.map(id => {
                    const body = {tag_id: id, course_id}
                    return commonApi.createObject(tagsForCourseUrl, body)
                })
            )
            await Promise.all(
                coachIds.map(id => {
                    const body = {coach_id: id, course_id}
                    return commonApi.createObject(coachesForCourseUrl, body)
                })
            )
        },
        async edit(newFields: CourseFormFields, course: ObjDB<CourseWithSummaryObj>) {
            const summary = course.summary
            const oldCityIds = summary.cities.map(s => s.city_id)
            const oldTagIds = summary.tags.map(s => s.tag_id)
            const oldCoachIds = summary.coaches.map(s => s.coach_id)

            const uncheckedCities = summary.cities.filter(s => !newFields.city_ids.includes(s.city_id))
            const uncheckedCoaches = summary.coaches.filter(s => !newFields.coach_ids.includes(s.coach_id))
            const uncheckedTags = summary.tags.filter(s => !newFields.tag_ids.includes(s.tag_id))

            const newCityIds = newFields.city_ids.filter(checkedId => !oldCityIds.includes(checkedId))
            const newTagIds = newFields.tag_ids.filter(checkedId => !oldTagIds.includes(checkedId))
            const newCoachIds = newFields.coach_ids.filter(checkedId => !oldCoachIds.includes(checkedId))

            await Promise.all(uncheckedCities.map(s => commonApi.deleteObject(citiesForCourseUrl, s.id)))
            await Promise.all(uncheckedCoaches.map(s => commonApi.deleteObject(coachesForCourseUrl, s.id)))
            await Promise.all(uncheckedTags.map(s => commonApi.deleteObject(tagsForCourseUrl, s.id)))
            await this.create(course.id, newCityIds, newTagIds, newCoachIds)
        },
        async deleteAll(courseItem: CourseWithSummaryObj) {
            const summaries = courseItem.summary
            await Promise.all(summaries.cities.map(s => commonApi.deleteObject(citiesForCourseUrl, s.id)))
            await Promise.all(summaries.coaches.map(s => commonApi.deleteObject(coachesForCourseUrl, s.id)))
            await Promise.all(summaries.tags.map(s => commonApi.deleteObject(tagsForCourseUrl, s.id)))
        },
        async mixSummary(courses: ObjDB<CourseWithSummaryObj>[]) {
            const allCitySummaries = await commonApi.getAllObjects(citiesForCourseUrl) as ObjDB<SummaryCititesForCourseObj>[]
            const allTagSummaries = await commonApi.getAllObjects(tagsForCourseUrl) as ObjDB<SummaryTagsForCourseObj>[]
            const allCoachSummaries = await commonApi.getAllObjects(coachesForCourseUrl) as ObjDB<SummaryCoachesForCourseObj>[]

            const coursesWithSummar = courses.map(course => {
                const cities = allCitySummaries.filter(s => s.course_id === course.id)
                const tags = allTagSummaries.filter(s => s.course_id === course.id)
                const coaches = allCoachSummaries.filter(s => s.course_id === course.id)
                return {
                    ...course,
                    summary: {
                        cities,
                        tags,
                        coaches,
                    }
                } as CourseWithSummaryObj
            })
            return coursesWithSummar
        },
        async getAll<RespData>(summaryUrl: string) {
            return await commonApi.getAllObjects(summaryUrl) as RespData[]
        }
    }
}

export const api = {
    file: {
        add: async(file: File) => {
            const formData = new FormData()
            formData.append('file', file)
            return await commonApi.createObject(fileUrl, formData)
        },
        delete: async(id: number) => {
            return await commonApi.deleteObject(fileUrl, id)
        },
        get: async(id: number) => await commonApi.getAllObjects(`${fileUrl}/${id}`)
    },
    cities: {
        create: async(values: CityFormFields) => {
            return await commonApi.createObject(cityUrl, values)
        },
        edit: async(newFields: CityFormFields, item: ObjDB<CityObj>) => {
            if (item.id === 0) throw new Error('Нельзя изменить курс с id=0')
            return await commonApi.editObject(cityUrl, item.id, newFields)
        },
        delete: async(item: ObjDB<CityObj>) => {
            if (item.id === 0) throw new Error('Нельзя удалить курс с id=0')
            return await commonApi.deleteObject(cityUrl, item.id)
        },
        deleteMany: async(items: ObjDB<CityObj>[]) => {
            const hasZeroId = items.some(c => c.id === 0)
            if (hasZeroId) throw new Error('Нельзя удалить курс с id=0')
            return await commonApi.deleteManyObject(cityUrl, items)
        },
        getAll: async() => await commonApi.getAllObjects(cityUrl)
    },
    educationCourse: {
        create: async(values: EducationCourseFormFields) => {
            return await commonApi.createObject(educationCourseUrl, values)
        },
        edit: async(newFields: EducationCourseFormFields, item: ObjDB<EducationCourseObj>) => {
            return await commonApi.editObject(educationCourseUrl, item.id, newFields)
        },
        delete: async(item: ObjDB<EducationCourseObj>) => {
            return await commonApi.deleteObject(educationCourseUrl, item.id)
        },
        deleteMany: async(items: ObjDB<EducationCourseObj>[]) => {
            return await commonApi.deleteManyObject(educationCourseUrl, items)
        },
        getAll: async() => await commonApi.getAllObjects(educationCourseUrl)
    },
    goals: {
        create: async(values: GoalFormFields) => {
            return await commonApi.createObject(goalUrl, values)
        },
        edit: async(newFields: GoalFormFields, item: ObjDB<GoalObj>) => {
            return await commonApi.editObject(goalUrl, item.id, newFields)
        },
        delete: async(item: ObjDB<GoalObj>) => {
            return await commonApi.deleteObject(goalUrl, item.id)
        },
        deleteMany: async(items: ObjDB<GoalObj>[]) => {
            return await commonApi.deleteManyObject(goalUrl, items)
        },
        getAll: async() => await commonApi.getAllObjects(goalUrl)
    },
    directionGroups: {
        create: async(values: DirectionGroupsFormFields) => {
            const { _avatarSrc, ...apiFields } = values
            return await commonApi.createObject(directionGroupUrl, apiFields)
        },
        edit: async(newFields: DirectionGroupsFormFields, item: ObjDB<DirectionGroupObj>) => {
            const { _avatarSrc, ...apiFields } = newFields
            return await commonApi.editObject(directionGroupUrl, item.id, apiFields)
        },
        delete: async(item: ObjDB<DirectionGroupObj>) => {
            return await commonApi.deleteObject(directionGroupUrl, item.id)
        },
        deleteMany: async(items: ObjDB<DirectionGroupObj>[]) => {
            return await commonApi.deleteManyObject(directionGroupUrl, items)
        },
        getAll: async() => await commonApi.getAllObjects(directionGroupUrl)
    },
    duration: {
        create: async(values: DurationFormFields) => {
            return await commonApi.createObject(durationUrl, values)
        },
        edit: async(newFields: DurationFormFields, item: ObjDB<DurationObj>) => {
            return await commonApi.editObject(durationUrl, item.id, newFields)
        },
        delete: async(item: ObjDB<DurationObj>) => {
            return await commonApi.deleteObject(durationUrl, item.id)
        },
        deleteMany: async(items: ObjDB<DurationObj>[]) => {
            return await commonApi.deleteManyObject(durationUrl, items)
        },
        getAll: async() => await commonApi.getAllObjects(durationUrl)
    },
    levels: {
        create: async(values: LevelFormFields) => {
            return await commonApi.createObject(levelUrl, values)
        },
        edit: async(newFields: LevelFormFields, item: ObjDB<LevelObj>) => {
            return await commonApi.editObject(levelUrl, item.id, newFields)
        },
        delete: async(item: ObjDB<LevelObj>) => {
            return await commonApi.deleteObject(levelUrl, item.id)
        },
        deleteMany: async(items: ObjDB<LevelObj>[]) => {
            return await commonApi.deleteManyObject(levelUrl, items)
        },
        getAll: async() => await commonApi.getAllObjects(levelUrl)
    },
    tags: {
        create: async(values: TagFormFields) => {
            return await commonApi.createObject(tagUrl, values)
        },
        edit: async(newFields: TagFormFields, item: ObjDB<TagObj>) => {
            return await commonApi.editObject(tagUrl, item.id, newFields)
        },
        delete: async(item: ObjDB<TagObj>) => {
            return await commonApi.deleteObject(tagUrl, item.id)
        },
        deleteMany: async(items: ObjDB<TagObj>[]) => {
            return await commonApi.deleteManyObject(tagUrl, items)
        },
        getAll: async() => await commonApi.getAllObjects(tagUrl)
    },
    directions: {
        create: async(values: DirectionFormFields) => {
            return await commonApi.createObject(directionUrl, values)
        },
        edit: async(newFields: DirectionFormFields, item: ObjDB<DirectionObj>) => {
            return await commonApi.editObject(directionUrl, item.id, newFields)
        },
        delete: async(item: ObjDB<DirectionObj>) => {
            return await commonApi.deleteObject(directionUrl, item.id)
        },
        deleteMany: async(items: ObjDB<DirectionObj>[]) => {
            return await commonApi.deleteManyObject(directionUrl, items)
        },
        getAll: async() => await commonApi.getAllObjects(directionUrl)
    },
    trainingStreams: {
        create: async(values: TrainingStreamsFormFields) => {
            return await commonApi.createObject(trainingStreamUrl, getDateByUTC(values, ['date_from', 'date_to']))
        },
        edit: async(newFields: TrainingStreamsFormFields, item: ObjDB<TrainingStreamObj>) => {
            return await commonApi.editObject(trainingStreamUrl, item.id, getDateByUTC(newFields, ['date_from', 'date_to']))
        },
        delete: async(item: ObjDB<TrainingStreamObj>) => {
            return await commonApi.deleteObject(trainingStreamUrl, item.id)
        },
        deleteMany: async(items: ObjDB<TrainingStreamObj>[]) => {
            return await commonApi.deleteManyObject(trainingStreamUrl, items)
        },
        getAll: async() => {
            const withZeroUTCDate = await commonApi.getAllObjects(trainingStreamUrl) as ObjDB<TrainingStreamObj>[]
            return mixLocalOffsetTime(withZeroUTCDate, ['date_from', 'date_to'])
        }
    },
    coaches: {
        create: async(values: CoachFormFields) => {
            const { _avatarSrc, ...apiData } = values
            return await commonApi.createObject(coachUrl, apiData)
        },
        edit: async(newFields: CoachFormFields, item: ObjDB<CoachObj>) => {
            const { _avatarSrc, ...apiData } = newFields
            return await commonApi.editObject(coachUrl, item.id, apiData)
        },
        delete: async(item: ObjDB<CoachObj>) => {
            return await commonApi.deleteObject(coachUrl, item.id)
        },
        deleteMany: async(items: ObjDB<CoachObj>[]) => {
            return await commonApi.deleteManyObject(coachUrl, items)
        },
        getAll: async() => await commonApi.getAllObjects(coachUrl)
    },
    courses: {
        create: async(values: CourseFormFields) => {
            console.log('course api', values);
            const {
                _previewCourseSrc,
                _documentPreviewSrc,
                city_ids,
                coach_ids,
                tag_ids,
                ...apiData
            } = values
            const data = await commonApi.createObject(courseUrl, apiData) as ObjDB<CourseWithSummaryObj>
            await summaryTableApi.courses.create(data.id, city_ids, tag_ids, coach_ids)
            return data
        },
        edit: async(newFields: CourseFormFields, item: ObjDB<CourseWithSummaryObj>) => {
            const { _previewCourseSrc, _documentPreviewSrc, ...apiData } = newFields
            await summaryTableApi.courses.edit(newFields, item)
            return await commonApi.editObject(courseUrl, item.id, apiData)
        },
        delete: async(item: ObjDB<CourseWithSummaryObj>) => {
            await summaryTableApi.courses.deleteAll(item)
            return await commonApi.deleteObject(courseUrl, item.id)
        },
        deleteMany: async(items: ObjDB<CourseWithSummaryObj>[]) => {
            await Promise.all(items.map(course => summaryTableApi.courses.deleteAll(course)))
            return await commonApi.deleteManyObject(courseUrl, items)
        },
        getAll: async() => {
            const rowData = await commonApi.getAllObjects(courseUrl)
            return summaryTableApi.courses.mixSummary(rowData)
        }
    },
    salaries: {
        create: async(values: SalaryFormFields) => {
            return await commonApi.createObject(salaryUrl, values)
        },
        edit: async(newFields: SalaryFormFields, item: ObjDB<SalaryObj>) => {
            return await commonApi.editObject(salaryUrl, item.id, newFields)
        },
        delete: async(item: ObjDB<SalaryObj>) => {
            return await commonApi.deleteObject(salaryUrl, item.id)
        },
        deleteMany: async(items: ObjDB<SalaryObj>[]) => {
            return await commonApi.deleteManyObject(salaryUrl, items)
        },
        getAll: async() => await commonApi.getAllObjects(salaryUrl)
    },
    courseReviews: {
        create: async(values: CourseReviewFormFields) => {
            return await commonApi.createObject(courseReviewUrl, getDateByUTC(values, ['date']))
        },
        edit: async(newFields: CourseReviewFormFields, item: ObjDB<CourseReviewObj>) => {
            return await commonApi.editObject(courseReviewUrl, item.id, getDateByUTC(newFields, ['date']))
        },
        delete: async(item: ObjDB<CourseReviewObj>) => {
            return await commonApi.deleteObject(courseReviewUrl, item.id)
        },
        deleteMany: async(items: ObjDB<CourseReviewObj>[]) => {
            return await commonApi.deleteManyObject(courseReviewUrl, items)
        },
        getAll: async() => {
            const withZeroUTCDate = await commonApi.getAllObjects(courseReviewUrl) as ObjDB<CourseReviewObj>[]
            return mixLocalOffsetTime(withZeroUTCDate, ['date'])
        }
    },
    prices: {
        create: async(values: PriceFormFields) => {
            return await commonApi.createObject(priceUrl, getDateByUTC(values, ['date_from', 'date_to']))
        },
        edit: async(newFields: PriceFormFields, item: ObjDB<PriceObj>) => {
            return await commonApi.editObject(priceUrl, item.id, getDateByUTC(newFields, ['date_from', 'date_to']))
        },
        delete: async(item: ObjDB<PriceObj>) => {
            return await commonApi.deleteObject(priceUrl, item.id)
        },
        deleteMany: async(items: ObjDB<PriceObj>[]) => {
            return await commonApi.deleteManyObject(priceUrl, items)
        },
        getAll: async() => {
            const withZeroUTCDate = await commonApi.getAllObjects(priceUrl) as ObjDB<PriceObj>[]
            return mixLocalOffsetTime(withZeroUTCDate, ['date_from', 'date_to'])
        }
    },
    discounts: {
        create: async(values: DiscountFormFields) => {
            return await commonApi.createObject(discountUrl, getDateByUTC(values, ['date_from', 'date_to']))
        },
        edit: async(newFields: DiscountFormFields, item: ObjDB<DiscountObj>) => {
            return await commonApi.editObject(discountUrl, item.id, getDateByUTC(newFields, ['date_from', 'date_to']))
        },
        delete: async(item: ObjDB<DiscountObj>) => {
            return await commonApi.deleteObject(discountUrl, item.id)
        },
        deleteMany: async(items: ObjDB<DiscountObj>[]) => {
            return await commonApi.deleteManyObject(discountUrl, items)
        },
        getAll: async() => {
            const withZeroUTCDate = await commonApi.getAllObjects(discountUrl) as ObjDB<DiscountObj>[]
            return mixLocalOffsetTime(withZeroUTCDate, ['date_from', 'date_to'])
        }
    },
    qAndA: {
        create: async(values: QAndAFormFields) => {
            return await commonApi.createObject(qAndAUrl, values)
        },
        edit: async(newFields: QAndAFormFields, item: ObjDB<QAndAObj>) => {
            return await commonApi.editObject(qAndAUrl, item.id, newFields)
        },
        delete: async(item: ObjDB<QAndAObj>) => {
            return await commonApi.deleteObject(qAndAUrl, item.id)
        },
        deleteMany: async(items: ObjDB<QAndAObj>[]) => {
            return await commonApi.deleteManyObject(qAndAUrl, items)
        },
        getAll: async() => await commonApi.getAllObjects(qAndAUrl)
    },
    skills: {
        create: async(values: SkillFormFields) => {
            return await commonApi.createObject(skillUrl, values)
        },
        edit: async(newFields: SkillFormFields, item: ObjDB<SkillObj>) => {
            return await commonApi.editObject(skillUrl, item.id, newFields)
        },
        delete: async(item: ObjDB<SkillObj>) => {
            return await commonApi.deleteObject(skillUrl, item.id)
        },
        deleteMany: async(items: ObjDB<SkillObj>[]) => {
            return await commonApi.deleteManyObject(skillUrl, items)
        },
        getAll: async() => await commonApi.getAllObjects(skillUrl)
    },
    factors: {
        create: async(values: FactorFormFields) => {
            return await commonApi.createObject(factorUrl, values)
        },
        edit: async(newFields: FactorFormFields, item: ObjDB<FactorObj>) => {
            return await commonApi.editObject(factorUrl, item.id, newFields)
        },
        delete: async(item: ObjDB<FactorObj>) => {
            return await commonApi.deleteObject(factorUrl, item.id)
        },
        deleteMany: async(items: ObjDB<FactorObj>[]) => {
            return await commonApi.deleteManyObject(factorUrl, items)
        },
        sortMany: async(items: ObjDB<FactorObj>[]) => {
            return await commonApi.sortMany(items, factorUrl)
        },
        getAll: async() => await commonApi.getAllObjects(factorUrl),
    },
    trainingPrograms: {
        create: async(values: TrainingProgramsFormFields) => {
            return await commonApi.createObject(trainingProgramsUrl, values)
        },
        edit: async(newFields: TrainingProgramsFormFields, item: ObjDB<TrainingProgramsObj>) => {
            return await commonApi.editObject(trainingProgramsUrl, item.id, newFields)
        },
        delete: async(item: ObjDB<TrainingProgramsObj>) => {
            return await commonApi.deleteObject(trainingProgramsUrl, item.id)
        },
        deleteMany: async(items: ObjDB<TrainingProgramsObj>[]) => {
            return await commonApi.deleteManyObject(trainingProgramsUrl, items)
        },
        sortMany: async(items: ObjDB<TrainingProgramsObj>[]) => {
            return await commonApi.sortMany(items, trainingProgramsUrl)
        },
        getAll: async() => await commonApi.getAllObjects(trainingProgramsUrl),
    },
    typeOfTrainings: {
        create: async(values: TypeOfTrainingFormFields) => {
            return await commonApi.createObject(typeOfTrainingUrl, values)
        },
        edit: async(newFields: TypeOfTrainingFormFields, item: ObjDB<TypeOfTrainingObj>) => {
            return await commonApi.editObject(typeOfTrainingUrl, item.id, newFields)
        },
        delete: async(item: ObjDB<TypeOfTrainingObj>) => {
            return await commonApi.deleteObject(typeOfTrainingUrl, item.id)
        },
        deleteMany: async(items: ObjDB<TypeOfTrainingObj>[]) => {
            return await commonApi.deleteManyObject(typeOfTrainingUrl, items)
        },
        getAll: async() => await commonApi.getAllObjects(typeOfTrainingUrl)
    },
    courseBG: {
        create: async(values: CourseBGFormFields) => {
            const { _imgSrc, ...apiData } = values
            return await commonApi.createObject(courseBGUrl, apiData)
        },
        edit: async(newFields: CourseBGFormFields, item: ObjDB<CourseBGObj>) => {
            const { _imgSrc, ...apiData } = newFields
            return await commonApi.editObject(courseBGUrl, item.id, apiData)
        },
        delete: async(item: ObjDB<CourseBGObj>) => {
            return await commonApi.deleteObject(courseBGUrl, item.id)
        },
        deleteMany: async(items: ObjDB<CourseBGObj>[]) => {
            return await commonApi.deleteManyObject(courseBGUrl, items)
        },
        get: async(id: number) => {
            const bgObj = await commonApi.getAllObjects(`${courseBGUrl}/${id}`) as ObjDB<CourseBGObj>
            const imgObj = await commonApi.getAllObjects(`${fileUrl}/${bgObj.background_file_id}`) as ObjDB<UploadObj>
            return {
                ...bgObj,
                img: {...imgObj}
            }
        },
        getAll: async() => {
            const backgrounds = await commonApi.getAllObjects(courseBGUrl) as ObjDB<CourseBGObj>[]
            const populatedBackgrounds = await Promise.all(
                backgrounds.map(bgObject => commonApi.getAllObjects(`${fileUrl}/${bgObject.background_file_id}`))
            ) as ObjDB<UploadObj>[]
            const result = backgrounds.map((bg, i) => ({
                ...bg,
                img: populatedBackgrounds[i]
            }))
            return result
        }
    },
    siteSettings: {
        async createMany(setData: CreateSettingData) {
            return await Promise.all(
                setData.set_keys.map(key => {
                    const body = { set_key: key, set_value: setData.fields[key] }
                    return commonApi.createObject(siteSettingUrl, body)
                })
            ) as ObjDB<SiteSettingObj>[]
        },
        async edit (data: EditSettingData) {
            return await Promise.all(
                data.edit_keys.map(set_key => {
                    const dbObj = getLastSiteSettingItem(set_key, data.settingItems)
                    if (!dbObj) return null
                    const set_value = data.fields[set_key]
                    const newFields = {
                        set_key,
                        set_value,
                    } as SiteSettingObj
                    if (set_key.startsWith('course_') && set_value === '') {
                        return commonApi.deleteObject(siteSettingUrl, dbObj.id)
                    }
                    return commonApi.editObject(siteSettingUrl, dbObj.id, newFields)
                })
            )
        },
        getAll: async() => {
            const rowData = await commonApi.getAllObjects(siteSettingUrl)
            return getLastSiteSettings(rowData)
        }
    },
    formTraining: {
        create: async(values: FormTrainingFormFields) => {
            return await commonApi.createObject(formTrainingUrl, values)
        },
        edit: async(newFields: FormTrainingFormFields, item: ObjDB<FormTrainingObj>) => {
            return await commonApi.editObject(formTrainingUrl, item.id, newFields)
        },
        delete: async(item: ObjDB<FormTrainingObj>) => {
            return await commonApi.deleteObject(formTrainingUrl, item.id)
        },
        deleteMany: async(items: ObjDB<FormTrainingObj>[]) => {
            return await commonApi.deleteManyObject(formTrainingUrl, items)
        },
        getAll: async() => await commonApi.getAllObjects(formTrainingUrl)
    },
}