import { PriceFormFields } from "../../../../types/formFieldTypes"
import { sortByDate } from "../../../../utils/form"

export const priceValidate = (values: PriceFormFields) => {
    const errors = {} as { [key: string]: string }

    if (!values.course_id) errors.course_id = 'Выберите курс'
    if (values.city_id === null) errors.city_id = 'Выберите город'
    if (!values.name) errors.name = 'Введите имя'
    if (!values.date_from) errors.date_from = 'Укажите дату начала'
    if (!values.date_to) errors.date_to = 'Укажите дату окончания'
    if (values.date_from && values.date_to) {
        if (sortByDate(values.date_from, values.date_to) >= 0) {
            errors.date_to = 'Дата окончания наступит раньше даты начала, введите корректные даты'
        }
    }
    return errors
}

export const initialPriceFields: PriceFormFields = {
    course_id: 0,
    city_id: null,
    name: '',
    price: 0,
    date_from: '',
    date_to: '',
}

export const clearPriceErrors = {
    course_id: '',
    city_id: '',
    name: '',
    price: '',
    date_from: '',
    date_to: '',
}
