import { EyeOutlined } from '@ant-design/icons'
import { Image } from 'antd'
import React from 'react'
import { baseUrl } from '../../../config/urls'
import { ObjDB, UploadObj } from '../../../types/apiTypes'
import './TableImage.scss'

type TableImageProps = {
    id?: number,
    imgContainer?: ObjDB<UploadObj>[]
    isLoading?: boolean
    uploadObj?: ObjDB<UploadObj>
    imgSrc?: string
    hideTextid?: boolean
    onMutate?: (id: number) => void
}

const TableImage: React.FC<TableImageProps> = (props) => {
    const {
        id, imgContainer, isLoading,
        uploadObj, imgSrc, hideTextid,
        onMutate,
    } = props

    const img = imgContainer?.find(image => image.id === id) || uploadObj
    const imgPath = imgSrc ? imgSrc : img?.path

    const handleClick = () => {
        if (onMutate && id) onMutate(id)
    }

    if (imgPath) return (
        <div className="uif-table-image">
            <Image
                src={`${baseUrl}${imgPath}`}
                wrapperClassName="uif-table-image__img"
            />
            {
                id && !hideTextid &&
                <p className="uif-table-image__text">{id}</p>
            }
        </div>
    )

    return (
        <button
            disabled={isLoading}
            className="table-action-button uif-btn uif-btn-white"
            onClick={handleClick}
        >
            {
                !hideTextid &&
                <p style={{ margin: 0, fontSize: 14 }}>
                    {id}
                </p>
            }
            <EyeOutlined />
        </button>
    )

}

export default TableImage