import { HelprerContentItem } from "../../../types/propsTypes";

export const siteSettingHelpers = {
    tel: [
        {
            type: 'title',
            text: 'Контактный телефон'
        },
        'Отображается в навигационной панели сайта',
        {
            type: 'image',
            imgSrc: '/static/img/helper/settings-tel.jpg',
        }
    ] as HelprerContentItem[],
    tag: [
        {
            type: 'title',
            text: 'Тег 3 блока'
        },
        'Отображается на главной странице в качестве третьего блока для группы "Цели"',
        {
            type: 'image',
            imgSrc: '/static/img/helper/setting-tag-position.jpg',
            description: 'Расположение на главной странице'
        },
        {
            type: 'image',
            imgSrc: '/static/img/helper/setting-tag-scale.jpg',
            description: 'Блок с тегом'
        }
    ] as HelprerContentItem[],
    offlineForm: [
        {
            type: 'title',
            text: 'Оффлайн форма'
        },
        'Данная форма обучения будет выбрана для блока "OFFLINE" на главной странице сайта. Т.е. список курсов в блоке "OFFLINE" будет отфильтрован по данной форме обучения',
        {
            type: 'image',
            imgSrc: '/static/img/helper/offline-form.jpg',
            description: 'OFFLINE-блок'
        }
    ] as HelprerContentItem[],
    onlineForm: [
        {
            type: 'title',
            text: 'Онлайн форма'
        },
        'Данная форма обучения будет выбрана для блока "ONLINE" на главной странице сайта. Т.е. список курсов в блоке "ONLINE" будет отфильтрован по данной форме обучения',
        {
            type: 'image',
            imgSrc: '/static/img/helper/online-form.jpg',
            description: 'ONLINE-блок'
        }
    ] as HelprerContentItem[]
}