import { CheckCircleTwoTone, CloseCircleTwoTone, InfoCircleTwoTone, WarningTwoTone } from '@ant-design/icons'
import { notification } from 'antd'
import { Link } from 'react-router-dom'
import { upwardObjects } from '../static/data/upwardObj'

type TableLinkIdData = {
    id: number
    name?: string
    first_name?: string
    last_name?: string
}

export const openNotification = (type: 'info' | 'success' | 'error', title: string, description?: string) => {
    let icon = <InfoCircleTwoTone />
    if (type === 'success') icon = <CheckCircleTwoTone twoToneColor="#52c41a" />
    if (type === 'error') icon = <CloseCircleTwoTone twoToneColor="#eb2f96"/>
    notification.open({
        message: title,
        description: description,
        placement: 'bottomRight',
        icon
    })
}

export const confirmIcon = () => <WarningTwoTone twoToneColor="#eb2f96"/>

export const tableLinkId = <Data extends TableLinkIdData[]>(
    data: Data,
    id: number,
    objName: keyof typeof upwardObjects
) => {
    if (!data.length) return
    const itemById = data.find(item => item.id === id)
    let linkText = ''

    if (itemById?.name) linkText = itemById?.name
    if (itemById?.first_name || itemById?.last_name) {
        linkText = `${itemById?.first_name || ''} ${itemById?.last_name || ''}`
    }

    return <Link to={`/object/${upwardObjects[objName].routePath}/${id}`}>
        {linkText || `id: ${id} (удалено)`}
    </Link>
}

export const replaceEntersToSpan = (value: string, className = 'uif-span-devider') => {
    return value.split('\n').map((word, i) => (
        <span key={i} className={className}>
            { word }
        </span>
    ))
}