import React, { useMemo, useState } from 'react'
import NavbarLayout from '../../../components/layout/NavbarLayout/NavbarLayout'
import './SkillPage.scss'
import SoloCollapse from '../../../components/ui/SoloCollapse/SoloCollapse';
import {
    ColumnsType,
} from 'antd/lib/table/interface';
import TableObjects from '../../../components/ui/TableObjects/TableObjects';
import { useTableObject } from '../../../utils/hooks/useTableObject';
import { addCollapseIcon, editCollapseIcon } from '../../../components/ui/SoloCollapse/components/icons';
import TableActionButtons from '../../../components/ui/TableObjects/components/TableActionButtons/TableActionButtons';
import { useMutation, useQuery } from 'react-query';
import { CourseWithSummaryObj, ObjDB, SkillObj } from '../../../types/apiTypes';
import { api } from '../../../api/api';
import { getSelectedItems } from '../../../utils/parsers';
import { openNotification, tableLinkId } from '../../../utils/jsxUtils';
import { RouteComponentProps, useParams } from 'react-router';
import { errorNotification, invalidateQueries, optionsQueryGetAll } from '../../../utils/rest';
import { SwitchSkillItems } from '../../../types/propsTypes';
import { filterTableLink, sortTableLinkByName } from '../../../utils/table';
import SkillAddForm from './components/SkillAddForm/SkillAddForm';
import SkillEditForm from './components/SkillEditForm/SkillEditForm';
import { queryKeysSkill } from '../../../static/data/queryKeys';
import useScrollMount from '../../../utils/hooks/useScrollMount';
import FieldHelper from '../../../components/common/FieldHelper/FieldHelper';
import { skillHelpers } from './formData/skillFieldHepler';

interface SkillPageProps extends RouteComponentProps { }

type TableItem = ObjDB<SkillObj>

const SkillPage: React.FC<SkillPageProps> = () => {

    const [openedAddForm, setOpenedAddForm] = useState<boolean>(false)
    const [openedEditForm, setOpenedEditForm] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<TableItem | null>(null)
    const [tableHeadLoader, setTableHeadLoader] = useState<boolean>(false)

    const params = useParams<{ id: string }>()

    const {
        selectedRowKeys,
        searchText,
        setSelectedRowKeys,
        getColumnSearchProps,
    } = useTableObject({ initialSearchText: params.id })

    const queryCourse = useQuery<CourseWithSummaryObj[], Error, ObjDB<CourseWithSummaryObj>[]>(
        queryKeysSkill[0],
        api.courses.getAll,
        { ...optionsQueryGetAll }
    )

    const switchData: SwitchSkillItems = { courses: queryCourse.data || [] }

    const queryAll = useQuery<undefined, Error, TableItem[]>(
        queryKeysSkill[1],
        api.skills.getAll,
        { ...optionsQueryGetAll }
    )

    const queryDelete = useMutation(
        'skill-delete',
        api.skills.delete,
        {
            onSuccess() {
                openNotification('success', 'Объект успешно удален')
                invalidateQueries(queryKeysSkill[1])
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка при удалении объекта')
        }
    )

    const columns: ColumnsType<TableItem> = useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: {
                    compare: (a, b) => a.id - b.id,
                },
                ...getColumnSearchProps('id'),
                defaultFilteredValue: params.id === searchText ? [params.id] : undefined,
            },
            {
                title: 'Курс',
                dataIndex: 'course_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.course_id, b.course_id, queryCourse.data || []) },
                ...getColumnSearchProps('course_id'),
                onFilter: (val, record) => filterTableLink(val, record.course_id, queryCourse.data || []),
                render: (id) => tableLinkId(queryCourse.data || [], id, 'course'),
            },
            {
                title: 'Название',
                dataIndex: 'name',
                sorter: { compare: (a, b) => a.name.localeCompare(b.name) },
                ...getColumnSearchProps('name'),
            },
            {
                title: 'Действие',
                dataIndex: '',
                align: 'right',
                render: (value: TableItem) => (
                    <TableActionButtons
                        onEdit={() => handleEditItem(value)}
                        onDelete={() => queryDelete.mutate(value)}
                    />
                )
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumnSearchProps])

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const handleTableAdd = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setOpenedAddForm(true)
    }

    const handleEditItem = (value: TableItem) => {
        setEditItem(value)
        setOpenedEditForm(true)
        setOpenedAddForm(false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleTableDelete = async () => {
        try {
            setTableHeadLoader(true)
            const deleteItems = getSelectedItems<TableItem>(selectedRowKeys, queryAll.data || [])
            await api.skills.deleteMany(deleteItems)
            invalidateQueries(queryKeysSkill[1])
            openNotification(
                'success',
                'Выбранные объекты успешно удалены'
            )
            setSelectedRowKeys([])
        } catch (err) {
            errorNotification(err, 'Ошибка при удалении объектов')
        }
        finally { setTableHeadLoader(false) }
    }
    const handleTableRefresh = () => invalidateQueries(queryKeysSkill)
    useScrollMount()

    return (
        <NavbarLayout>
            <section className="uif-skill-page">
                <FieldHelper
                    content={skillHelpers.pageTitle}
                    text="Навыки | Skills"
                    isPageTitle
                />
                <div className="uif-skill-page__collapses">
                    <SoloCollapse
                        header="Создать новый объект"
                        keyCollapse="add"
                        show={openedAddForm}
                        icon={addCollapseIcon}
                        setShow={setOpenedAddForm}
                    >
                        <SkillAddForm
                            switchData={switchData}
                            setOpenedForm={setOpenedAddForm}
                        />
                    </SoloCollapse>
                    <SoloCollapse
                        header="Редактировать объект"
                        keyCollapse="edit"
                        show={openedEditForm}
                        icon={editCollapseIcon}
                        setShow={setOpenedEditForm}
                    >
                        <SkillEditForm
                            editItem={editItem}
                            switchData={switchData}
                            setOpenedForm={setOpenedEditForm}
                            setEditItem={setEditItem}
                        />
                    </SoloCollapse>
                </div>

                <TableObjects
                    selectedRowKeys={selectedRowKeys}
                    count={queryAll.data?.length || 0}
                    columns={columns}
                    dataSource={queryAll.data}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: onSelectChange
                    }}
                    onDelete={handleTableDelete}
                    onAdd={handleTableAdd}
                    onRefresh={handleTableRefresh}
                    rowKey="id"
                    isFetching={queryAll.isFetching}
                    headerLoader={tableHeadLoader}
                    scroll={{ x: 500 }}
                />
            </section>
        </NavbarLayout >
    )
}

export default SkillPage