import { useState } from "react"

type StatusStates = 'success' | 'error' | 'fetching' | ''

export const useFormStatus = () => {
    const [status, setStatus] = useState<StatusStates>('')
    const [message, setMessage] = useState<string>('')

    const reset = () => {
        setStatus('')
        setMessage('')
    }

    return {
        status,
        message,
        setStatus,
        setMessage,
        reset,
    }
}