import React from 'react'
import { Form, Input } from 'antd';
import FormControls from '../../../../../components/form/FormControls/FormControls';

type TypeOfTrainingFormInputsProps = {
    handleChange: React.ChangeEventHandler
    submitForm: () => void
    clearForm?: () => void
    resetChanges?: () => void
}

const TypeOfTrainingFormInputs: React.FC<TypeOfTrainingFormInputsProps> = (props) => {
    const {
        handleChange, clearForm,
        submitForm, resetChanges,
    } = props

    return (
        <>
            <Form.Item
                name="name"
                label="Название"
                className="uif-ant-form-item"
            >
                <Input onChange={handleChange} />
            </Form.Item>

            <FormControls
                submitForm={submitForm}
                clearForm={clearForm}
                resetChanges={resetChanges}
            />
        </>
    )
}

export default TypeOfTrainingFormInputs