import { HelprerContentItem } from "../../../../types/propsTypes";

export const goalHelpers = {
    pageTitle: [
        {
            type: 'title',
            text: 'Цель'
        },
        'Отображается на главной странице. Также выступает в качестве фильтра для каталога курсов',
        {
            type: 'image',
            imgSrc: '/static/img/helper/page-title/goal.jpg',
        }
    ] as HelprerContentItem[]
}