import React, { useMemo, useState } from 'react'
import NavbarLayout from '../../../components/layout/NavbarLayout/NavbarLayout'
import './DirectionPage.scss'
import SoloCollapse from '../../../components/ui/SoloCollapse/SoloCollapse';
import PageTitle from '../../../components/common/PageTitle/PageTitle';
import {
    ColumnsType,
} from 'antd/lib/table/interface';
import TableObjects from '../../../components/ui/TableObjects/TableObjects';
import { useTableObject } from '../../../utils/hooks/useTableObject';
import { addCollapseIcon, editCollapseIcon } from '../../../components/ui/SoloCollapse/components/icons';
import TableActionButtons from '../../../components/ui/TableObjects/components/TableActionButtons/TableActionButtons';
import { useMutation, useQuery } from 'react-query';
import { DirectionGroupObj, DirectionObj, ObjDB } from '../../../types/apiTypes';
import { api } from '../../../api/api';
import { getSelectedItems } from '../../../utils/parsers';
import { openNotification, tableLinkId } from '../../../utils/jsxUtils';
import { RouteComponentProps, useParams } from 'react-router';
import DirectionAddForm from './components/DirectionAddForm/DirectionAddForm';
import DirectionEditForm from './components/DirectionEditForm/DirectionEditForm';
import { filterTableLink, sortTableLinkByName } from '../../../utils/table';
import { invalidateQueries, optionsQueryGetAll, queryOptionsPreset } from '../../../utils/rest';
import { queryKeysDirection } from '../../../static/data/queryKeys';
import useScrollMount from '../../../utils/hooks/useScrollMount';

interface DirectionPageProps extends RouteComponentProps {}

type TableItem = ObjDB<DirectionObj>

const DirectionPage: React.FC<DirectionPageProps> = () => {

    const [openedAddForm, setOpenedAddForm] = useState<boolean>(false)
    const [openedEditForm, setOpenedEditForm] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<TableItem | null>(null)
    const [tableHeadLoader, setTableHeadLoader] = useState<boolean>(false)

    const params = useParams<{ id: string }>()

    const {
        selectedRowKeys,
        searchText,
        setSelectedRowKeys,
        getColumnSearchProps,
    } = useTableObject({initialSearchText: params.id})

    const queryDirectionGroups = useQuery<undefined, Error, ObjDB<DirectionGroupObj>[]>(
        queryKeysDirection[0],
        api.directionGroups.getAll,
        { ...optionsQueryGetAll }
    )

    const queryAll = useQuery<undefined, Error, TableItem[]>(
        queryKeysDirection[1],
        api.directions.getAll,
        {
            onError(err: Error) {
                console.log('ERROR get all objects', err)
                openNotification(
                    'error',
                    'Ошибка получения списка объектов',
                    err.message
                )
            },
            ...queryOptionsPreset,
        }
    )

    const queryDelete = useMutation(
        'direction-delete',
        api.directions.delete,
        {
            onSuccess() {
                openNotification('success', 'Объект успешно удален')
                invalidateQueries(queryKeysDirection[1])
            },
            onError(err: Error) {
                console.error("Error delete object: ", err.message)
                openNotification('error', 'Ошибка при удалении объекта', err.message)
            },
        }
    )

    const columns: ColumnsType<TableItem> = useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: {
                    compare: (a: TableItem, b: TableItem) => a.id - b.id,
                },
                ...getColumnSearchProps('id'),
                defaultFilteredValue: params.id === searchText ? [params.id] : undefined,
            },
            {
                title: 'Название',
                dataIndex: 'name',
                sorter: {
                    compare: (a: TableItem, b: TableItem) => a.name.localeCompare(b.name),
                },
                ...getColumnSearchProps('name'),
            },
            {
                title: 'Группа направлений',
                dataIndex: 'group_directions',
                sorter: { compare: (a, b) => sortTableLinkByName(a.group_directions, b.group_directions, queryDirectionGroups.data || []) },
                ...getColumnSearchProps('group_directions'),
                onFilter: (val, record) => filterTableLink(val, record.group_directions, queryDirectionGroups.data || []),
                render: (id: number) => tableLinkId(queryDirectionGroups.data || [], id, 'directionsGroups'),
            },
            {
                title: 'Действие',
                dataIndex: '',
                align: 'right',
                render: (value: TableItem) => (
                    <TableActionButtons
                        onEdit={() => handleEditItem(value)}
                        onDelete={() => queryDelete.mutate(value)}
                    />
                )
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumnSearchProps])

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const handleTableAdd = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setOpenedAddForm(true)
    }

    const handleEditItem = (value: TableItem) => {
        setEditItem(value)
        setOpenedEditForm(true)
        setOpenedAddForm(false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleTableDelete = async () => {
        try {
            setTableHeadLoader(true)
            const deleteItems = getSelectedItems<TableItem>(selectedRowKeys, queryAll.data || [])
            await api.directions.deleteMany(deleteItems)
            invalidateQueries(queryKeysDirection[1])
            openNotification(
                'success',
                'Выбранные объекты успешно удалены'
            )
            setSelectedRowKeys([])
        } catch (err) {
            console.error('Error delete many', err)
            openNotification(
                'error',
                'Ошибка при удалении объектов',
                err.message
            )
        } finally { setTableHeadLoader(false) }
    }
    const handleTableRefresh = () => invalidateQueries(queryKeysDirection)
    useScrollMount()

    return (
        <NavbarLayout>
            <section className="uif-direction-page">
                <PageTitle text="Направления | Directions" />

                <div className="uif-direction-page__collapses">
                    <SoloCollapse
                        header="Создать новый объект"
                        keyCollapse="add"
                        show={openedAddForm}
                        icon={addCollapseIcon}
                        setShow={setOpenedAddForm}
                    >
                        <DirectionAddForm
                            directionGroups={queryDirectionGroups.data || []}
                            setOpenedForm={setOpenedAddForm}
                        />
                    </SoloCollapse>
                    <SoloCollapse
                        header="Редактировать объект"
                        keyCollapse="edit"
                        show={openedEditForm}
                        icon={editCollapseIcon}
                        setShow={setOpenedEditForm}

                    >
                        <DirectionEditForm
                            editItem={editItem}
                            directionGroups={queryDirectionGroups.data || []}
                            setOpenedForm={setOpenedEditForm}
                            setEditItem={setEditItem}
                        />
                    </SoloCollapse>
                </div>

                <TableObjects
                    selectedRowKeys={selectedRowKeys}
                    count={queryAll.data?.length || 0}
                    columns={columns}
                    dataSource={queryAll.data}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: onSelectChange
                    }}
                    onDelete={handleTableDelete}
                    onAdd={handleTableAdd}
                    onRefresh={handleTableRefresh}
                    rowKey="id"
                    isFetching={queryAll.isFetching}
                    headerLoader={tableHeadLoader}
                    scroll={{x: 600}}
                />
            </section>
        </NavbarLayout >
    )
}

export default DirectionPage