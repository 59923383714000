import React, { useMemo, useState } from 'react'
import NavbarLayout from '../../../components/layout/NavbarLayout/NavbarLayout'
import './CourseReviewPage.scss'
import SoloCollapse from '../../../components/ui/SoloCollapse/SoloCollapse';
import {
    ColumnsType,
} from 'antd/lib/table/interface';
import TableObjects from '../../../components/ui/TableObjects/TableObjects';
import { useTableObject } from '../../../utils/hooks/useTableObject';
import { addCollapseIcon, editCollapseIcon } from '../../../components/ui/SoloCollapse/components/icons';
import TableActionButtons from '../../../components/ui/TableObjects/components/TableActionButtons/TableActionButtons';
import { useMutation, useQuery } from 'react-query';
import { CourseWithSummaryObj, CourseReviewObj, UploadObj, ObjDB } from '../../../types/apiTypes';
import { api } from '../../../api/api';
import { getSelectedItems } from '../../../utils/parsers';
import { openNotification, tableLinkId } from '../../../utils/jsxUtils';
import { RouteComponentProps, useParams } from 'react-router';
import { errorNotification, invalidateQueries, optionsQueryGetAll } from '../../../utils/rest';
import { sortByDate } from '../../../utils/form';
import TrainingStreamsAddForm from './components/CourseReviewAddForm/CourseReviewAddForm';
import TableImage from '../../../components/table/TableImage/TableImage';
import { SwitchCourseReviewItems } from '../../../types/propsTypes';
import CourseReviewEditForm from './components/CourseReviewEditForm/CourseReviewEditForm';
import ParsedDate from '../../../components/table/ParsedDate/ParsedDate';
import { filterTableLink, sortTableLinkByName } from '../../../utils/table';
import { queryKeysCourseReview } from '../../../static/data/queryKeys';
import useScrollMount from '../../../utils/hooks/useScrollMount';
import FieldHelper from '../../../components/common/FieldHelper/FieldHelper';
import { courseReviewHelpers } from './formData/courseReviewFieldHepler';

interface CourseReviewPageProps extends RouteComponentProps {}

type TableItem = ObjDB<CourseReviewObj>

const CourseReviewPage: React.FC<CourseReviewPageProps> = () => {

    const [openedAddForm, setOpenedAddForm] = useState<boolean>(false)
    const [openedEditForm, setOpenedEditForm] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<TableItem | null>(null)
    const [tableHeadLoader, setTableHeadLoader] = useState<boolean>(false)
    const [images, setImages] = useState<ObjDB<UploadObj>[]>([])

    const params = useParams<{ id: string }>()

    const {
        selectedRowKeys,
        searchText,
        setSelectedRowKeys,
        getColumnSearchProps,
    } = useTableObject({initialSearchText: params.id})

    const queryCourse = useQuery<CourseWithSummaryObj[], Error, ObjDB<CourseWithSummaryObj>[]>(
        queryKeysCourseReview[0],
        api.courses.getAll,
        { ...optionsQueryGetAll }
    )

    const switchData: SwitchCourseReviewItems = { courses: queryCourse.data || [] }

    const queryAll = useQuery<TableItem[], Error, TableItem[]>(
        queryKeysCourseReview[1],
        api.courseReviews.getAll,
        { ...optionsQueryGetAll }
    )

    const queryDelete = useMutation(
        'course-review-delete',
        api.courseReviews.delete,
        {
            onSuccess() {
                openNotification('success', 'Объект успешно удален')
                invalidateQueries(queryKeysCourseReview[1])
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка при удалении объекта')
        }
    )

    const imgMutate = useMutation(
        'img-mutate',
        api.file.get,
        {
            onSuccess(data: ObjDB<UploadObj>) {
                setImages([...images, data])
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка при загрузке файла'),
        }
    )

    const columns: ColumnsType<TableItem> = useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: {
                    compare: (a, b) => a.id - b.id,
                },
                ...getColumnSearchProps('id'),
                defaultFilteredValue: params.id === searchText ? [params.id] : undefined,
            },
            {
                title: 'Курс',
                dataIndex: 'course_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.course_id, b.course_id, queryCourse.data || []) },
                ...getColumnSearchProps('course_id'),
                onFilter: (val, record) => filterTableLink(val, record.course_id, queryCourse.data || []),
                render: (id) => tableLinkId(queryCourse.data || [], id, 'course'),
            },
            {
                title: 'Дата',
                dataIndex: 'date',
                sorter: { compare: (a, b) => sortByDate(a.date, b.date) },
                render: (val: string) => <ParsedDate timeDB={val}/>
            },
            {
                title: 'Имя',
                dataIndex: 'first_name_review',
                sorter: {compare: (a, b) => a.first_name_review.localeCompare(b.first_name_review)},
                ...getColumnSearchProps('first_name_review'),
            },
            {
                title: 'Фамилия',
                dataIndex: 'last_name_review',
                sorter: {compare: (a, b) => a.last_name_review.localeCompare(b.last_name_review)},
                ...getColumnSearchProps('last_name_review'),
            },
            {
                title: 'ID Фото',
                dataIndex: 'avatar_file_id',
                sorter: { compare: (a, b) => a.avatar_file_id - b.avatar_file_id },
                ...getColumnSearchProps('avatar_file_id'),
                render: (id: number) => (
                    <TableImage
                        id={id}
                        imgContainer={images}
                        isLoading={imgMutate.isLoading}
                        onMutate={id => imgMutate.mutate(id)}
                    />
                ),
                width: 150,
            },
            {
                title: 'Действие',
                dataIndex: '',
                align: 'right',
                render: (value: TableItem) => (
                    <TableActionButtons
                        onEdit={() => handleEditItem(value)}
                        onDelete={() => queryDelete.mutate(value)}
                    />
                )
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumnSearchProps])

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const handleTableAdd = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setOpenedAddForm(true)
    }

    const handleEditItem = (value: TableItem) => {
        setEditItem(value)
        setOpenedEditForm(true)
        setOpenedAddForm(false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleTableDelete = async () => {
        try {
            setTableHeadLoader(true)
            const deleteItems = getSelectedItems<TableItem>(selectedRowKeys, queryAll.data || [])
            await api.courseReviews.deleteMany(deleteItems)
            invalidateQueries(queryKeysCourseReview[1])
            openNotification(
                'success',
                'Выбранные объекты успешно удалены'
            )
            setSelectedRowKeys([])
        } catch (err) {
            errorNotification(err, 'Ошибка при удалении объектов')
        }
        finally { setTableHeadLoader(false) }
    }
    const handleTableRefresh = () => invalidateQueries(queryKeysCourseReview)
    useScrollMount()

    return (
        <NavbarLayout>
            <section className="uif-course-review-page">
                <FieldHelper
                    content={courseReviewHelpers.pageTitle}
                    text="Отзывы о курсе | Course Reviews"
                    isPageTitle
                />

                <div className="uif-course-review-page__collapses">
                    <SoloCollapse
                        header="Создать новый объект"
                        keyCollapse="add"
                        show={openedAddForm}
                        icon={addCollapseIcon}
                        setShow={setOpenedAddForm}
                    >
                        <TrainingStreamsAddForm
                            switchData={switchData}
                            setOpenedForm={setOpenedAddForm}
                        />
                    </SoloCollapse>
                    <SoloCollapse
                        header="Редактировать объект"
                        keyCollapse="edit"
                        show={openedEditForm}
                        icon={editCollapseIcon}
                        setShow={setOpenedEditForm}
                    >
                        <CourseReviewEditForm
                            editItem={editItem}
                            switchData={switchData}
                            setOpenedForm={setOpenedEditForm}
                            setEditItem={setEditItem}
                        />
                    </SoloCollapse>
                </div>

                <TableObjects
                    selectedRowKeys={selectedRowKeys}
                    count={queryAll.data?.length || 0}
                    columns={columns}
                    dataSource={queryAll.data}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: onSelectChange
                    }}
                    expandable={{
                        expandedRowRender: (record: TableItem) => <p style={{ margin: 0 }}>{record.text}</p>,
                        rowExpandable: record => record.text
                    }}
                    onDelete={handleTableDelete}
                    onAdd={handleTableAdd}
                    onRefresh={handleTableRefresh}
                    rowKey="id"
                    isFetching={queryAll.isFetching}
                    headerLoader={tableHeadLoader}
                    scroll={{x: 1000}}
                />
            </section>
        </NavbarLayout >
    )
}

export default CourseReviewPage