import { Button } from 'antd'
import React from 'react'

type FormControlsProps = {
    submitForm?: () => void
    clearForm?: () => void
    resetChanges?: () => void
}

const FormControls: React.FC<FormControlsProps> = (props) => {
    const {
        submitForm, clearForm, resetChanges
    } = props


    return (
        <div className="uif-ant-form-controls">
            {
                clearForm &&
                <Button
                    type='default'
                    htmlType="button"
                    onClick={clearForm}
                >
                    Очистить поля
                </Button>
            }
            {
                resetChanges &&
                <Button
                    type='default'
                    htmlType="button"
                    onClick={resetChanges}
                >
                    Отменить изменения
                </Button>
            }
            {
                submitForm &&
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={submitForm}
                >
                    Отправить
                </Button>
            }

        </div>
    )
}

export default FormControls