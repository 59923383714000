import { observer } from 'mobx-react-lite'
import React from 'react'
import NavbarLayout from '../../components/layout/NavbarLayout/NavbarLayout'
import { userStore } from '../../store/user'
import useScrollMount from '../../utils/hooks/useScrollMount'
import './AccountPage.scss'

type AccountPageProps = {}

type TableItem = {
    name: string
    value: string | number
}

const AccountPage: React.FC<AccountPageProps> = observer(() => {
    let tableItems: TableItem[] = []
    const user = userStore.user

    if (user) {
        tableItems = [
            {
                name: 'Имя',
                value: user.name
            },
            {
                name: 'Роли',
                value: user.roles.join('; ')
            },
            {
                name: 'Email',
                value: user.email
            },
            {
                name: 'Email подтвержден',
                value: user.email_verified ? 'Да' : 'Нет'
            },
        ]
    }

    useScrollMount()

    return (
        <NavbarLayout>
            <div className="uif-account-page">
                <p className="uif-account-page__info-title">Данные аккаунта</p>
                <div className="uif-account-page__table">
                    <div className="uif-account-page__table-item">
                        <p className="uif-account-page__table-item-title">Основная информация</p>
                        {
                            tableItems.map((it, i) => (
                                <div className="uif-account-page__table-item-row" key={i}>
                                    <p className="uif-account-page__table-item-param">
                                        {it.name}
                                    </p>
                                    <p className="uif-account-page__table-item-value">
                                        {
                                            it.value
                                        }
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </NavbarLayout>
    )
})

export default AccountPage