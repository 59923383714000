import { CourseFormFields } from "../../../../types/formFieldTypes"
import { openNotification } from "../../../../utils/jsxUtils"

export const courseValidate = (values: CourseFormFields) => {
    const errors = {} as { [key: string]: string }
    if (!values.name) errors.name = 'Введите имя'
    if (!values.direction_id) errors.direction_id = 'Выберите направление'
    if (!values.type_of_training_id) errors.type_of_training_id = 'Выберите тип обучения'
    if (!values.background_id) errors.background_id = 'Выберите фон'
    if (!values.description) errors.description = 'Введите описание'
    if (!values.document_name) errors.document_name = 'Введите имя документа'
    if (!values.document_description) errors.document_description = 'Введите описание документа'
    if (!values.goal_id) errors.goal_id = 'Выберите цель'
    if (!values.duration_id) errors.duration_id = 'Выберите длительность'
    if (!values.level_id) errors.level_id = 'Выберите уровень'
    if (!values.form_training_id) errors.form_training_id = 'Выберите форму обучения'
    if (!values.position) errors.position = 'Введите должность'
    // if (!values.amount_of_time) errors.amount_of_time = 'Введите количество недель'
    if (!values.code) errors.code = 'Введите код номенклатуры'

    if (!values.document_preview_id) errors.document_preview_id = 'Загрузите превью документа'
    if (!values.preview_course_id) errors.preview_course_id = 'Загрузите превью курса'

    if (values.is_installment && !values.installment_months) {
        errors.installment_months = 'Укажите количество месяцев'
    }

    // summary-table fields
    if (!values.city_ids.length) errors.city_ids = 'Выберите города'
    if (values.city_ids.includes(0) && values.city_ids.length > 1) errors.city_ids = 'Если выбрано "Все города", то нельзя указывать какие-либо другие города'
    // if (!values.tag_ids.length) errors.tag_ids = 'Выберите теги'
    if (!values.coach_ids.length) errors.coach_ids = 'Выберите преподавателей'

    if (Object.keys(errors).length) openNotification('error', 'Форма заполнена неверно')
    return errors
}

export const initialCourseFields: CourseFormFields = {
    name: '',
    direction_id: 0,
    type_of_training_id: 0,
    description: '',
    preview_course_id: 0,
    document_name: '',
    document_description: '',
    document_preview_id: 0,
    background_id: 0,
    goal_id: 0,
    duration_id: 0,
    level_id: 0,
    hours: 0,
    months: 0,
    max_seats: null,
    form_training_id: 0,
    position: '',
    amount_of_time: '',
    code: 0,
    is_hide: false,
    is_internship: false,
    is_installment: false,
    installment_months: 0,
    training_result: `По окончании обучения ты получишь все необходимые теоретические и практические навыки для работы по запросу клиента и диплом о профессиональной переподготовке, который позволит работать тебе в сфере фитнеса.`,

    _documentPreviewSrc: '',
    _previewCourseSrc: '',

    city_ids: [],
    tag_ids: [],
    coach_ids: [],
}

export const clearCourseErrors = {
    name: '',
    direction_id: '',
    type_of_training_id: '',
    description: '',
    preview_course_id: '',
    background_id: '',
    document_name: '',
    document_description: '',
    document_preview_id: '',
    city_id: '',
    goal_id: '',
    duration_id: '',
    level_id: '',
    hours: '',
    months: '',
    max_seats: '',
    is_hide: '',
    form_training_id: '',
    position: '',
    amount_of_time: '',
    code: '',
    is_internship: '',
    is_installment: '',
    installment_months: '',

    _documentPreviewSrc: '',
    _previewCourseSrc: '',
}
