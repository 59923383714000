import { HelprerContentItem } from "../../../../types/propsTypes";

export const typeOfTrainingHelpers = {
    pageTitle: [
        {
            type: 'title',
            text: 'Типы обучения'
        },
        'Отображается на главной странице в блоке "ONLINE"',
        {
            type: 'image',
            imgSrc: '/static/img/helper/page-title/type-of-training.jpg',
        },
    ] as HelprerContentItem[]
}