import { queryClient } from "../components/hoc/ReactQuery"
import { staleQueriesTime } from "../config/vars"
import { ObjDB, SiteSettingObj } from "../types/apiTypes"
import { SiteSettingFormFields } from "../types/formFieldTypes"
import { useFormStatus } from "./hooks/useFormStatus"
import { openNotification } from "./jsxUtils"
import { dbTimeToZeroUTC, dbTimeWithLocalTimeOffset } from "./parsers"

export const errorNotification = (err: Error, title: string, formStatus?: ReturnType<typeof useFormStatus>) => {
    console.error(err)
    if (formStatus) {
        formStatus.setMessage(`${title}: ${err.message}`)
        formStatus.setStatus('error')
        setTimeout(formStatus.reset, 3000)
    } else {
        openNotification(
            'error',
            title,
            err.message
        )
    }
}

export const queryOptionsPreset = {
    staleTime: staleQueriesTime,
    retry: 1,
    refetchOnWindowFocus: false,
}

export const optionsQueryGetAll = {
    onError(err: Error) {
        console.log('ERROR get all objects', err)
        openNotification(
            'error',
            'Ошибка получения списка зависимых объектов',
            err.message
        )
    },
    ...queryOptionsPreset
}

export const previewQueryOptions = (formStatus: ReturnType<typeof useFormStatus>) => {
    return ({
        onSuccess() {
            formStatus.setMessage('')
            formStatus.setStatus('')
        },
        onError: (err: Error) => errorNotification(err, 'Ошибка загрузки файла', formStatus),
        onMutate() {
            formStatus.setMessage('Загрузка файла')
            formStatus.setStatus('fetching')
        }
    })
}

export const authOptions = (accessToken: string | null | undefined) => ({
    headers: {'Authorization': `Bearer ${accessToken}`}
})

export const getLastSiteSettingItem = (setKey: keyof SiteSettingFormFields, items: ObjDB<SiteSettingObj>[]) => {
    let lastIndexObj = -1
    items.forEach((it, idx) => {
        if (it.set_key === setKey) lastIndexObj = idx
    })
    if (lastIndexObj === -1 || !items[lastIndexObj]) return null
    return items[lastIndexObj]
}

export const getLastSiteSettings = (items: ObjDB<SiteSettingObj>[]) => {
    const lastSettings = {} as { [key: string]: {value: string | number, id: number} }
    items.forEach(it => lastSettings[it.set_key] = { value: it.set_value, id: it.id })
    const result = Object.keys(lastSettings).map(key => {
        return {
            set_key: key,
            id: lastSettings[key].id,
            set_value: lastSettings[key].value
        }
    }) as ObjDB<SiteSettingObj>[]
    return result
}

export const getDateByUTC = <Values extends {}>(rowValues: Values, dateFields: (keyof Values)[]) => {
    const result = {...rowValues}
    dateFields.forEach(field => {
        if (typeof result[field] === 'string') {
            result[field] = dbTimeToZeroUTC(result[field] + '') as any
        }
    })
    return result
}

export const mixLocalOffsetTime = <Item extends {}>(items: Item[], dateFields: (keyof Item)[]): Item[] => {
    return items.map(item => {
        dateFields.forEach(dateField => item[dateField] = dbTimeWithLocalTimeOffset(item[dateField] + '') as any)
        return item
    })
}

export const invalidateQueries = (values: string | readonly string[]) => {
    if (typeof values === 'string') {
        queryClient.invalidateQueries(values)
    } else {
        values.forEach(val => queryClient.invalidateQueries(val))
    }
}