import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { TagFormFields } from '../../../../../types/formFieldTypes';
import { initialTagFields, tagValidate , clearTagErrors} from '../../formData/TagFormData';
import { getAntField } from '../../../../../utils/form';
import TagFormInputs from '../TagFormInputs/TagFormInputs';
import { invalidateQueries } from '../../../../../utils/rest';
import { queryKeysTag } from '../../../../../static/data/queryKeys';


type TagAddFormProps = {
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
}

const TagAddForm: React.FC<TagAddFormProps> = React.memo(() => {

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<TagFormFields>({
        initialValues: initialTagFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: tagValidate
    })

    const antField = getAntField<TagFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.tags.create,
        {
            mutationKey: 'tag-add',
            onSuccess() {
                invalidateQueries(queryKeysTag[0])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.log('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 4000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialTagFields)
        setErrors(clearTagErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[antField('name')]}>
            <TagFormInputs
                handleChange={handleChange}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus}/>
        </Form>
    )
})

export default TagAddForm