import { CoachFormFields } from "../../../../types/formFieldTypes"

export const coachValidate = (values: CoachFormFields) => {
    const errors = {} as { [key: string]: string }
    if (!values.first_name) errors.first_name = 'Введите имя'
    if (!values.last_name) errors.last_name = 'Введите фамилию'
    if (!values.quality.trim()) errors.quality = 'Укажите уровень'
    if (!values.avatar_id) errors.avatar_id = 'Загрузите фотографию'
    if (!values.education) errors.education = 'Опишите образование'
    if (!values.experience) errors.experience = 'Опишите опыт работы'
    return errors
}

export const initialCoachFields: CoachFormFields = {
    first_name: '',
    last_name: '',
    quality: '',
    avatar_id: 0,
    education: '',
    experience: '',

    _avatarSrc: '',
}

export const clearCoachErrors = {
    first_name: '',
    last_name: '',
    quality: '',
    avatar_id: '',
    education: '',
    experience: '',

    _avatarSrc: '',
}
