import { HelprerContentItem } from "../../../../types/propsTypes";

export const courseHelpers = {
    document: [
        {
            type: 'title',
            text: 'Документ'
        },
        'Отобажается на странице курса в блоке "Итоги обучения"',
        {
            type: 'image',
            imgSrc: '/static/img/helper/course-document.jpg',
        }
    ] as HelprerContentItem[],
    documentPreview: [
        {
            type: 'title',
            text: 'Превью документа'
        },
        'Отобажается на странице курса в блоке "Итоги обучения". Для лучшего отображения на сайте, изображение желательно загружать в вертикальном формате',
        {
            type: 'image',
            imgSrc: '/static/img/helper/course-document.jpg',
        }
    ] as HelprerContentItem[],
    coursePreview: [
        {
            type: 'title',
            text: 'Превью курса'
        },
        'Отобажается на странице курса',
        {
            type: 'image',
            imgSrc: '/static/img/helper/course-preview.jpg',
        }
    ] as HelprerContentItem[],
    position: [
        {
            type: 'title',
            text: 'Должность'
        },
        'Отобажается на странице курса в блоке "Итоги обучения"',
        {
            type: 'image',
            imgSrc: '/static/img/helper/course-position.jpg',
        }
    ] as HelprerContentItem[],
    amountOfTime: [
        {
            type: 'title',
            text: 'Количество недель'
        },
        'Отобажается на странице курса в первом блоке. Например "4 недели", "1 неделя"',
        'Если поле пустое - количество недель не будет отображено на сайте',
        {
            type: 'image',
            imgSrc: '/static/img/helper/course-amount.jpg',
        }
    ] as HelprerContentItem[],
    maxSeats: [
        {
            type: 'title',
            text: 'Количество мест'
        },
        'Отобажается в форме обратной связи на странице курса',
        'Если поле пустое - количество мест не будет отображаться на сайте',
        {
            type: 'image',
            imgSrc: '/static/img/helper/course-max-seats.jpg',
        }
    ] as HelprerContentItem[],
    practice: [
        {
            type: 'title',
            text: 'Блок практики'
        },
        'Отобажается на странице курса',
        {
            type: 'image',
            imgSrc: '/static/img/helper/course-practice.jpg',
        }
    ] as HelprerContentItem[],
    trainingResultDescription: [
        {
            type: 'title',
            text: 'Описание блока "Итоги обучения"'
        },
        'Отобажается на странице курса',
        {
            type: 'image',
            imgSrc: '/static/img/helper/training-results.jpg',
        }
    ] as HelprerContentItem[],
}