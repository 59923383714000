import axios from "axios"
import { accessTokenCookieName, refreshTokenCookieName } from "../config/vars"
import { refreshUrl } from "../config/urls"
import { AuthResponseData } from "../types/apiTypes"

export const freshAccessToken = async () => {
    try {
        const accessFromCookie = getCookie(accessTokenCookieName)
        const refreshFromCookie = getCookie(refreshTokenCookieName)

        if (accessFromCookie) return accessFromCookie

        if (refreshFromCookie){
            const refreshBody = { refresh_token: refreshFromCookie }
            const res = await axios.post(refreshUrl, refreshBody)
            const data = res.data as AuthResponseData
            if (data) {
                writeUserToCookie(data)
                return data.access_token
            }
            deleteCookie(refreshFromCookie)
        }
        document.location.reload()
    } catch(e) {
        console.log('get fresh access token error', e)
        deleteCookie(accessTokenCookieName)
        deleteCookie(refreshTokenCookieName)
        document.location.reload()
    }
}

export function getCookie(name: string): string | null {
    let result: string | null = null
    const cookie = document.cookie
    const cookieProp = cookie.split('; ')
    cookieProp.forEach(cookiePair => {
        const [key, value] = cookiePair.split('=')
        if (key === name) {
            result = value
        }
    })
    return result
}

export function writeUserToCookie(authData: AuthResponseData) {
    setCookie(accessTokenCookieName, authData.access_token, authData.expires_in - 30)
    setCookie(refreshTokenCookieName, authData.refresh_token, authData.refresh_expires_in - 30)
}

export function setCookie(name: string, value: string, maxAge: number = 28800): void {
    document.cookie = `${name}=${value}; max-age=${maxAge}; path=/`
}

export function deleteCookie(name: string): void {
    document.cookie = `${name}=; path=/; expires=0; max-age=0`
}