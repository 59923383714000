import React from 'react'
import { parseDateDB } from '../../../utils/parsers'

type ParsedDateProps = {
    timeDB: string
}

const ParsedDate: React.FC<ParsedDateProps> = ({ timeDB }) => {
    return (
        <p style={{ textAlign: 'center' }}>
            {parseDateDB(timeDB)[0]}
            <br />
            {parseDateDB(timeDB)[1]}
        </p>
    )
}

export default ParsedDate