import React from 'react'
import { Form } from 'antd';
import { Select } from 'antd';
import { FormikErrors } from 'formik';
import { QAndAFormFields } from '../../../../../types/formFieldTypes';
import FormControls from '../../../../../components/form/FormControls/FormControls';
import { SwitchQAndAItems } from '../../../../../types/propsTypes';
import Memo from '../../../../../components/hoc/Memo';
import TextArea from 'antd/lib/input/TextArea';
const { Option } = Select;

type QAndAFormInputsProps = {
    switchData: SwitchQAndAItems
    values: QAndAFormFields
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<QAndAFormFields>> | Promise<void>
    handleChange: React.ChangeEventHandler
    submitForm: () => void
    clearForm?: () => void
    resetChanges?: () => void
}

const QAndAFormInputs: React.FC<QAndAFormInputsProps> = (props) => {
    const {
        switchData, values,
        handleChange,
        setFieldValue, clearForm,
        submitForm, resetChanges,
    } = props

    return (
        <>
            <Memo
                data={switchData.courses}
                val={values.course_id}
                func={setFieldValue}
            >
                <Form.Item
                    name="course_id"
                    label="Курс"
                    className="uif-ant-form-item"
                >
                    <Select
                        showSearch
                        placeholder="Выберите курс"
                        optionFilterProp="children"
                        onChange={id => setFieldValue('course_id', id)}
                    >
                        {
                            switchData.courses.map(c => (
                                <Option value={c.id} key={c.id}>
                                    {c.name}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Memo>

            <Memo hdl={handleChange}>
                <Form.Item
                    name="question"
                    label="Текст вопроса"
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={e => setFieldValue('question', e.target.value)} />
                </Form.Item>
            </Memo>

            <Memo hdl={handleChange}>
                <Form.Item
                    name="answer"
                    label="Текст ответа"
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={e => setFieldValue('answer', e.target.value)} />
                </Form.Item>
            </Memo>

            <FormControls
                submitForm={submitForm}
                clearForm={clearForm}
                resetChanges={resetChanges}
            />
        </>
    )
}

export default QAndAFormInputs