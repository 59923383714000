import { QAndAFormFields } from "../../../../types/formFieldTypes"

export const qAndAValidate = (values: QAndAFormFields) => {
    const errors = {} as { [key: string]: string }

    if (!values.course_id) errors.course_id = 'Выберите курс'
    if (!values.question) errors.question = 'Введите вопрос'
    if (!values.answer) errors.answer = 'Введите ответ'
    return errors
}

export const initialQAndAFields: QAndAFormFields = {
    course_id: 0,
    question: '',
    answer: ''
}

export const clearQAndAErrors = {
    course_id: '',
    question: '',
    answer: ''
}
