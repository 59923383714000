import React from 'react'
import { Form } from 'antd';
import Memo from '../../../../../components/hoc/Memo';
import TextArea from 'antd/lib/input/TextArea';
import FormControls from '../../../../../components/form/FormControls/FormControls';

type GoalFormInputsProps = {
    handleChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
    clearForm?: () => void
    resetChanges?: () => void
    submitForm: () => void
}

export const GoalFormInputs: React.FC<GoalFormInputsProps> = (props) => {
    const {
        handleChange, clearForm, submitForm,
        resetChanges,
    } = props

    return (
        <>
            <Memo hdl={handleChange}>
                <Form.Item
                    name="name"
                    label="Название цели"
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={e => handleChange(e)} />
                </Form.Item>
            </Memo>

            <Memo hdl={handleChange}>
                <Form.Item
                    name="description"
                    label="Описание"
                    className="uif-ant-form-item"
                >
                    <TextArea rows={4} onChange={e => handleChange(e)} />
                </Form.Item>
            </Memo>

            <FormControls
                submitForm={submitForm}
                resetChanges={resetChanges}
                clearForm={clearForm}
            />
        </>
    )
}

export default GoalFormInputs