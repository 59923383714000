import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { ObjDB, FactorObj } from '../../../../../types/apiTypes';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import EditPlaceholder from '../../../../../components/common/EditPlaceholder/EditPlaceholder';
import { getAntField } from '../../../../../utils/form';
import { FactorFormFields } from '../../../../../types/formFieldTypes';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { SwitchFactorItems } from '../../../../../types/propsTypes';
import { clearFactorErrors, factorValidate, initialFactorFields } from '../../formData/FactorFormData';
import FactorFormInputs from '../FactorFormInputs/FactorFormInputs';
import { queryKeysFactor } from '../../../../../static/data/queryKeys';

type FactorEditFormProps = {
    editItem: ObjDB<FactorObj> | null
    switchData: SwitchFactorItems
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
    setEditItem: React.Dispatch<React.SetStateAction<ObjDB<FactorObj> | null>>
}

const FactorEditForm: React.FC<FactorEditFormProps> = React.memo((props) => {
    const {
        editItem, setOpenedForm, setEditItem,
        switchData,
    } = props

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        setFieldValue, handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<FactorFormFields>({
        initialValues: initialFactorFields(editItem?.course_id || 0),
        onSubmit: (values) => {
            mutate(values)
        },
        validateOnChange: false,
        validate: factorValidate
    })

    const antField = getAntField<FactorFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        async (values: FactorFormFields) => {
            if (editItem) return await api.factors.edit(values, editItem)
        },
        {
            mutationKey: 'factor-edit',
            onSuccess() {
                invalidateQueries(queryKeysFactor[1])
                formStatus.setMessage('Данные успешно изменены')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка редактирования', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialFactorFields(0))
        setErrors(clearFactorErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setOpenedForm(false)
        setTimeout(() => {
            setEditItem(null)
            formStatus.reset()
        }, 300)
    }

    const resetChanges = () => {
        if (editItem) {
            const { id, ...values } = editItem
            setValues(values)
            setErrors(clearFactorErrors)
        }
    }

    useEffect(() => {
        if (editItem) return resetChanges()
        clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    if (!editItem) return <EditPlaceholder />

    return (
        <Form
            fields={[
                antField('course_id', {withFalsy: true}),
                antField('name'),
                antField('is_hide'),
            ]}
        >
            {
                editItem &&
                <p className="uif-ant-form-title">
                    ID редактируемого объекта: <span>{editItem.id}</span>
                </p>
            }

            <FactorFormInputs
                switchData={switchData}
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                resetChanges={resetChanges}
                submitForm={submitForm}
            />

            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default FactorEditForm