import React, { useMemo, useState } from 'react'
import NavbarLayout from '../../../components/layout/NavbarLayout/NavbarLayout'
import './CityPage.scss'
import SoloCollapse from '../../../components/ui/SoloCollapse/SoloCollapse';
import PageTitle from '../../../components/common/PageTitle/PageTitle';
import {
    ColumnsType,
} from 'antd/lib/table/interface';
import TableObjects from '../../../components/ui/TableObjects/TableObjects';
import { useTableObject } from '../../../utils/hooks/useTableObject';
import { addCollapseIcon, editCollapseIcon } from '../../../components/ui/SoloCollapse/components/icons';
import TableActionButtons from '../../../components/ui/TableObjects/components/TableActionButtons/TableActionButtons';
import CityAddForm from './components/CityAddForm/CityAddForm';
import { useMutation, useQuery } from 'react-query';
import CityEditForm from './components/CityEditForm/CityEditForm';
import { CityObj, ObjDB } from '../../../types/apiTypes';
import { api } from '../../../api/api';
import { getSelectedItems } from '../../../utils/parsers';
import { openNotification } from '../../../utils/jsxUtils';
import { RouteComponentProps, useParams } from 'react-router';
import { invalidateQueries, queryOptionsPreset } from '../../../utils/rest';
import { queryKeysCity } from '../../../static/data/queryKeys';
import useScrollMount from '../../../utils/hooks/useScrollMount';


type TableItem = ObjDB<CityObj>

interface CityPageProps extends RouteComponentProps { }

const CityPage: React.FC<CityPageProps> = () => {

    const [openedAddForm, setOpenedAddForm] = useState<boolean>(false)
    const [openedEditForm, setOpenedEditForm] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<TableItem | null>(null)
    const [tableHeadLoader, setTableHeadLoader] = useState<boolean>(false)

    const params = useParams<{ id: string }>()

    const {
        selectedRowKeys,
        searchText,
        setSelectedRowKeys,
        getColumnSearchProps,
    } = useTableObject({ initialSearchText: params.id })

    const queryAll = useQuery<undefined, Error, TableItem[]>(
        queryKeysCity[0],
        api.cities.getAll,
        {
            onError(err: Error) {
                console.log('ERROR get all objects', err)
                openNotification(
                    'error',
                    'Ошибка получения списка объектов',
                    err.message
                )
            },
            ...queryOptionsPreset,
        }
    )

    const queryDelete = useMutation(
        'city-delete',
        api.cities.delete,
        {
            onSuccess() {
                openNotification('success', 'Объект успешно удален')
                invalidateQueries('city-all')
            },
            onError(err: Error) {
                console.error("Error delete object: ", err.message)
                openNotification('error', 'Ошибка при удалении объекта', err.message)
            },
        }
    )

    const columns: ColumnsType<TableItem> = useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: {
                    compare: (a: TableItem, b: TableItem) => a.id - b.id,
                },
                ...getColumnSearchProps('id'),
                defaultFilteredValue: params.id === searchText ? [params.id] : undefined,
            },
            {
                title: 'Название',
                dataIndex: 'name',
                sorter: {
                    compare: (a: TableItem, b: TableItem) => a.name.localeCompare(b.name),
                },
                ...getColumnSearchProps('name'),
            },
            {
                title: 'Действие',
                dataIndex: '',
                align: 'right',
                render: (value: TableItem) => {
                    if (value.id !== 0) return (
                        <TableActionButtons
                            onEdit={() => handleEditItem(value)}
                            onDelete={() => queryDelete.mutate(value)}
                        />
                    )
                    return null
                }
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumnSearchProps])

    const onSelectChange = (selectedKeys: React.Key[]) => {
        const withoutZeroId = selectedKeys.filter(k => k !== 0)
        const selectedZero = selectedKeys.includes(0)
        if (selectedZero && selectedRowKeys.length === selectedKeys.length - 1) {
            return setSelectedRowKeys([])
        }
        setSelectedRowKeys(withoutZeroId)
    }

    const handleTableAdd = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setOpenedAddForm(true)
    }

    const handleEditItem = (value: TableItem) => {
        setEditItem(value)
        setOpenedEditForm(true)
        setOpenedAddForm(false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    const handleTableDelete = async () => {
        try {
            setTableHeadLoader(true)
            const deleteItems = getSelectedItems<TableItem>(selectedRowKeys, queryAll.data || [])
            await api.cities.deleteMany(deleteItems)
            invalidateQueries(queryKeysCity[0])
            openNotification(
                'success',
                'Выбранные объекты успешно удалены'
            )
            setSelectedRowKeys([])
        } catch (err) {
            console.error('Error delete many', err)
            openNotification(
                'error',
                'Ошибка при удалении объектов',
                err.message
            )
        } finally { setTableHeadLoader(false) }
    }
    const handleTableRefresh = () => invalidateQueries(queryKeysCity)

    useScrollMount()

    return (
        <NavbarLayout>
            <section className="uif-city-page">
                <PageTitle text="Города | Cities" />

                <div className="uif-city-page__collapses">
                    <SoloCollapse
                        header="Создать новый объект"
                        keyCollapse="add"
                        show={openedAddForm}
                        icon={addCollapseIcon}
                        setShow={setOpenedAddForm}
                    >
                        <CityAddForm />
                    </SoloCollapse>
                    <SoloCollapse
                        header="Редактировать объект"
                        keyCollapse="edit"
                        show={openedEditForm}
                        icon={editCollapseIcon}
                        setShow={setOpenedEditForm}

                    >
                        <CityEditForm
                            editItem={editItem}
                            setOpenedForm={setOpenedEditForm}
                            setEditItem={setEditItem}
                        />
                    </SoloCollapse>
                </div>

                <TableObjects
                    selectedRowKeys={selectedRowKeys}
                    count={queryAll.data?.length || 0}
                    columns={columns}
                    dataSource={queryAll.data}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: onSelectChange
                    }}
                    onDelete={handleTableDelete}
                    onAdd={handleTableAdd}
                    onRefresh={handleTableRefresh}
                    rowKey="id"
                    isFetching={queryAll.isFetching}
                    headerLoader={tableHeadLoader}
                    scroll={{ x: 500 }}
                />
            </section>
        </NavbarLayout >
    )
}

export default CityPage