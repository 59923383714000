import React, { useState } from 'react'
import { Image, Spin } from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import './ImageUpload.scss'
import { openNotification } from '../../../utils/jsxUtils';
import { baseUrl } from '../../../config/urls';

type ImageUploadProps = {
    error?: string
    uploadText?: string
    onlyImg?: boolean
    defaultImgSrc?: string
    name?: string
    isFetching?: boolean
    onChange: (file: File | null) => void
}

const ImageUpload: React.FC<ImageUploadProps> = React.memo((props) => {
    const {
        error, uploadText,
        onlyImg, defaultImgSrc, name,
        isFetching,
        onChange,
    } = props

    const [imageUrl] = useState<string>('')

    const isLoaded = imageUrl || defaultImgSrc
    const path = imageUrl
        ? imageUrl
        : `${baseUrl}${defaultImgSrc}`

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null
        if (file) {
            if (!validateChange(file)) return null
            onChange(file)
        }
    };

    function validateChange(file: File) {
        if (onlyImg) {
            const isJpgOrPng = file.type === 'image/jpeg'
                || file.type === 'image/png'
                || file.type === 'image/jpg'
                || file.type === 'image/svg+xml'
            if (!isJpgOrPng) {
                openNotification(
                    'error',
                    'Разрешены только .jpg .svg или .png файлы!',
                )
            }
            return isJpgOrPng;
        }
        return true
    }

    const wrapClasses = () => {
        let classes = ['uif-upload-img']
        if (isLoaded || isFetching) classes.push('active')
        return classes.join(' ')
    }

    return (
        <>
            <div className={wrapClasses()}>
                <div className="uif-upload-img__body">
                    <label className="uif-upload-img__label">
                        <div className="uif-upload-img__load-area">
                            {
                                isFetching &&
                                <div className="uif-upload-img__loader">
                                    <Spin indicator={<LoadingOutlined spin />} />
                                </div>
                            }
                            {
                                defaultImgSrc && !isFetching &&
                                <Image
                                    width={'100%'}
                                    src={path}
                                    title="Увеличить"
                                    onClick={e => { e.stopPropagation(); e.preventDefault() }}
                                />
                            }
                        </div>
                        <input
                            type="file"
                            className="uif-upload-img__input"
                            id="5567"
                            value=""
                            name={name}
                            onChange={handleChange}
                        />
                        <p className="uif-upload-img__upload-btn">
                            <UploadOutlined />
                            <span>{uploadText || 'Загрузить файл'}</span>
                        </p>

                    </label>
                    <p className="uif-upload-img__error">
                        {error}
                    </p>
                </div>
            </div>
        </>
    )
})

export default ImageUpload