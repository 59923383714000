import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { FormTrainingFormFields } from '../../../../../types/formFieldTypes';
import { getAntField } from '../../../../../utils/form';
import { formTrainingValidate, clearFormTrainingErrors, initialFormTrainingFields } from '../../formData/FormTrainingFormData';
import FormTrainingFormInputs from '../FormTrainingFormInputs/FormTrainingFormInputs';
import { queryKeysFormTraining } from '../../../../../static/data/queryKeys';
import { invalidateQueries } from '../../../../../utils/rest';

type FormTrainingAddFormProps = {}

const FormTrainingAddForm: React.FC<FormTrainingAddFormProps> = React.memo(() => {

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<FormTrainingFormFields>({
        initialValues: initialFormTrainingFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: formTrainingValidate
    })

    const antField = getAntField<FormTrainingFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.formTraining.create,
        {
            mutationKey: 'form-training-add',
            onSuccess() {
                invalidateQueries(queryKeysFormTraining[0])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.log('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 4000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialFormTrainingFields)
        setErrors(clearFormTrainingErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[antField('name')]}>
            <FormTrainingFormInputs
                handleChange={handleChange}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default FormTrainingAddForm