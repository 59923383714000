import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { FormTrainingObj, ObjDB } from '../../../../../types/apiTypes';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import { FormTrainingFormFields } from '../../../../../types/formFieldTypes';
import EditPlaceholder from '../../../../../components/common/EditPlaceholder/EditPlaceholder';
import { getAntField } from '../../../../../utils/form';
import { formTrainingValidate, clearFormTrainingErrors, initialFormTrainingFields } from '../../formData/FormTrainingFormData';
import  FormTrainingFormInputs from '../FormTrainingFormInputs/FormTrainingFormInputs';
import { invalidateQueries } from '../../../../../utils/rest';
import { queryKeysFormTraining } from '../../../../../static/data/queryKeys';


type FormTrainingEditFormProps = {
    editItem: ObjDB<FormTrainingObj> | null
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
    setEditItem: React.Dispatch<React.SetStateAction<ObjDB<FormTrainingObj> | null>>
}

const FormTrainingEditForm: React.FC<FormTrainingEditFormProps> = React.memo((props) => {
    const {
        editItem, setOpenedForm, setEditItem
    } = props

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<FormTrainingFormFields>({
        initialValues: initialFormTrainingFields,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: formTrainingValidate
    })

    const antField = getAntField<FormTrainingFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        async (values: FormTrainingFormFields) => {
            if (editItem) return await api.formTraining.edit(values, editItem)
        },
        {
            mutationKey: 'form-training-edit',
            onSuccess() {
                invalidateQueries(queryKeysFormTraining[0])
                formStatus.setMessage('Данные успешно изменены')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.error('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 5000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialFormTrainingFields)
        setErrors(clearFormTrainingErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setOpenedForm(false)
        setTimeout(() => {
            setEditItem(null)
            formStatus.reset()
        }, 300)
    }

    const resetChanges = () => {
        if (editItem) {
            const { id, ...values } = editItem
            setValues(values)
            setErrors(clearFormTrainingErrors)
        }
    }

    useEffect(() => {
        if (editItem) return resetChanges()
        clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    if (!editItem) return <EditPlaceholder />

    return (
        <Form
            fields={[antField('name')]}
        >
            {
                editItem &&
                <p className="uif-ant-form-title">
                    ID редактируемого объекта: <span>{editItem.id}</span>
                </p>
            }
            <FormTrainingFormInputs
                handleChange={handleChange}
                resetChanges={resetChanges}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default FormTrainingEditForm