import React, { useMemo, useState } from 'react'
import NavbarLayout from '../../../components/layout/NavbarLayout/NavbarLayout'
import './EducationCoursePage.scss'
import SoloCollapse from '../../../components/ui/SoloCollapse/SoloCollapse';
import {
    ColumnsType,
} from 'antd/lib/table/interface';
import TableObjects from '../../../components/ui/TableObjects/TableObjects';
import { useTableObject } from '../../../utils/hooks/useTableObject';
import { addCollapseIcon, editCollapseIcon } from '../../../components/ui/SoloCollapse/components/icons';
import TableActionButtons from '../../../components/ui/TableObjects/components/TableActionButtons/TableActionButtons';
import { useMutation, useQuery } from 'react-query';
import { EducationCourseObj, ObjDB, TrainingProgramsObj } from '../../../types/apiTypes';
import { api } from '../../../api/api';
import { getSelectedItems } from '../../../utils/parsers';
import { openNotification } from '../../../utils/jsxUtils';
import { RouteComponentProps, useParams } from 'react-router';
import { invalidateQueries, optionsQueryGetAll, queryOptionsPreset } from '../../../utils/rest';
import { queryKeysEducationCourse, queryKeysTrainingPrograms } from '../../../static/data/queryKeys';
import useScrollMount from '../../../utils/hooks/useScrollMount';
import EducationCourseAddForm from './components/EducationCourseAddForm/EducationCourseAddForm';
import EducationCourseEditForm from './components/EducationCourseEditForm/EducationCourseEditForm';
import FieldHelper from '../../../components/common/FieldHelper/FieldHelper';
import { educationCourseHelpers } from './formData/educationCourseFieldHepler';

type TableItem = ObjDB<EducationCourseObj>

interface EducationCoursePageProps extends RouteComponentProps {}

const EducationCoursePage: React.FC<EducationCoursePageProps> = () => {

    const [openedAddForm, setOpenedAddForm] = useState<boolean>(false)
    const [openedEditForm, setOpenedEditForm] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<TableItem | null>(null)
    const [tableHeadLoader, setTableHeadLoader] = useState<boolean>(false)

    const params = useParams<{ id: string }>()

    const {
        selectedRowKeys,
        searchText,
        setSelectedRowKeys,
        getColumnSearchProps,
    } = useTableObject({initialSearchText: params.id})

    const queryTrainingProgram = useQuery<ObjDB<TrainingProgramsObj>[], Error, ObjDB<TrainingProgramsObj>[]>(
        queryKeysTrainingPrograms[1],
        api.trainingPrograms.getAll,
        { ...optionsQueryGetAll }
    )

    const queryAll = useQuery<undefined, Error, TableItem[]>(
        queryKeysEducationCourse[0],
        api.educationCourse.getAll,
        {
            onError(err: Error) {
                console.log('ERROR get all objects', err)
                openNotification(
                    'error',
                    'Ошибка получения списка объектов',
                    err.message
                )
            },
            ...queryOptionsPreset,
        }
    )

    const queryDelete = useMutation(
        'education-course-delete',
        async (deleteItem: ObjDB<EducationCourseObj>) => {
            await api.educationCourse.delete(deleteItem)
            await deletePrograms([deleteItem.id])
        },
        {
            onSuccess() {
                openNotification('success', 'Объект успешно удален')
                invalidateQueries(queryKeysEducationCourse)
            },
            onError(err: Error) {
                console.error("Error delete object: ", err.message)
                openNotification('error', 'Ошибка при удалении объекта', err.message)
            },
        }
    )

    const columns: ColumnsType<TableItem> = useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: {
                    compare: (a, b) => a.id - b.id,
                },
                ...getColumnSearchProps('id'),
                defaultFilteredValue: params.id === searchText ? [params.id] : undefined,
            },
            {
                title: 'Название',
                dataIndex: 'name',
                sorter: {
                    compare: (a, b) => a.name.localeCompare(b.name),
                },
                ...getColumnSearchProps('name'),
            },
            {
                title: 'Действие',
                dataIndex: '',
                align: 'right',
                render: (value) => (
                    <TableActionButtons
                        onEdit={() => handleEditItem(value)}
                        onDelete={() => queryDelete.mutate(value)}
                    />
                )
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumnSearchProps])

    const deletePrograms = async (educationCourseIds: number[]) => {
        return Promise.all(
            educationCourseIds.map(educationId => {
                return api.trainingPrograms.deleteMany(educationPrograms(educationId))
            })
        )
    }

    const educationPrograms = (educationCourseId: number): ObjDB<TrainingProgramsObj>[] => {
        const programs = queryTrainingProgram.data || []
        return programs.filter(p => p.education_course_id === educationCourseId)
    }

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const handleTableAdd = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setOpenedAddForm(true)
    }

    const handleEditItem = (value: TableItem) => {
        setEditItem(value)
        setOpenedEditForm(true)
        setOpenedAddForm(false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    const handleTableDelete = async () => {
        try {
            setTableHeadLoader(true)
            const deleteItems = getSelectedItems<TableItem>(selectedRowKeys, queryAll.data || [])
            await api.educationCourse.deleteMany(deleteItems)
            await deletePrograms(deleteItems.map(c => c.id))
            invalidateQueries(queryKeysEducationCourse[0])
            openNotification(
                'success',
                'Выбранные объекты успешно удалены'
            )
            setSelectedRowKeys([])
        } catch (err) {
            console.error('Error delete many', err)
            openNotification(
                'error',
                'Ошибка при удалении объектов',
                err.message
            )
        } finally { setTableHeadLoader(false) }
    }
    const handleTableRefresh = () => invalidateQueries(queryKeysEducationCourse)

    useScrollMount()

    return (
        <NavbarLayout>
            <section className="uif-education-course-page">
                <FieldHelper
                    content={educationCourseHelpers.pageTitle}
                    text="Курс обучения | Education course"
                    isPageTitle
                />

                <div className="uif-education-course-page__collapses">
                    <SoloCollapse
                        header="Создать новый объект"
                        keyCollapse="add"
                        show={openedAddForm}
                        icon={addCollapseIcon}
                        setShow={setOpenedAddForm}
                    >
                        <EducationCourseAddForm />
                    </SoloCollapse>
                    <SoloCollapse
                        header="Редактировать объект"
                        keyCollapse="edit"
                        show={openedEditForm}
                        icon={editCollapseIcon}
                        setShow={setOpenedEditForm}

                    >
                        <EducationCourseEditForm
                            editItem={editItem}
                            setOpenedForm={setOpenedEditForm}
                            setEditItem={setEditItem}
                        />
                    </SoloCollapse>
                </div>

                <TableObjects
                    selectedRowKeys={selectedRowKeys}
                    count={queryAll.data?.length || 0}
                    columns={columns}
                    dataSource={queryAll.data}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: onSelectChange
                    }}
                    onDelete={handleTableDelete}
                    onAdd={handleTableAdd}
                    onRefresh={handleTableRefresh}
                    rowKey="id"
                    isFetching={queryAll.isFetching}
                    headerLoader={tableHeadLoader}
                    scroll={{x: 500}}
                />
            </section>
        </NavbarLayout >
    )
}

export default EducationCoursePage