export const queryKeysCity = ['city-all'] as const
export const queryKeysCoach = ['coach-all'] as const
export const queryKeysCourseBG = ['course-bg-all'] as const
export const queryKeysCourse = ['direction-all', 'city-all', 'coach-all', 'tag-all', 'goal-all', 'duration-all', 'level-all', 'course-bg-all', 'type-of-training-all', 'form-training-all', 'course-all' ,'course-all', 'factor-all'] as const
export const queryKeysCourseReview = ['course-all', 'course-review-all'] as const
export const queryKeysDirectionGroups = ['direction-group-all'] as const
export const queryKeysDirection = ['direction-group-all', 'direction-all'] as const
export const queryKeysDiscount = ['course-all', 'city-all', 'discount-all'] as const
export const queryKeysDuration = ['duration-all'] as const
export const queryKeysEducationCourse = ['education-course-all', 'training-program-all'] as const
export const queryKeysFactor = ['course-all', 'factor-all'] as const
export const queryKeysFormTraining = ['form-training-all'] as const
export const queryKeysGoal = ['goal-all'] as const
export const queryKeysLevel = ['level-all'] as const
export const queryKeysPrice = ['course-all', 'city-all', 'price-all'] as const
export const queryKeysQAndA = ['course-all', 'qAndA-all'] as const
export const queryKeysSalary = ['salary-all', 'course-all'] as const
export const queryKeysSkill = ['course-all', 'skill-all'] as const
export const queryKeysTag = ['tag-all'] as const
export const queryKeysTrainingPrograms = ['training-stream-all', 'training-program-all'] as const
export const queryKeysTrainingStreams = ['course-all', 'city-all', 'training-stream-all', 'education-course-all'] as const
export const queryKeysTypeOfTraining = ['type-training-all'] as const
