export const baseUrl = 'https://upward.uif66.ru'

export const loginUrl = `${baseUrl}/auth/login`
export const refreshUrl = `${baseUrl}/auth/refresh`
export const profileUrl = `${baseUrl}/auth/profile`
export const logoutUrl = `${baseUrl}/auth/logout`

export const fileUrl = `${baseUrl}/admin/upload`
export const cityUrl = `${baseUrl}/admin/cities`
export const educationCourseUrl = `${baseUrl}/admin/education-course`
export const goalUrl = `${baseUrl}/admin/goals`
export const directionGroupUrl = `${baseUrl}/admin/directions-groups`
export const durationUrl = `${baseUrl}/admin/durations`
export const levelUrl = `${baseUrl}/admin/levels`
export const tagUrl = `${baseUrl}/admin/tags`
export const directionUrl = `${baseUrl}/admin/directions`
export const trainingStreamUrl = `${baseUrl}/admin/trainings-streams`
export const coachUrl = `${baseUrl}/admin/coaches`
export const courseUrl = `${baseUrl}/admin/courses`
export const salaryUrl = `${baseUrl}/admin/salaries`
export const courseReviewUrl = `${baseUrl}/admin/course-reviews`
export const priceUrl = `${baseUrl}/admin/prices`
export const discountUrl = `${baseUrl}/admin/discounts`
export const qAndAUrl = `${baseUrl}/admin/course-q-and-a`
export const skillUrl = `${baseUrl}/admin/skills`
export const factorUrl = `${baseUrl}/admin/factors`
export const trainingProgramsUrl = `${baseUrl}/admin/trainings-programs`
export const typeOfTrainingUrl = `${baseUrl}/admin/type-of-training`
export const courseBGUrl = `${baseUrl}/admin/background-course`
export const siteSettingUrl = `${baseUrl}/admin/settings-site`
export const formTrainingUrl = `${baseUrl}/admin/form-training`

// summary-table urls
export const citiesForCourseUrl = `${baseUrl}/admin/cities-for-course`
export const tagsForCourseUrl = `${baseUrl}/admin/tags-for-course`
export const coachesForCourseUrl = `${baseUrl}/admin/coaches-for-course`

