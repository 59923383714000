import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { CourseWithSummaryObj, ObjDB, UploadObj } from '../../../../../types/apiTypes';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import { CourseFormFields } from '../../../../../types/formFieldTypes';
import EditPlaceholder from '../../../../../components/common/EditPlaceholder/EditPlaceholder';
import { getAntField } from '../../../../../utils/form';
import { errorNotification, invalidateQueries, previewQueryOptions } from '../../../../../utils/rest';
import { clearCourseErrors, courseValidate, initialCourseFields } from '../../formData/CourseFormData';
import CourseFormInputs from '../CourseFormInputs/CourseFormInputs';
import { SwitchCourseItems } from '../../../../../types/propsTypes';
import { openNotification } from '../../../../../utils/jsxUtils';
import { queryKeysCourse } from '../../../../../static/data/queryKeys';

type CourseEditFormProps = {
    editItem: ObjDB<CourseWithSummaryObj> | null
    switchDataItems: SwitchCourseItems
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
    setEditItem: React.Dispatch<React.SetStateAction<ObjDB<CourseWithSummaryObj> | null>>
}

const CourseEditForm: React.FC<CourseEditFormProps> = React.memo((props) => {
    const {
        editItem, setOpenedForm, setEditItem,
        switchDataItems,
    } = props

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange, setFieldValue,
        submitForm, setValues, setErrors,
    } = useFormik<CourseFormFields>({
        initialValues: initialCourseFields,
        onSubmit: (values: CourseFormFields) => {
            mutate({
                ...values,
                name: values.name.trim()
            })
        },
        validate: courseValidate,
        validateOnChange: false,
    })

    const antField = getAntField<CourseFormFields>(values, errors, touched)

    const { mutate } = useMutation(
        async (values: CourseFormFields) => {
            if (editItem) return await api.courses.edit(values, editItem)
        },
        {
            mutationKey: 'course-edit',
            onSuccess() {
                invalidateQueries(queryKeysCourse[11])
                openNotification('success', 'Курс успешно отредактирован')
                formStatus.setMessage('Данные успешно изменены')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.error('ERROR', err)
                errorNotification(err, 'Ошибка редактирования курса')
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 5000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const previewCourseMutate = useMutation<ObjDB<UploadObj>, Error, number, void>(
        'preview-course-get',
        api.file.get,
        { ...previewQueryOptions(formStatus) }
    )

    const previewDocumentMutate = useMutation<ObjDB<UploadObj>, Error, number, void>(
        'preview-document-get',
        api.file.get,
        { ...previewQueryOptions(formStatus) }
    )

    const coursePreviewUpload = useMutation(
        api.file.add,
        {
            mutationKey: 'course-preview-add-edit',
            onSuccess(data: ObjDB<UploadObj>) {
                setFieldValue('preview_course_id', data.id)
                setFieldValue('_previewCourseSrc', data.path)
            },
            onError: (err: Error) => {
                errorNotification(err, 'Ошибка при загрузке файла, загрузите файл заново')
                setFieldValue('preview_course_id', 0)
                setFieldValue('_previewCourseSrc', '')
            },
        }
    )

    const documentPreviewUpload = useMutation(
        api.file.add,
        {
            mutationKey: 'document-preview-add-edit',
            onSuccess(data: ObjDB<UploadObj>) {
                setFieldValue('document_preview_id', data.id)
                setFieldValue('_documentPreviewSrc', data.path)
            },
            onError: (err: Error) => {
                errorNotification(err, 'Ошибка при загрузке файла, загрузите файл заново')
                setFieldValue('document_preview_id', 0)
                setFieldValue('_documentPreviewSrc', '')
            },
        }
    )

    const handleChangeDocumentPreview = (file: File | null) => {
        if (file) documentPreviewUpload.mutate(file)
    }

    const handleChangeCoursePreview = (file: File | null) => {
        if (file) coursePreviewUpload.mutate(file)
    }

    const clearForm = () => {
        setValues(initialCourseFields)
        setErrors(clearCourseErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setOpenedForm(false)
        setTimeout(() => {
            setEditItem(null)
            formStatus.reset()
        }, 300)
    }

    const resetChanges = () => {
        if (editItem) {
            const { summary, ...editFields } = editItem
            setValues({
                ...editFields,
                _previewCourseSrc: '',
                _documentPreviewSrc: '',
                is_hide: !!editFields.is_hide,
                is_installment: !!editFields.is_installment,
                is_internship: !!editFields.is_internship,
                installment_months: Number(editFields.installment_months),
                tag_ids: editItem.summary.tags.map(s => s.tag_id),
                city_ids: editItem.summary.cities.map(s => s.city_id),
                coach_ids: editItem.summary.coaches.map(s => s.coach_id),
                training_result: editFields.training_result || '',
            })
            setErrors(clearCourseErrors)
        }
    }

    useEffect(() => {
        if (editItem) {
            if (editItem.document_preview_id) previewDocumentMutate.mutate(editItem.document_preview_id)
            if (editItem.preview_course_id) previewCourseMutate.mutate(editItem.preview_course_id)
            return resetChanges()
        }
        clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    if (!editItem) return <EditPlaceholder />

    return (
        <Form
            fields={[
                antField('name'),
                antField('code'),
                antField('position'),
                antField('direction_id', { withFalsy: true }),
                antField('description'),
                antField('document_name'),
                antField('document_description'),
                antField('city_ids', { withFalsy: true }),
                antField('type_of_training_id', { withFalsy: true }),
                antField('form_training_id', { withFalsy: true }),
                antField('coach_ids', { withFalsy: true }),
                antField('tag_ids', { withFalsy: true }),
                antField('goal_id', { withFalsy: true }),
                antField('duration_id', { withFalsy: true }),
                antField('level_id', { withFalsy: true }),
                antField('hours'),
                antField('months'),
                antField('amount_of_time'),
                antField('max_seats'),
                antField('is_installment'),
                antField('installment_months'),
                antField('training_result'),
            ]}
        >
            {
                editItem &&
                <p className="uif-ant-form-title">
                    ID редактируемого объекта: <span>{editItem.id}</span>
                </p>
            }
            <CourseFormInputs
                values={values}
                errors={errors}
                switchDataItems={switchDataItems}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                resetChanges={resetChanges}
                submitForm={submitForm}
                documentPreview={{
                    defaultSrc: previewDocumentMutate.data?.path,
                    isFetching: documentPreviewUpload.isLoading,
                    onChangeImg: handleChangeDocumentPreview,
                }}
                coursePreview={{
                    defaultSrc: previewCourseMutate.data?.path,
                    isFetching: coursePreviewUpload.isLoading,
                    onChangeImg: handleChangeCoursePreview,
                }}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})



export default CourseEditForm