import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { CoachObj, ObjDB, UploadObj } from '../../../../../types/apiTypes';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import { CoachFormFields } from '../../../../../types/formFieldTypes';
import EditPlaceholder from '../../../../../components/common/EditPlaceholder/EditPlaceholder';
import { getAntField } from '../../../../../utils/form';
import { CoachFormInputs } from '../CoachFormInputs/CoachFormInputs';
import { clearCoachErrors, coachValidate, initialCoachFields } from '../../formData/CoachFormData';
import { errorNotification, invalidateQueries, previewQueryOptions } from '../../../../../utils/rest';
import { queryKeysCoach } from '../../../../../static/data/queryKeys';

type CoachEditFormProps = {
    editItem: ObjDB<CoachObj> | null
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
    setEditItem: React.Dispatch<React.SetStateAction<ObjDB<CoachObj> | null>>
}

const CoachEditForm: React.FC<CoachEditFormProps> = React.memo((props) => {
    const {
        editItem, setOpenedForm, setEditItem
    } = props

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange, setFieldValue,
        submitForm, setValues, setErrors,
    } = useFormik<CoachFormFields>({
        initialValues: initialCoachFields,
        onSubmit: (values: CoachFormFields) => {
            mutate(values)
        },
        validate: coachValidate,
        validateOnChange: false,
    })

    const antField = getAntField<CoachFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        async (values: CoachFormFields) => {
            if (editItem) return await api.coaches.edit(values, editItem)
        },
        {
            mutationKey: 'coach-edit',
            onSuccess() {
                invalidateQueries(queryKeysCoach[0])
                formStatus.setMessage('Данные успешно изменены')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.error('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 5000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const defaultAvatarMutate = useMutation<ObjDB<UploadObj>, Error, number, void>(
        'avatar-mutate-edit',
        api.file.get,
        { ...previewQueryOptions(formStatus) }
    )

    const mutationFile = useMutation(
        api.file.add,
        {
            mutationKey: 'avatar-add-edit',
            onSuccess(data: ObjDB<UploadObj>) {
                setFieldValue('avatar_id', data.id)
                setFieldValue('_avatarSrc', data.path)
            },
            onError: (err: Error) => {
                errorNotification(err, 'Ошибка при загрузке файла, загрузите файл заново', formStatus)
                setFieldValue('avatar_id', 0)
                setFieldValue('_avatarSrc', '')
            },
        }
    )

    const handleChangeImg = (file: File | null) => {
        if (file) mutationFile.mutate(file)
    }

    const clearForm = () => {
        setValues(initialCoachFields)
        setErrors(clearCoachErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setOpenedForm(false)
        setTimeout(() => {
            setEditItem(null)
            formStatus.reset()
        }, 300)
    }


    const resetChanges = () => {
        if (editItem) {
            const {
                id,
                experience,
                education,
                ...itemFields
            } = editItem
            setValues({
                ...itemFields,
                experience: experience || '',
                education: education || '',
                _avatarSrc: ''
            })
            setErrors(clearCoachErrors)
        }
    }

    useEffect(() => {
        if (editItem) {
            if (editItem.avatar_id) defaultAvatarMutate.mutate(editItem.avatar_id)
            return resetChanges()
        }
        clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    if (!editItem) return <EditPlaceholder />

    return (
        <Form
            fields={[
                antField('first_name'),
                antField('last_name'),
                antField('quality'),
                antField('experience'),
                antField('education'),
            ]}
        >
            {
                editItem &&
                <p className="uif-ant-form-title">
                    ID редактируемого объекта: <span>{editItem.id}</span>
                </p>
            }
            <CoachFormInputs
                values={values}
                errors={errors}
                defaultImgSrc={defaultAvatarMutate.data?.path}
                isFetchingImg={mutationFile.isLoading}
                onChangeImg={handleChangeImg}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                resetChanges={resetChanges}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default CoachEditForm