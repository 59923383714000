import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { QAndAFormFields } from '../../../../../types/formFieldTypes';
import { getAntField } from '../../../../../utils/form';
import { clearQAndAErrors, initialQAndAFields, qAndAValidate } from '../../formData/QAndAFormData';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { SwitchQAndAItems } from '../../../../../types/propsTypes';
import QAndAFormInputs from '../QAndAFormInputs/QAndAFormInputs';
import { queryKeysQAndA } from '../../../../../static/data/queryKeys';

type QAndAAddFormProps = {
    switchData: SwitchQAndAItems
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
}

const QAndAAddForm: React.FC<QAndAAddFormProps> = React.memo((props) => {
    const {
        switchData
    } = props
    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        setFieldValue, handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<QAndAFormFields>({
        initialValues: initialQAndAFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: qAndAValidate
    })

    const antField = getAntField<QAndAFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.qAndA.create,
        {
            mutationKey: 'qAndA-add',
            onSuccess() {
                invalidateQueries(queryKeysQAndA[1])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка создания', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialQAndAFields)
        setErrors(clearQAndAErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[
            antField('course_id', {withFalsy: true}),
            antField('question'),
            antField('answer'),
        ]}>
            <QAndAFormInputs
                switchData={switchData}
                values={values}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default QAndAAddForm