import React, { useMemo, useState } from 'react'
import NavbarLayout from '../../../components/layout/NavbarLayout/NavbarLayout'
import './CoursePage.scss'
import SoloCollapse from '../../../components/ui/SoloCollapse/SoloCollapse';
import PageTitle from '../../../components/common/PageTitle/PageTitle';
import {
    ColumnsType,
} from 'antd/lib/table/interface';
import TableObjects from '../../../components/ui/TableObjects/TableObjects';
import { useTableObject } from '../../../utils/hooks/useTableObject';
import { addCollapseIcon, editCollapseIcon } from '../../../components/ui/SoloCollapse/components/icons';
import TableActionButtons from '../../../components/ui/TableObjects/components/TableActionButtons/TableActionButtons';
import { useMutation, useQuery } from 'react-query';
import { ObjDB, UploadObj, DirectionObj, CityObj, GoalObj, DurationObj, LevelObj, CourseBGObj, TypeOfTrainingObj, CoachObj, TagObj, CourseWithSummaryObj, FactorObj } from '../../../types/apiTypes';
import { api } from '../../../api/api';
import { getSelectedItems } from '../../../utils/parsers';
import { openNotification, tableLinkId } from '../../../utils/jsxUtils';
import { RouteComponentProps, useParams } from 'react-router';
import TableImage from '../../../components/table/TableImage/TableImage';
import { invalidateQueries, optionsQueryGetAll } from '../../../utils/rest'
import { SwitchCourseItems } from '../../../types/propsTypes';
import CourseAddForm from './components/CourseAddForm/CourseAddForm';
import CourseEditForm from './components/CourseEditForm/CourseEditForm';
import { filterTableBlockLinks, filterTableLink, sortTableLinkByName } from '../../../utils/table';
import FormDropDescription from '../../../components/table/TableDropDescription/TableDropDescription';
import TableLinksBlock from '../../../components/table/TableLinksBlock/TableLinksBlock';
import { upwardObjects } from '../../../static/data/upwardObj';
import { queryKeysCourse } from '../../../static/data/queryKeys';
import useScrollMount from '../../../utils/hooks/useScrollMount';

type TableItem = CourseWithSummaryObj

interface CoursePageProps extends RouteComponentProps { }

const CoursePage: React.FC<CoursePageProps> = () => {

    const [openedAddForm, setOpenedAddForm] = useState<boolean>(false)
    const [openedEditForm, setOpenedEditForm] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<TableItem | null>(null)
    const [tableHeadLoader, setTableHeadLoader] = useState<boolean>(false)
    const [images, setImages] = useState<ObjDB<UploadObj>[]>([])
    const [backgrounds, setBackgrounds] = useState<ObjDB<CourseBGObj>[]>([])

    const params = useParams<{ id: string }>()

    const {
        selectedRowKeys,
        searchText,
        setSelectedRowKeys,
        getColumnSearchProps,
    } = useTableObject({ initialSearchText: params.id })

    const queryFactor = useQuery<ObjDB<FactorObj>[], Error, ObjDB<FactorObj>[]>(
        queryKeysCourse[12],
        api.factors.getAll,
        { ...optionsQueryGetAll }
    )

    const queryDirection = useQuery<undefined, Error, ObjDB<DirectionObj>[]>(
        queryKeysCourse[0],
        api.directions.getAll,
        { ...optionsQueryGetAll }
    )

    const queryCity = useQuery<undefined, Error, ObjDB<CityObj>[]>(
        queryKeysCourse[1],
        api.cities.getAll,
        { ...optionsQueryGetAll }
    )

    const queryCoach = useQuery<undefined, Error, ObjDB<CoachObj>[]>(
        queryKeysCourse[2],
        api.coaches.getAll,
        { ...optionsQueryGetAll }
    )

    const queryTag = useQuery<undefined, Error, ObjDB<TagObj>[]>(
        queryKeysCourse[3],
        api.tags.getAll,
        { ...optionsQueryGetAll }
    )

    const queryGoal = useQuery<undefined, Error, ObjDB<GoalObj>[]>(
        queryKeysCourse[4],
        api.goals.getAll,
        { ...optionsQueryGetAll }
    )

    const queryDuration = useQuery<undefined, Error, ObjDB<DurationObj>[]>(
        queryKeysCourse[5],
        api.duration.getAll,
        { ...optionsQueryGetAll }
    )

    const queryLevel = useQuery<undefined, Error, ObjDB<LevelObj>[]>(
        queryKeysCourse[6],
        api.levels.getAll,
        { ...optionsQueryGetAll }
    )

    const queryCourseBG = useQuery<ObjDB<CourseBGObj>[], Error, ObjDB<CourseBGObj>[]>(
        queryKeysCourse[7],
        api.courseBG.getAll,
        { ...optionsQueryGetAll }
    )

    const queryTypeOfTraining = useQuery<undefined, Error, ObjDB<TypeOfTrainingObj>[]>(
        queryKeysCourse[8],
        api.typeOfTrainings.getAll,
        { ...optionsQueryGetAll }
    )

    const queryFormTraining = useQuery<undefined, Error, ObjDB<TypeOfTrainingObj>[]>(
        queryKeysCourse[9],
        api.formTraining.getAll,
        { ...optionsQueryGetAll }
    )

    const queryAll = useQuery<CourseWithSummaryObj[], Error, TableItem[]>(
        queryKeysCourse[10],
        api.courses.getAll,
        { ...optionsQueryGetAll }
    )

    const switchDataItems: SwitchCourseItems = {
        directions: queryDirection.data || [],
        cities: queryCity.data || [],
        tags: queryTag.data || [],
        coaches: queryCoach.data || [],
        goals: queryGoal.data || [],
        durations: queryDuration.data || [],
        levels: queryLevel.data || [],
        backgrounds: queryCourseBG.data || [],
        typesOfTraining: queryTypeOfTraining.data || [],
        formTrainings: queryFormTraining.data || [],
    }

    const queryDelete = useMutation(
        'course-delete',
        async (deleteItem: ObjDB<CourseWithSummaryObj>) => {
            await api.courses.delete(deleteItem)
            await deleteFactors([deleteItem.id])
        },
        {
            onSuccess() {
                openNotification('success', 'Объект успешно удален')
                invalidateQueries([queryKeysCourse[11], queryKeysCourse[12]])
            },
            onError(err: Error) {
                console.error("Error delete object: ", err.message)
                openNotification('error', 'Ошибка при удалении объекта', err.message)
            },
        }
    )

    const imgMutate = useMutation(
        'img-mutate',
        api.file.get,
        {
            onSuccess(data: ObjDB<UploadObj>) {
                setImages([...images, data])
            },
            onError(err: Error) {
                console.error("Error load object: ", err.message)
                openNotification('error', 'Ошибка при загрузке файла', err.message)
            },
        }
    )

    const bgMutate = useMutation<ObjDB<CourseBGObj>, Error, number>(
        'bg-mutate',
        api.courseBG.get,
        {
            onSuccess(data) {
                setBackgrounds([...backgrounds, data])
            },
            onError(err: Error) {
                console.error("Error load object: ", err.message)
                openNotification('error', 'Ошибка при загрузке файла', err.message)
            },
        }
    )

    const columns: ColumnsType<TableItem> = useMemo(() => {
        const tableImage = (id: number) => (
            <TableImage
                id={id}
                imgContainer={images}
                isLoading={imgMutate.isLoading}
                onMutate={id => imgMutate.mutate(id)}
            />
        )
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: {
                    compare: (a, b) => a.id - b.id,
                },
                ...getColumnSearchProps('id'),
                defaultFilteredValue: params.id === searchText ? [params.id] : undefined,
                width: 90,
            },
            {
                title: 'Название',
                dataIndex: 'name',
                sorter: { compare: (a, b) => a.name.localeCompare(b.name) },
                ...getColumnSearchProps('name'),
                width: 150,
            },
            {
                title: 'Код номенклатуры',
                dataIndex: 'code',
                sorter: { compare: (a, b) => a.code - b.code },
                ...getColumnSearchProps('code'),
                width: 150,
            },
            {
                title: 'Направление',
                dataIndex: 'direction_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.direction_id, b.direction_id, queryDirection.data || []) },
                ...getColumnSearchProps('direction_id'),
                onFilter: (val, record) => filterTableLink(val, record.direction_id, queryDirection.data || []),
                render: (id) => tableLinkId(queryDirection.data || [], id, 'directions'),
                width: 150,
            },
            {
                title: 'Тип обучения',
                dataIndex: 'type_of_training_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.type_of_training_id, b.type_of_training_id, switchDataItems.typesOfTraining || []) },
                ...getColumnSearchProps('type_of_training_id'),
                onFilter: (val, record) => filterTableLink(val, record.type_of_training_id, switchDataItems.typesOfTraining || []),
                render: (id) => tableLinkId(switchDataItems.typesOfTraining || [], id, 'typeOfTraining'),
                width: 100,
            },
            {
                title: 'Форма обучения',
                dataIndex: 'form_training_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.form_training_id, b.form_training_id, switchDataItems.formTrainings || []) },
                ...getColumnSearchProps('form_training_id'),
                onFilter: (val, record) => filterTableLink(val, record.form_training_id, switchDataItems.formTrainings || []),
                render: (id) => tableLinkId(switchDataItems.formTrainings || [], id, 'formTraining'),
                width: 100,
            },
            {
                title: 'Имя документа',
                dataIndex: 'document_name',
                sorter: { compare: (a, b) => a.document_name.localeCompare(b.document_name) },
                ...getColumnSearchProps('document_name'),
                width: 150,
            },
            {
                title: 'ID Превью документа',
                dataIndex: 'document_preview_id',
                sorter: { compare: (a, b) => a.document_preview_id - b.document_preview_id },
                ...getColumnSearchProps('document_preview_id'),
                render: tableImage,
                width: 100,
            },
            {
                title: 'ID Превью курса',
                dataIndex: 'preview_course_id',
                sorter: { compare: (a, b) => a.preview_course_id - b.preview_course_id },
                ...getColumnSearchProps('preview_course_id'),
                render: tableImage,
                width: 100,
            },
            {
                title: 'Фон',
                dataIndex: 'background_id',
                render: (background_id: number) => {
                    const bgObj = backgrounds.find(bg => bg.id === background_id)
                    return (
                        <TableImage
                            id={background_id}
                            hideTextid={true}
                            imgSrc={bgObj?.img.path || ''}
                            isLoading={bgMutate.isLoading}
                            onMutate={id => bgMutate.mutate(id)}
                        />
                    )
                },
                width: 100,
            },
            {
                title: 'Города',
                dataIndex: 'city_ids_',
                ...getColumnSearchProps('city_ids_'),
                onFilter: (val, record) => {
                    const checkedIds = record.summary.cities.map(s => s.city_id)
                    return filterTableBlockLinks(val, checkedIds, queryCity.data || [])
                },
                render: (_, record) => <TableLinksBlock
                    checkedIds={record.summary.cities.map(s => s.city_id)}
                    allItems={queryCity.data || []}
                    basePath={upwardObjects.cities.routePath}
                />,
                width: 150,
            },
            {
                title: 'Теги',
                dataIndex: 'tag_ids_',
                ...getColumnSearchProps('tag_ids_'),
                onFilter: (val, record) => {
                    const checkedIds = record.summary.tags.map(s => s.tag_id)
                    return filterTableBlockLinks(val, checkedIds, queryTag.data || [])
                },
                render: (_, record) => <TableLinksBlock
                    checkedIds={record.summary.tags.map(s => s.tag_id)}
                    allItems={queryTag.data || []}
                    basePath={upwardObjects.tags.routePath}
                />,
                width: 200,
            },
            {
                title: 'Преподаватели',
                dataIndex: 'coach_ids_',
                ...getColumnSearchProps('coach_ids_'),
                onFilter: (val, record) => {
                    const checkedIds = record.summary.coaches.map(s => s.coach_id)
                    return filterTableBlockLinks(val, checkedIds, queryCoach.data || [])
                },
                render: (_, record) => <TableLinksBlock
                    checkedIds={record.summary.coaches.map(s => s.coach_id)}
                    allItems={queryCoach.data || []}
                    basePath={upwardObjects.coaches.routePath}
                />,
                width: 200,
            },
            {
                title: 'Длительность',
                dataIndex: 'duration_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.duration_id, b.duration_id, queryDuration.data || []) },
                ...getColumnSearchProps('duration_id'),
                onFilter: (val, record) => filterTableLink(val, record.duration_id, queryDuration.data || []),
                render: (id: number) => tableLinkId(queryDuration.data || [], id, 'durations'),
                width: 120,
            },
            {
                title: 'Должность',
                dataIndex: 'position',
                sorter: { compare: (a, b) => a.position.localeCompare(b.position) },
                ...getColumnSearchProps('position'),
                width: 150,
            },
            {
                title: 'Часы',
                dataIndex: 'hours',
                sorter: {
                    compare: (a: TableItem, b: TableItem) => a.hours - b.hours,
                },
                ...getColumnSearchProps('hours'),
                width: 90,
            },
            {
                title: 'Недели',
                dataIndex: 'amount_of_time',
                sorter: { compare: (a, b) => a.amount_of_time.localeCompare(b.amount_of_time) },
                ...getColumnSearchProps('amount_of_time'),
                width: 100,
            },
            {
                title: 'Месяцы',
                dataIndex: 'months',
                sorter: {
                    compare: (a: TableItem, b: TableItem) => a.months - b.months,
                },
                ...getColumnSearchProps('months'),
                width: 90,
            },
            {
                title: 'Места',
                dataIndex: 'max_seats',
                sorter: {
                    compare: (a: TableItem, b: TableItem) => a.max_seats - b.max_seats,
                },
                ...getColumnSearchProps('max_seats'),
                width: 90,
            },
            {
                title: 'Действие',
                dataIndex: '',
                align: 'right',
                render: (value: TableItem) => (
                    <TableActionButtons
                        onEdit={() => handleEditItem(value)}
                        onDelete={() => queryDelete.mutate(value)}
                    />
                ),
                width: 110,
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumnSearchProps])

    const deleteFactors = async (courseIds: number[]) => {
        return Promise.all(
            courseIds.map(courseId => {
                return api.factors.deleteMany(courseFactors(courseId))
            })
        )
    }

    const courseFactors = (courseId: number): ObjDB<FactorObj>[] => {
        const factors = queryFactor.data || []
        return factors.filter(f => f.course_id === courseId)
    }

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const handleTableAdd = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setOpenedAddForm(true)
    }

    const handleEditItem = (value: TableItem) => {
        setEditItem(value)
        setOpenedEditForm(true)
        setOpenedAddForm(false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    const handleTableDelete = async () => {
        try {
            setTableHeadLoader(true)
            const deleteItems = getSelectedItems<TableItem>(selectedRowKeys, queryAll.data || [])
            await api.courses.deleteMany(deleteItems)
            await deleteFactors(deleteItems.map(c => c.id))
            invalidateQueries([queryKeysCourse[11], queryKeysCourse[12]])
            openNotification(
                'success',
                'Выбранные объекты успешно удалены'
            )
            setSelectedRowKeys([])
        } catch (err) {
            console.error('Error delete many', err)
            openNotification(
                'error',
                'Ошибка при удалении объектов',
                err.message
            )
        } finally { setTableHeadLoader(false) }
    }

    const handleTableRefresh = () => invalidateQueries(queryKeysCourse)
    useScrollMount()

    return (
        <NavbarLayout>
            <section className="uif-course-page">
                <PageTitle text="Курсы | Courses" />

                <div className="uif-course-page__collapses">
                    <SoloCollapse
                        header="Создать новый объект"
                        keyCollapse="add"
                        show={openedAddForm}
                        icon={addCollapseIcon}
                        setShow={setOpenedAddForm}
                    >
                        <CourseAddForm switchDataItems={switchDataItems} />
                    </SoloCollapse>
                    <SoloCollapse
                        header="Редактировать объект"
                        keyCollapse="edit"
                        show={openedEditForm}
                        icon={editCollapseIcon}
                        setShow={setOpenedEditForm}

                    >
                        <CourseEditForm
                            editItem={editItem}
                            switchDataItems={switchDataItems}
                            setOpenedForm={setOpenedEditForm}
                            setEditItem={setEditItem}
                        />
                    </SoloCollapse>
                </div>

                <TableObjects
                    scroll={{ x: 2490 }}
                    selectedRowKeys={selectedRowKeys}
                    count={queryAll.data?.length || 0}
                    columns={columns}
                    dataSource={queryAll.data}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: onSelectChange
                    }}
                    expandable={{
                        expandedRowRender: (record: TableItem) => (
                            <FormDropDescription
                                data={[
                                    { title: 'Описание курса', text: record.description },
                                    { title: 'Описание документа', text: record.document_description },
                                    { title: 'Текст блока "Итоги обучения"', text: record.training_result || '' },
                                    { title: 'Цель', TextNode: tableLinkId(queryGoal.data || [], record.goal_id, 'goals') },
                                    { title: 'Уровень сложности', TextNode: tableLinkId(queryLevel.data || [], record.level_id , 'levels') },
                                    { title: 'Курс скрыт?', text: record.is_hide ? 'Да' : 'Нет'},
                                    { title: 'Отображение блока с практикой', text: record.is_internship ? 'Да' : 'Нет'},
                                    { title: 'Доступна рассрочка', text: record.is_installment ? 'Да' : 'Нет'},
                                    { title: 'Количество месяцев рассрочки', text: Number(record.installment_months) + ''},
                                ]}
                            />
                        ),
                    }}
                    onDelete={handleTableDelete}
                    onAdd={handleTableAdd}
                    onRefresh={handleTableRefresh}
                    rowKey="id"
                    isFetching={queryAll.isFetching || queryDelete.isLoading}
                    headerLoader={tableHeadLoader}
                />
            </section>
        </NavbarLayout >
    )
}
// render: (isHide: number) => ,

// render: (id: number) => tableLinkId(queryLevel.data || [], id, 'levels'),

// render: (id: number) => tableLinkId(queryGoal.data || [], id, 'goals'),

export default CoursePage