import { makeAutoObservable, runInAction } from "mobx"
import { userApi } from "../api/userApi"
import { UserResponseData } from "../types/apiTypes"


class UserStore {
    user: UserResponseData | null = null

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }

    async initial() {
        try {
            const data = await userApi.initial()
            runInAction(() => this.user = data)
        } catch(err) {
            console.warn('Initial user error:', err.message)
            runInAction(() => this.user = null)
        }
    }
}

export const userStore = new UserStore()