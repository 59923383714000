import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import AccountPage from './pages/AccountPage/AccountPage';
import AuthPage from './pages/AuthPage/AuthPage';
import { upwardObjectsArray } from './static/data/upwardObj';
import { activeDark, isDarken } from './utils/styleUtils'
import { observer } from "mobx-react-lite"
import { authStore } from './store/auth';
import { userStore } from './store/user';
import { Spin, Space } from 'antd';
import SiteSettingPage from './pages/SiteSettingPage/SiteSettingPage';

function App() {

    useEffect(() => {
        if (isDarken()) activeDark()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        authStore.authFromCookie()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (authStore.isAuth) userStore.initial()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore.isAuth])


    if (authStore.isAuth === null) return (
        <Space size="large" className="auth-app-loader">
            <Spin size="large" />
        </Space>
    )

    if (authStore.isAuth) return (
        <Switch>
            <Route path="/account" exact component={AccountPage} />
            <Route path="/siteSettings" exact component={SiteSettingPage} />
            {
                upwardObjectsArray()
                    .map((obj, i) => (
                        obj.routes.map(route => (
                            <Route
                                path={`/object/${route.path}`}
                                render={() => <route.component {...route.props} />}
                                key={i}
                                exact
                            />
                        ))
                    ))
                    .flat(Infinity)
            }
                        {/* {
                upwardObjectsArray('name', 1, upwardSummaryObjects)
                    .map((obj, i) => (
                        obj.routes.map(route => (
                            <Route
                                path={`/summary/${route.path}`}
                                render={() => <route.component {...route.props} />}
                                key={i}
                                exact
                            />
                        ))
                    ))
                    .flat(Infinity)
            } */}
            <Redirect to="/account" />
        </Switch>
    )

    if (!authStore.isAuth) return (
        <Switch>
            <Route path="/auth" exact component={AuthPage} />
            <Redirect to="/auth" />
        </Switch>
    )

    return null
}

export default observer(App);
