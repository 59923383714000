import { DirectionGroupsFormFields } from "../../../../types/formFieldTypes"

export const directionGroupsValidate = (values: DirectionGroupsFormFields) => {
    const errors = {} as { [key: string]: string }
    if (!values.name) errors.name = 'Введите название'
    if (!values.avatar_id) errors.avatar_id = 'Загрузите файл'
    return errors
}

export const initialDirectionGroupsFields = {
    avatar_id: 0,
    name: '',

    _avatarSrc: '',
}

export const clearDirectionGroupsErrors = {
    avatar_id: '',
    name: '',

    _avatarSrc: '',
}

