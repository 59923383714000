import { MenuOutlined } from "@ant-design/icons";
import { SortableContainer, SortableContainerProps, SortableElement, SortableHandle } from "react-sortable-hoc";

export const SortItem = SortableElement((props: any) => <tr {...props} />);
export const DragHandle =  SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />)
export const SortContainer = SortableContainer((props: any) => <tbody {...props} />);

export const DraggableBodyRow = (data: { id: number }[]) => {
    return ({ ...restProps }) => {
        const index = data.findIndex(x => x.id === restProps['data-row-key']);
        return <SortItem index={index} {...restProps} />;
    }
}

export const DraggableContainer = (onSortEnd: SortableContainerProps['onSortEnd']) => {
    return (props: any) => (
        <SortContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    )
}