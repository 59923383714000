import { SalaryFormFields } from "../../../../types/formFieldTypes"

export const salaryValidate = (values: SalaryFormFields) => {
    const errors = {} as { [key: string]: string }

    if (!values.course_id) errors.course_id = 'Выберите курс'
    if (!values.salary) errors.salary = 'Укажите зарплату'
    if (!values.text) errors.text = 'Добавьте пояснение'
    return errors
}

export const initialSalaryFields: SalaryFormFields  = {
    course_id: 0,
    salary: 0,
    text: '',
}

export const clearSalaryErrors = {
    course_id: '',
    salary: '',
    text: '',
}
