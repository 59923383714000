import React from 'react'
import './TableObjects.scss'
import {
    PlusCircleOutlined,
    RedoOutlined,
    DeleteOutlined,
    FieldNumberOutlined,
} from '@ant-design/icons'
import { Table, Popover, Popconfirm } from 'antd';
import { TablePaginationConfig, TableProps } from 'antd/lib/table'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { confirmIcon } from '../../../utils/jsxUtils';
import { tableLocale } from '../../../static/data/tableLocale';


interface TableObjectsProps extends TableProps<any> {
    selectedRowKeys: React.Key[]
    count: number | string
    onDelete?: () => void
    onAdd?: () => void
    onRefresh?: () => void
    onSaveSort?: () => void
    isFetching?: boolean
    headerLoader?: boolean
    pagination?: false | TablePaginationConfig
}

const TableObjects: React.FC<TableObjectsProps> = React.memo((props) => {
    const {
        selectedRowKeys, count, isFetching,
        headerLoader, scroll, pagination,
        onSaveSort,
        onDelete, onAdd, onRefresh,
        ...tableProps
    } = props

    const popoverBtnClasses = () => {
        let classes = ['uif-object-table__top-control', 'uif-btn', 'uif-btn-white']
        if (headerLoader) classes.push('disabled')
        return classes.join(' ')
    }

    return (
        <div className="uif-object-table">
            <div className="uif-object-table__body">
                <div className="uif-object-table__top">
                    <div className="uif-object-table__count">
                        <p className="uif-object-table__count-name">Всего</p>
                        <p className="uif-object-table__count-value">{count}</p>
                    </div>
                    <div className="uif-object-table__top-controls">
                        {
                            headerLoader &&
                            <div className="uif-object-table__header-loader">
                                <Spin indicator={<LoadingOutlined className="uif-object-table__header-loader-icon" spin />} />
                            </div>
                        }
                        {
                            onSaveSort &&
                                <Popover
                                    placement="topLeft"
                                    title="Сохранить сортировку"
                                    content="Нажмите на иконку '№' для сохранения последовательности расположения объектов"
                                >
                                    <button
                                        className="uif-object-table__top-control uif-btn uif-btn-white"
                                        disabled={headerLoader}
                                        onClick={onSaveSort}
                                    >
                                        <FieldNumberOutlined
                                            className={
                                                headerLoader
                                                ? "disabled"
                                                : ""
                                            }
                                        />
                                    </button>
                                </Popover>
                        }
                        {
                            onDelete && (!tableProps.rowSelection?.selectedRowKeys?.length
                                ? <Popover
                                    placement="topLeft"
                                    title="Невозможно удалить"
                                    content="Выберите один или несколько объектов"
                                    className="uif-object-table__top-popover disabled"
                                >
                                    <DeleteOutlined />
                                </Popover>
                                : <Popconfirm
                                    title="Удалить выбранные объекты?"
                                    onConfirm={onDelete}
                                    okText="Да"
                                    cancelText="Нет"
                                    icon={confirmIcon()}
                                    disabled={headerLoader}
                                    className={popoverBtnClasses()}
                                >
                                    <DeleteOutlined />
                                </Popconfirm>)
                        }
                        {
                            onAdd &&
                            <button
                                className="uif-object-table__top-control uif-btn uif-btn-white"
                                disabled={headerLoader}
                                onClick={onAdd}
                            >
                                <PlusCircleOutlined />
                            </button>
                        }
                        {
                            onRefresh &&
                            <button
                                className="uif-object-table__top-control uif-btn uif-btn-white"
                                disabled={headerLoader}
                                onClick={onRefresh}
                            >
                                <RedoOutlined />
                            </button>
                        }
                    </div>
                </div>

                <div className="uif-object-table__table">
                    {
                        isFetching
                            ? <div className="uif-object-table__loader">
                                <Spin indicator={<LoadingOutlined className="uif-object-table__loader-icon" spin />} />
                            </div>
                            : <Table
                                {...tableProps}
                                locale={tableLocale}
                                scroll={{...scroll, y: scroll?.y ? scroll?.y : '75vh',}}
                                pagination={pagination ? pagination : false}
                            />
                    }
                </div>
            </div>
        </div>
    )
})

export default TableObjects