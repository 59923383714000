export const splitLocation = (path: string) => {
    const endsSlash = path[path.length - 1] === '/'
    const rowPath = endsSlash
        ? path.slice(1, path.length - 2)
        : path.slice(1)
    return rowPath.split('/').map(path => '/' + path)
}

export const trimDynamicPath = (path: string) => {
    const withoutDynamic = splitLocation(path).filter(p => p[1] !== ':')
    return withoutDynamic.join('')
}

export const getSelectedItems = <Data extends { id: number }>(selectedRowKeys: React.Key[], data: Data[]) => {
    const selectedItems = [] as Data[]
    selectedRowKeys.forEach(id => {
        const item = data.find(c => c.id === id)
        if (item) selectedItems.push(item)
    })
    return selectedItems
}

export const parseAntDate = (date: string, time: string) => {
    // "2021-05-01 00:00:00" and "1970-01-01 04:01:07" -> "2021-05-01 04:01:07"
    // "date____placeholder"     "placeholder____time"  -> "date time"
    const trimDate = date.split(' ')[0]?.split('-')?.join('')
    const trimTime = time.split(' ')[1]?.split(':')?.join('')
    return trimDate + trimTime
}

export const parseDateDB = (val: string, reverseDate = true) => {
    // "20160518000900" -> 2016-05-18 00:09:00
    const date = [] as string[]
    const time = [] as string[]
    if (typeof val === 'string' && val) {
        date.push(val.slice(0, 4))
        date.push(val.slice(4, 6))
        date.push(val.slice(6, 8))

        time.push(val.slice(8, 10))
        time.push(val.slice(10, 12))
        time.push(val.slice(12, 14))
    }
    const dateString = reverseDate ? date.reverse().join('-') : date.join('-')
    const timeString = time.join(':')
    return [dateString, timeString]
}

export const parseDBTimeToDate = (val: string): Date | null => {
    // "20160518000900" -> Date
    const date = new Date(2000, 2)

    if (!val) {
        // if date is equal '', get now date-string expamle: "20160518000900"
        // then parse this date-string to Date obj
        return null
    }
    date.setSeconds(+val.slice(12, 14));
    date.setMinutes(+val.slice(10, 12));
    date.setHours(+val.slice(8, 10));

    date.setDate(+val.slice(6, 8));
    date.setMonth(+val.slice(4, 6) - 1);
    date.setFullYear(+val.slice(0, 4));

    return date
}

const getTimeOffset = () => {
    const rowMinutesOffset = (new Date()).getTimezoneOffset() * -1
    const hoursOffset = Math.trunc(rowMinutesOffset / 60)
    const minutesOffset = rowMinutesOffset - (hoursOffset * 60)
    return {
        hoursOffset,
        minutesOffset
    }
}

export const dbTimeToZeroUTC = (val: string) => {
    const localDate = parseDBTimeToDate(val)
    const { hoursOffset, minutesOffset } = getTimeOffset()
    localDate?.setHours(localDate.getHours() - hoursOffset)
    localDate?.setMinutes(localDate.getMinutes() - minutesOffset)
    return parseDateToDBTime(localDate)
}

export const dbTimeWithLocalTimeOffset = (val: string) => {
    const zeroUTC = parseDBTimeToDate(val)
    const { hoursOffset, minutesOffset } = getTimeOffset()
    zeroUTC?.setHours(zeroUTC.getHours() + hoursOffset)
    zeroUTC?.setMinutes(zeroUTC.getMinutes() + minutesOffset)
    return parseDateToDBTime(zeroUTC)
}

const addZero = (val: number | string): string => {
    if ((val + '').length === 1) return `0${val}`
    return val + ''
}

export const parseDateToDBTime = (date: Date | [Date, Date] | null): string => {
    // Date -> "20160518000900"
    if (Array.isArray(date) || !date) return '00000000000000'
    const year = date.getFullYear()
    const month = addZero(date.getMonth() + 1)
    const day = addZero(date.getDate())
    const hour = addZero(date.getHours())
    const minute = addZero(date.getMinutes())

    return year.toString() +
        month.toString() +
        day.toString() +
        hour.toString() +
        minute.toString() + '00'
}