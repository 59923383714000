import React, { useMemo, useState } from 'react'
import NavbarLayout from '../../../components/layout/NavbarLayout/NavbarLayout'
import './SalaryPage.scss'
import SoloCollapse from '../../../components/ui/SoloCollapse/SoloCollapse';
import {
    ColumnsType,
} from 'antd/lib/table/interface';
import TableObjects from '../../../components/ui/TableObjects/TableObjects';
import { useTableObject } from '../../../utils/hooks/useTableObject';
import { addCollapseIcon, editCollapseIcon } from '../../../components/ui/SoloCollapse/components/icons';
import TableActionButtons from '../../../components/ui/TableObjects/components/TableActionButtons/TableActionButtons';
import { useMutation, useQuery } from 'react-query';
import { CourseWithSummaryObj, ObjDB, SalaryObj } from '../../../types/apiTypes';
import { api } from '../../../api/api';
import { getSelectedItems } from '../../../utils/parsers';
import { openNotification, tableLinkId } from '../../../utils/jsxUtils';
import { RouteComponentProps, useParams } from 'react-router';
import { errorNotification, invalidateQueries, optionsQueryGetAll } from '../../../utils/rest'
import { SwitchSalaryItems } from '../../../types/propsTypes';
import SalaryEditForm from './components/SalaryEditForm/SalaryEditForm';
import SalaryAddForm from './components/SalaryAddForm/SalaryAddForm';
import { filterTableLink, sortTableLinkByName } from '../../../utils/table';
import { queryKeysSalary } from '../../../static/data/queryKeys';
import useScrollMount from '../../../utils/hooks/useScrollMount';
import FieldHelper from '../../../components/common/FieldHelper/FieldHelper';
import { salaryHelpers } from './formData/salaryFieldHepler';

type TableItem = ObjDB<SalaryObj>

interface SalaryPageProps extends RouteComponentProps {}

const SalaryPage: React.FC<SalaryPageProps> = () => {

    const [openedAddForm, setOpenedAddForm] = useState<boolean>(false)
    const [openedEditForm, setOpenedEditForm] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<TableItem | null>(null)
    const [tableHeadLoader, setTableHeadLoader] = useState<boolean>(false)

    const params = useParams<{ id: string }>()

    const {
        selectedRowKeys,
        searchText,
        setSelectedRowKeys,
        getColumnSearchProps,
    } = useTableObject({initialSearchText: params.id})

    const queryAll = useQuery<undefined, Error, TableItem[]>(
        queryKeysSalary[0],
        api.salaries.getAll,
        { ...optionsQueryGetAll }
    )

    const queryCourse = useQuery<CourseWithSummaryObj[], Error, ObjDB<CourseWithSummaryObj>[]>(
        queryKeysSalary[1],
        api.courses.getAll,
        { ...optionsQueryGetAll }
    )

    const switchDataItems: SwitchSalaryItems = {
        courses: queryCourse?.data || []
    }

    const queryDelete = useMutation(
        'salary-delete',
        api.salaries.delete,
        {
            onSuccess() {
                openNotification('success', 'Объект успешно удален')
                invalidateQueries(queryKeysSalary[0])
            },
            onError(err: Error) {
                console.error("Error delete object: ", err.message)
                openNotification('error', 'Ошибка при удалении объекта', err.message)
            },
        }
    )

    const columns: ColumnsType<TableItem> = useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: {
                    compare: (a, b) => a.id - b.id,
                },
                ...getColumnSearchProps('id'),
                defaultFilteredValue: params.id === searchText ? [params.id] : undefined,
                width: 90,
            },
            {
                title: 'Величина зарплаты',
                dataIndex: 'salary',
                sorter: { compare: (a, b) => a.salary - b.salary },
                ...getColumnSearchProps('salary'),
                width: 100,
            },
            {
                title: 'Пояснение',
                dataIndex: 'text',
                sorter: { compare: (a, b) => a.text.localeCompare(b.text) },
                ...getColumnSearchProps('text'),
                width: 150,
            },
            {
                title: 'Курс',
                dataIndex: 'course_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.course_id, b.course_id, queryCourse.data || []) },
                ...getColumnSearchProps('course_id'),
                onFilter: (val, record) => filterTableLink(val, record.course_id, queryCourse.data || []),
                render: (id: number) => tableLinkId(queryCourse.data || [], id, 'course'),
                width: 150,
            },
            {
                title: 'Действие',
                dataIndex: '',
                align: 'right',
                render: (value: TableItem) => (
                    <TableActionButtons
                        onEdit={() => handleEditItem(value)}
                        onDelete={() => queryDelete.mutate(value)}
                    />
                ),
                width: 80,
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumnSearchProps])

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const handleTableAdd = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setOpenedAddForm(true)
    }

    const handleEditItem = (value: TableItem) => {
        setEditItem(value)
        setOpenedEditForm(true)
        setOpenedAddForm(false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    const handleTableDelete = async () => {
        try {
            setTableHeadLoader(true)
            const deleteItems = getSelectedItems<TableItem>(selectedRowKeys, queryAll.data || [])
            await api.salaries.deleteMany(deleteItems)
            invalidateQueries(queryKeysSalary[0])
            openNotification(
                'success',
                'Выбранные объекты успешно удалены'
            )
            setSelectedRowKeys([])
        } catch (err) {
            errorNotification(err, 'Ошибка при удалении объектов')
        } finally { setTableHeadLoader(false) }
    }

    const handleTableRefresh = () => invalidateQueries(queryKeysSalary)
    useScrollMount()

    return (
        <NavbarLayout>
            <section className="uif-salary-page">
                <FieldHelper
                    content={salaryHelpers.pageTitle}
                    text="Зарплаты | Salaries"
                    isPageTitle
                />

                <div className="uif-salary-page__collapses">
                    <SoloCollapse
                        header="Создать новый объект"
                        keyCollapse="add"
                        show={openedAddForm}
                        icon={addCollapseIcon}
                        setShow={setOpenedAddForm}
                    >
                        <SalaryAddForm switchDataItems={switchDataItems} />
                    </SoloCollapse>
                    <SoloCollapse
                        header="Редактировать объект"
                        keyCollapse="edit"
                        show={openedEditForm}
                        icon={editCollapseIcon}
                        setShow={setOpenedEditForm}

                    >
                        <SalaryEditForm
                            editItem={editItem}
                            switchDataItems={switchDataItems}
                            setOpenedForm={setOpenedEditForm}
                            setEditItem={setEditItem}
                        />
                    </SoloCollapse>
                </div>

                <TableObjects
                    scroll={{ x: 800 }}
                    selectedRowKeys={selectedRowKeys}
                    count={queryAll.data?.length || 0}
                    columns={columns}
                    dataSource={queryAll.data}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: onSelectChange
                    }}
                    onDelete={handleTableDelete}
                    onAdd={handleTableAdd}
                    onRefresh={handleTableRefresh}
                    rowKey="id"
                    isFetching={queryAll.isFetching}
                    headerLoader={tableHeadLoader}
                />
            </section>
        </NavbarLayout >
    )
}


export default SalaryPage