import {
    FilterConfirmProps,
    FilterDropdownProps,
} from 'antd/lib/table/interface';
import { Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router';

export function useTableObject(
    {
        initialSearchText = '',
        initialSearchedColumn = ''
    } = {}) {
    const [searchText, setSearchText] = useState<React.Key>(initialSearchText)
    const [searchedColumn, setSearchedColumn] = useState<string>(initialSearchedColumn)
    const [searchInput, setSearchInput] = useState<Input | null>(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

    const params = useParams<{ id: string }>()

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => setSearchInput(node)}
                    placeholder={`Поиск ...`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Найти
              </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Очистить
              </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false })
                            setSearchText(selectedKeys[0])
                            setSearchedColumn(dataIndex)
                        }}
                    >
                        Фильтр
              </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: string | number | boolean, record: any) => {
            let lowerValue = value
            if (typeof lowerValue === "string") lowerValue = lowerValue.toLowerCase()
            if (params?.id && params.id === lowerValue) {
                /// strict finder by ID after router redirect
                return record[dataIndex].toString() === params.id
            }
            return record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(lowerValue)
                : ''
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) setTimeout(() => searchInput?.select(), 100)
        },
        render: (text: string) => {
            return searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText.toString()]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
        }
        ,
    })

    const handleSearch = useCallback((
        selectedKeys: React.Key[],
        confirm: (param?: FilterConfirmProps | undefined) => void,
        dataIndex: string
    ) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }, [])

    const handleReset = useCallback((clearFilters: (() => void) | undefined) => {
        if (clearFilters) clearFilters()
        setSearchText('')
    }, [])

    return {
        selectedRowKeys,
        setSelectedRowKeys,
        getColumnSearchProps,
        searchText
    }
}