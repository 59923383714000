import React from 'react'
import './TableCellPlaceholder.scss'

type TableCellPlaceholderProps = {
    text: string
}

const TableCellPlaceholder: React.FC<TableCellPlaceholderProps> = ({text}) => {

    return (
        <p className="table-cell-placeholder">{text}</p>
    )
}

export default TableCellPlaceholder