import React from 'react'
import './NavbarSideMenu.scss'
import { Menu, Button } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    InboxOutlined,
    UserOutlined,
    CloseCircleOutlined,
    GlobalOutlined,
    // TableOutlined,
} from '@ant-design/icons';
import { isOpenedMenuStorage, lockBodySmall, unlockBodySmall } from '../../../../../utils/styleUtils';
import { RouteComponentProps, withRouter } from 'react-router';
import { upwardObjectsArray } from '../../../../../static/data/upwardObj';
import { Link } from 'react-router-dom';
import { splitLocation, trimDynamicPath } from '../../../../../utils/parsers';
const { SubMenu } = Menu;

interface NavbarSideMenuProps extends RouteComponentProps {
    isOpen: boolean
    setOpen: (val: boolean) => void
}

// Список всех путей, по которым доступны подменю, путь на подменю должен идти сразу после hostname
// http://hostname.ru/subPath
const subFirstLvlPaths = ['/object', '/summary']

const NavbarSideMenu: React.FC<NavbarSideMenuProps> = React.memo((props) => {
    const {
        isOpen, history, match,
        setOpen,
    } = props

    const toggleOpen = () => {
        if (isOpen) unlockBodySmall()
        if (!isOpen) lockBodySmall()
        setOpen(!isOpen)
    }
    const openedFirstSub = subFirstLvlPaths.find(path => path === splitLocation(match.path)[0])

    const handleClickMenu = ({ pathRedirect = '', } = {}) => {
        if (pathRedirect) history.push(pathRedirect)
        unlockBodySmall()
        if (window.innerWidth <= 767) setOpen(false)
    }

    return (
        <>
            <nav className={
                isOpen
                    ? "uif-nav-sidemenu"
                    : "uif-nav-sidemenu collapse"
            }
            >
                <div className="uif-nav-sidemenu__body">
                    <div className="uif-nav-sidemenu__controls">
                        <Button type="primary" onClick={toggleOpen} className="uif-nav-sidemenu__collapse-btn">
                            {
                                isOpen
                                    ? <MenuUnfoldOutlined />
                                    : <MenuFoldOutlined />
                            }
                        </Button>
                        <Button type="primary" onClick={toggleOpen} className="uif-nav-sidemenu__burger-btn">
                            <CloseCircleOutlined />
                        </Button>
                    </div>
                    <Menu
                        defaultOpenKeys={[isOpenedMenuStorage() ? openedFirstSub || '' : '']}
                        defaultSelectedKeys={[trimDynamicPath(match.path)]}
                        mode="inline"
                        theme="dark"
                        inlineCollapsed={!isOpen}
                        className="uif-nav-sidemenu__menu"
                    >
                        <Menu.Item
                            key='/account'
                            icon={<UserOutlined />}
                            className="uif-nav-sidemenu__menu-item-first"
                            onClick={() => handleClickMenu({pathRedirect: '/account'})}
                        >
                            Аккаунт
                        </Menu.Item>

                        <Menu.Item
                            key='/siteSettings'
                            icon={<GlobalOutlined />}
                            className="uif-nav-sidemenu__menu-item-first"
                            onClick={() => handleClickMenu({pathRedirect: '/siteSettings'})}
                        >
                            Настройки сайта
                        </Menu.Item>

                        <SubMenu
                            key='/object'
                            icon={<InboxOutlined />}
                            title="Объекты"
                            className="uif-nav-sidemenu__menu-submenu"
                        >
                            {
                                upwardObjectsArray('name').map(obj => (
                                    <Menu.Item
                                        key={`/object/${obj.routePath}`}
                                    >
                                        <Link
                                            to={`/object/${obj.routePath}`}
                                            onClick={() => handleClickMenu()}
                                        >
                                            {obj.name}
                                        </Link>
                                    </Menu.Item>
                                ))
                            }
                        </SubMenu>

                        {/* <SubMenu
                            key='/summary'
                            icon={<TableOutlined />}
                            title="Сводные таблицы"
                            className="uif-nav-sidemenu__menu-submenu"
                        >
                            {
                                upwardObjectsArray('name', 1, upwardSummaryObjects).map(obj => (
                                    <Menu.Item
                                        key={`/summary/${obj.routePath}`}
                                    >
                                        <Link to={`/summary/${obj.routePath}`} onClick={unlockBodySmall}>
                                            {obj.name}
                                        </Link>
                                    </Menu.Item>
                                ))
                            }
                        </SubMenu> */}
                    </Menu>
                </div>
            </nav>
            <div
                className={
                    isOpen
                        ? "uif-burger-menu-bg show"
                        : "uif-burger-menu-bg"
                }
                onClick={toggleOpen}
            ></div>
        </>
    )
})

export default withRouter(NavbarSideMenu)