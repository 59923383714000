import { HelprerContentItem } from "../../../../types/propsTypes";

export const qAndAHelpers = {
    pageTitle: [
        {
            type: 'title',
            text: 'Вопрос - ответ'
        },
        'Отобажается на странице курса',
        {
            type: 'image',
            imgSrc: '/static/img/helper/page-title/qAndA.jpg',
        }
    ] as HelprerContentItem[]
}