import React from 'react'
import { Form, Input } from 'antd';
import FormControls from '../../../../../components/form/FormControls/FormControls';

type EducationCourseFormInputsProps = {
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    resetChanges?: () => void
    submitForm: () => void
    clearForm?: () => void
}

export const EducationCourseFormInputs: React.FC<EducationCourseFormInputsProps> = (props) => {
    const {
        handleChange, resetChanges, submitForm,
        clearForm,
    } = props

    return (
        <>
            <Form.Item
                name="name"
                label="Название курса обучения"
                className="uif-ant-form-item"
            >
                <Input onInput={handleChange} />
            </Form.Item>

            <FormControls
                submitForm={submitForm}
                resetChanges={resetChanges}
                clearForm={clearForm}
            />
        </>
    )
}

export default EducationCourseFormInputs