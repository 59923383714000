import { HelprerContentItem } from "../../../../types/propsTypes";

export const formTrainingHelpers = {
    pageTitle: [
        {
            type: 'title',
            text: 'Формы обучения'
        },
        'Выступает в качестве фильтра для каталога курсов. Например, "Очно", "Онлайн", "Заочно" и т.д.',
    ] as HelprerContentItem[]
}