import { DiscountFormFields } from "../../../../types/formFieldTypes"
import { sortByDate } from "../../../../utils/form"

export const discountValidate = (values: DiscountFormFields) => {
    const errors = {} as { [key: string]: string }

    if (!values.course_id) errors.course_id = 'Выберите курс'
    if (values.city_id === null) errors.city_id = 'Выберите город'
    if (!values.name) errors.name = 'Введите имя'
    if (!values.date_from) errors.date_from = 'Укажите дату начала действия'
    if (!values.date_to) errors.date_to = 'Укажите дату окончания действия'
    if (values.date_from && values.date_to) {
        if (sortByDate(values.date_from, values.date_to) >= 0) {
            errors.date_to = 'Дата окончания наступит раньше даты начала, введите корректные даты'
        }
    }

    if (values.type_discount === 'fix_price' && values.fix_price === null) errors.fix_price = 'Укажите цену'
    if (values.type_discount === 'percent' && values.discount === null) errors.discount = 'Укажите проценты'
    return errors
}

export const initialDiscountFields: DiscountFormFields = {
    course_id: 0,
    city_id: null,
    name: '',
    discount: null,
    fix_price: null,
    type_discount: 'percent',
    date_from: '',
    date_to: '',
}

export const clearDiscountErrors = {
    course_id: '',
    city_id: '',
    name: '',
    discount: '',
    fix_price: '',
    type_discount: '',
    date_from: '',
    date_to: '',
}
