import React, { useMemo, useState } from 'react'
import NavbarLayout from '../../../components/layout/NavbarLayout/NavbarLayout'
import './DiscountPage.scss'
import SoloCollapse from '../../../components/ui/SoloCollapse/SoloCollapse';
import PageTitle from '../../../components/common/PageTitle/PageTitle';
import {
    ColumnsType,
} from 'antd/lib/table/interface';
import TableObjects from '../../../components/ui/TableObjects/TableObjects';
import { useTableObject } from '../../../utils/hooks/useTableObject';
import { addCollapseIcon, editCollapseIcon } from '../../../components/ui/SoloCollapse/components/icons';
import TableActionButtons from '../../../components/ui/TableObjects/components/TableActionButtons/TableActionButtons';
import { useMutation, useQuery } from 'react-query';
import { CityObj, CourseWithSummaryObj, DiscountObj, ObjDB } from '../../../types/apiTypes';
import { api } from '../../../api/api';
import { getSelectedItems } from '../../../utils/parsers';
import { openNotification, tableLinkId } from '../../../utils/jsxUtils';
import { RouteComponentProps, useParams } from 'react-router';
import { errorNotification, invalidateQueries, optionsQueryGetAll } from '../../../utils/rest';
import { sortByDate } from '../../../utils/form';
import { SwitchDiscountItems } from '../../../types/propsTypes';
import ParsedDate from '../../../components/table/ParsedDate/ParsedDate';
import DiscountAddForm from './components/DiscountAddForm/DiscountAddForm';
import DiscountEditForm from './components/DiscountEditForm/DiscountEditForm';
import { filterTableLink, sortTableLinkByName } from '../../../utils/table';
import { queryKeysDiscount } from '../../../static/data/queryKeys';
import useScrollMount from '../../../utils/hooks/useScrollMount';

interface DiscountPageProps extends RouteComponentProps { }

type TableItem = ObjDB<DiscountObj>

const DiscountPage: React.FC<DiscountPageProps> = () => {

    const [openedAddForm, setOpenedAddForm] = useState<boolean>(false)
    const [openedEditForm, setOpenedEditForm] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<TableItem | null>(null)
    const [tableHeadLoader, setTableHeadLoader] = useState<boolean>(false)

    const params = useParams<{ id: string }>()

    const {
        selectedRowKeys,
        searchText,
        setSelectedRowKeys,
        getColumnSearchProps,
    } = useTableObject({initialSearchText: params.id})

    const queryCourse = useQuery<CourseWithSummaryObj[], Error, ObjDB<CourseWithSummaryObj>[]>(
        queryKeysDiscount[0],
        api.courses.getAll,
        { ...optionsQueryGetAll }
    )

    const queryCity = useQuery<undefined, Error, ObjDB<CityObj>[]>(
        queryKeysDiscount[1],
        api.cities.getAll,
        { ...optionsQueryGetAll }
    )

    const switchData: SwitchDiscountItems = {
        courses: queryCourse.data || [],
        cities: queryCity.data || [],
    }

    const queryAll = useQuery<TableItem[], Error, TableItem[]>(
        queryKeysDiscount[2],
        api.discounts.getAll,
        { ...optionsQueryGetAll }
    )

    const queryDelete = useMutation(
        'discount-delete',
        api.discounts.delete,
        {
            onSuccess() {
                openNotification('success', 'Объект успешно удален')
                invalidateQueries(queryKeysDiscount[2])
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка при удалении объекта')
        }
    )

    const columns: ColumnsType<TableItem> = useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: {
                    compare: (a, b) => a.id - b.id,
                },
                ...getColumnSearchProps('id'),
                defaultFilteredValue: params.id === searchText ? [params.id] : undefined,
            },
            {
                title: 'Курс',
                dataIndex: 'course_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.course_id, b.course_id, queryCourse.data || []) },
                ...getColumnSearchProps('course_id'),
                onFilter: (val, record) => filterTableLink(val, record.course_id, queryCourse.data || []),
                render: (id) => tableLinkId(queryCourse.data || [], id, 'course'),
            },
            {
                title: 'Город',
                dataIndex: 'city_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.city_id, b.city_id, queryCity.data || []) },
                ...getColumnSearchProps('city_id'),
                onFilter: (val, record) => filterTableLink(val, record.city_id, queryCity.data || []),
                render: (id: number) => tableLinkId(queryCity.data || [], id, 'cities'),
                width: 150,
            },
            {
                title: 'Название',
                dataIndex: 'name',
                sorter: { compare: (a, b) => a.name.localeCompare(b.name) },
                ...getColumnSearchProps('name'),
            },
            {
                title: 'Скидка, %',
                dataIndex: 'discount',
                sorter: { compare: (a, b) => (a.discount - b.discount) || 0 },
                ...getColumnSearchProps('discount'),
            },
            {
                title: 'Фиксированная цена, ₽',
                dataIndex: 'fix_price',
                sorter: { compare: (a, b) => (a.fix_price - b.fix_price) || 0 },
                ...getColumnSearchProps('fix_price'),
            },
            {
                title: 'Дата начала',
                dataIndex: 'date_from',
                sorter: { compare: (a, b) => sortByDate(a.date_from, b.date_from) },
                render: (val: string) => <ParsedDate timeDB={val} />
            },
            {
                title: 'Дата окончания',
                dataIndex: 'date_to',
                sorter: { compare: (a, b) => sortByDate(a.date_to, b.date_to) },
                render: (val: string) => <ParsedDate timeDB={val} />
            },
            {
                title: 'Действие',
                dataIndex: '',
                align: 'right',
                render: (value: TableItem) => (
                    <TableActionButtons
                        onEdit={() => handleEditItem(value)}
                        onDelete={() => queryDelete.mutate(value)}
                    />
                )
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumnSearchProps])

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const handleTableAdd = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setOpenedAddForm(true)
    }

    const handleEditItem = (value: TableItem) => {
        setEditItem(value)
        setOpenedEditForm(true)
        setOpenedAddForm(false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleTableDelete = async () => {
        try {
            setTableHeadLoader(true)
            const deleteItems = getSelectedItems<TableItem>(selectedRowKeys, queryAll.data || [])
            await api.discounts.deleteMany(deleteItems)
            invalidateQueries(queryKeysDiscount[2])
            openNotification(
                'success',
                'Выбранные объекты успешно удалены'
            )
            setSelectedRowKeys([])
        } catch (err) {
            errorNotification(err, 'Ошибка при удалении объектов')
        }
        finally { setTableHeadLoader(false) }
    }
    const handleTableRefresh = () => invalidateQueries(queryKeysDiscount)
    useScrollMount()

    return (
        <NavbarLayout>
            <section className="uif-discount-page">
                <PageTitle text="Скидки | Discounts" />

                <div className="uif-discount-page__collapses">
                    <SoloCollapse
                        header="Создать новый объект"
                        keyCollapse="add"
                        show={openedAddForm}
                        icon={addCollapseIcon}
                        setShow={setOpenedAddForm}
                    >
                        <DiscountAddForm
                            switchData={switchData}
                            setOpenedForm={setOpenedAddForm}
                        />
                    </SoloCollapse>
                    <SoloCollapse
                        header="Редактировать объект"
                        keyCollapse="edit"
                        show={openedEditForm}
                        icon={editCollapseIcon}
                        setShow={setOpenedEditForm}
                    >
                        <DiscountEditForm
                            editItem={editItem}
                            switchData={switchData}
                            setOpenedForm={setOpenedEditForm}
                            setEditItem={setEditItem}
                        />
                    </SoloCollapse>
                </div>

                <TableObjects
                    selectedRowKeys={selectedRowKeys}
                    count={queryAll.data?.length || 0}
                    columns={columns}
                    dataSource={queryAll.data}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: onSelectChange
                    }}
                    onDelete={handleTableDelete}
                    onAdd={handleTableAdd}
                    onRefresh={handleTableRefresh}
                    rowKey="id"
                    isFetching={queryAll.isFetching}
                    headerLoader={tableHeadLoader}
                    scroll={{x: 1200}}
                />
            </section>
        </NavbarLayout >
    )
}

export default DiscountPage