import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { CourseBGFormFields } from '../../../../../types/formFieldTypes';
import { clearCourseBGErrors, courseBGValidate, initialCourseBGFields } from '../../formData/CourseBGPageFormData';
import { CourseBGFormInputs } from '../CourseBGPageFormInputs/CourseBGPageFormInputs';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { ObjDB, UploadObj } from '../../../../../types/apiTypes';
import { queryKeysCourseBG } from '../../../../../static/data/queryKeys';

type CourseBGAddFormProps = {}

const CourseBGAddForm: React.FC<CourseBGAddFormProps> = React.memo(() => {

    const formStatus = useFormStatus()

    const {
        errors, values,
        setFieldValue,
        submitForm, setValues, setErrors,
    } = useFormik<CourseBGFormFields>({
        initialValues: initialCourseBGFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: courseBGValidate
    })

    const {
        mutate
    } = useMutation(
        api.courseBG.create,
        {
            mutationKey: 'course-bg-add',
            onSuccess() {
                invalidateQueries(queryKeysCourseBG[0])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка создания', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            },
        }
    )

    const mutationFile = useMutation(
        api.file.add,
        {
            mutationKey: 'course-bg-img-add',
            onSuccess(data: ObjDB<UploadObj>) {
                setFieldValue('background_file_id', data.id)
                setFieldValue('_imgSrc', data.path)
            },
            onError: (err: Error) => {
                errorNotification(err, 'Ошибка при загрузке файла, загрузите файл заново')
                setFieldValue('background_file_id', 0)
                setFieldValue('_imgSrc', '')
            },
        }
    )

    const handleChangeImg = (file: File | null) => {
        if (file) mutationFile.mutate(file)
    }

    const clearForm = () => {
        setValues(initialCourseBGFields)
        setErrors(clearCourseBGErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form>
            <CourseBGFormInputs
                values={values}
                errors={errors}
                onChangeImg={handleChangeImg}
                clearForm={clearForm}
                isFetchingImg={mutationFile.isLoading}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default CourseBGAddForm