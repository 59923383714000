import React from 'react'
import { Form } from 'antd';
import ImageUpload from '../../../../../components/form/ImageUpload/ImageUpload';
import { FormikErrors } from 'formik';
import { CourseBGFormFields } from '../../../../../types/formFieldTypes';
import FormControls from '../../../../../components/form/FormControls/FormControls';

type CourseBGFormInputsProps = {
    values: CourseBGFormFields
    errors: FormikErrors<CourseBGFormFields>
    onChangeImg: (file: File | null) => void
    submitForm: () => void
    clearForm?: () => void
    resetChanges?: () => void
    defaultImgSrc?: string
    isFetchingImg?: boolean
}

export const CourseBGFormInputs: React.FC<CourseBGFormInputsProps> = (props) => {
    const {
        values, errors, defaultImgSrc,
        isFetchingImg,
        onChangeImg, clearForm, submitForm,
        resetChanges,
    } = props

    return (
        <>
            <Form.Item
                label="Изображение"
                className="uif-ant-form-item"
            >
                <ImageUpload
                    error={errors.background_file_id}
                    onlyImg={true}
                    defaultImgSrc={values._imgSrc || defaultImgSrc}
                    isFetching={isFetchingImg}
                    onChange={onChangeImg}
                />
            </Form.Item>

            <FormControls
                submitForm={submitForm}
                clearForm={clearForm}
                resetChanges={resetChanges}
            />
        </>
    )
}

export default CourseBGFormInputs