import { GoalFormFields } from "../../../../types/formFieldTypes"

export const goalValidate = (values: GoalFormFields) => {
    const errors = {} as { [key: string]: string }
    if (!values.name) errors.name = 'Введите название'
    if (!values.description) errors.description = 'Введите описание'
    return errors
}

export const initialGoalFields = {
    name: '',
    description: ''
}

export const clearGoalErrors = {
    name: '',
    description: ''
}
