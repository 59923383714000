import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { EducationCourseFormFields } from '../../../../../types/formFieldTypes';
import { getAntField } from '../../../../../utils/form';
import { educationCourseValidate, clearEducationCourseErrors, initialEducationCourseFields } from '../../formData/EducationCourseFormData';
import { EducationCourseFormInputs } from '../EducationCourseFormInputs/EducationCourseFormInputs';
import { invalidateQueries } from '../../../../../utils/rest';
import { queryKeysEducationCourse } from '../../../../../static/data/queryKeys';


type EducationCourseAddFormProps = {}

const EducationCourseAddForm: React.FC<EducationCourseAddFormProps> = React.memo(() => {

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<EducationCourseFormFields>({
        initialValues: initialEducationCourseFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: educationCourseValidate
    })

    const antField = getAntField<EducationCourseFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.educationCourse.create,
        {
            mutationKey: 'education-course-add',
            onSuccess() {
                invalidateQueries(queryKeysEducationCourse[0])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.log('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 4000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialEducationCourseFields)
        setErrors(clearEducationCourseErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[antField('name')]}>
            <EducationCourseFormInputs
                handleChange={handleChange}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default EducationCourseAddForm