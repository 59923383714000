import React from 'react'
import './NavbarHeader.scss'
import { Menu, Dropdown } from 'antd'
import { Link } from 'react-router-dom'
import { BulbOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { lockBodySmall, unlockBodySmall, toggleTheme } from '../../../../../utils/styleUtils';
import { authStore } from '../../../../../store/auth';
import { observer } from 'mobx-react-lite';

type NavbarHeaderProps = {
    isOpenedSideMenu: boolean
    setOpenedSideMenu: (val: boolean) => void
}

const NavbarHeader: React.FC<NavbarHeaderProps> = observer((props) => {
    const {
        isOpenedSideMenu, setOpenedSideMenu,
    } = props

    const handleClickBurger = () => {
        if (isOpenedSideMenu) unlockBodySmall()
        if (!isOpenedSideMenu) lockBodySmall()
        setOpenedSideMenu(!isOpenedSideMenu)
    }

    const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        authStore.logout()
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <Link to="/auth" onClick={handleLogout}>
                    Выйти
                </Link>
            </Menu.Item>
        </Menu>
    )

    return (
        <header className="uif-header">
            <div className="uif-header__content">
                <Link to="/" className="uif-header__logo">
                    UIF Admin
                </Link>

                <div className="uif-header__controls">
                    <Dropdown overlay={menu} placement="bottomRight" arrow>
                        <UserOutlined className="uif-header__control-user">
                        </UserOutlined>
                    </Dropdown>
                    <button className="uif-header__burger-btn uif-btn" onClick={handleClickBurger}>
                        <MenuUnfoldOutlined />
                    </button>
                    <button
                        title="Темная/Светлая тема"
                        className="uif-header__theme-btn uif-btn"
                        onClick={toggleTheme}
                    >
                    <BulbOutlined />
                    </button>
                </div>
            </div>
        </header>
    )
})

export default NavbarHeader