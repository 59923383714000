import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { DirectionGroupObj, ObjDB, UploadObj } from '../../../../../types/apiTypes';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import { DirectionGroupsFormFields } from '../../../../../types/formFieldTypes';
import EditPlaceholder from '../../../../../components/common/EditPlaceholder/EditPlaceholder';
import { getAntField } from '../../../../../utils/form';
import { clearDirectionGroupsErrors, directionGroupsValidate, initialDirectionGroupsFields } from '../../formData/DirectionGroupsFormData';
import DirectionGroupsFormInputs from '../DirectionGroupsFormInputs/DirectionGroupsFormInputs';
import { errorNotification, invalidateQueries, previewQueryOptions } from '../../../../../utils/rest';
import { queryKeysDirectionGroups } from '../../../../../static/data/queryKeys';


type DirectionGroupsEditFormProps = {
    editItem: ObjDB<DirectionGroupObj> | null
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
    setEditItem: React.Dispatch<React.SetStateAction<ObjDB<DirectionGroupObj> | null>>
}

const DirectionGroupsEditForm: React.FC<DirectionGroupsEditFormProps> = React.memo((props) => {
    const {
        editItem, setOpenedForm, setEditItem
    } = props

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange, setFieldValue,
        submitForm, setValues, setErrors,
    } = useFormik<DirectionGroupsFormFields>({
        initialValues: initialDirectionGroupsFields,
        onSubmit: (values: DirectionGroupsFormFields) => {
            mutate(values)
        },
        validate: directionGroupsValidate
    })

    const antField = getAntField<DirectionGroupsFormFields>(values, errors, touched)

    const mutationAvatar = useMutation(
        api.file.add,
        {
            mutationKey: 'directoion-group-avatar-add',
            onSuccess(data: ObjDB<UploadObj>) {
                setFieldValue('avatar_id', data.id)
                setFieldValue('_avatarSrc', data.path)
            },
            onError: (err: Error) => {
                errorNotification(err, 'Ошибка при загрузке файла, загрузите файл заново')
                setFieldValue('avatar_id', 0)
                setFieldValue('_avatarSrc', '')
            },
        }
    )

    const defaultAvatarMutate = useMutation<ObjDB<UploadObj>, Error, number, void>(
        'directoion-group-avatar-get',
        api.file.get,
        {...previewQueryOptions(formStatus)}
    )

    const { mutate } = useMutation(
        async (values: DirectionGroupsFormFields) => {
            if (editItem) return await api.directionGroups.edit(values, editItem)
        },
        {
            mutationKey: 'direction-group-edit',
            onSuccess() {
                invalidateQueries(queryKeysDirectionGroups[0])
                formStatus.setMessage('Данные успешно изменены')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.error('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 5000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            },
        }
    )

    const clearForm = () => {
        setValues(initialDirectionGroupsFields)
        setErrors(clearDirectionGroupsErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setOpenedForm(false)
        setTimeout(() => {
            setEditItem(null)
            formStatus.reset()
        }, 300)
    }

    const resetChanges = () => {
        if (editItem) {
            const { id, ...itemFields } = editItem
            setValues({...itemFields, _avatarSrc: ''})
            setErrors(clearDirectionGroupsErrors)
        }
    }

    useEffect(() => {
        if (editItem) {
            if (editItem.avatar_id) defaultAvatarMutate.mutate(editItem.avatar_id)
            return resetChanges()
        }
        clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    const handleChangeImg = (file: File | null) => {
        if (file) mutationAvatar.mutate(file)
    }

    if (!editItem) return <EditPlaceholder />

    return (
        <Form
            fields={[antField('name')]}
        >
            {
                editItem &&
                <p className="uif-ant-form-title">
                    ID редактируемого объекта: <span>{editItem.id}</span>
                </p>
            }
            <DirectionGroupsFormInputs
                values={values}
                errors={errors}
                defaultImgSrc={defaultAvatarMutate.data?.path}
                isFetchingImg={mutationAvatar.isLoading}
                onChangeImg={handleChangeImg}
                handleChange={handleChange}
                submitForm={submitForm}
                resetChanges={resetChanges}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default DirectionGroupsEditForm