import React from 'react'
import { ReactNode } from 'react'
import { replaceEntersToSpan } from '../../../utils/jsxUtils'
import './TableDropDescription.scss'

type TableDropDescriptionItem = {
    title: string
    text?: string
    TextNode?: ReactNode
}

type TableDropDescriptionProps = {
    data: TableDropDescriptionItem[]
}

const TableDropDescription: React.FC<TableDropDescriptionProps> = ({ data }) => {
    return (
        <div className="table-drop-description">
            {
                data.map((item, i) => (
                    <div className="table-drop-description__item" key={i}>
                        <p className="table-drop-description__item-title">
                            {item.title}
                        </p>
                        {
                            item.text &&
                            <p className="table-drop-description__item-text">
                                {
                                    replaceEntersToSpan(item.text)
                                }
                            </p>
                        }
                        <div className="table-drop-description__item-node-text">
                            {
                                item.TextNode &&
                                item.TextNode
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default TableDropDescription