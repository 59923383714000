import { TrainingProgramsFormFields } from "../../../../types/formFieldTypes"
import { openNotification } from "../../../../utils/jsxUtils"

export const trainingProgramsValidate = (values: TrainingProgramsFormFields) => {
    const errors = {} as { [key: string]: string }

    if (!values.education_course_id) {
        errors.course_id = 'Выберите курс обучения'
        openNotification('error', 'Неверный id курса обучения', 'Попробуйте вернуться к списку курсов обучения, а затем заново создать данный объект')
    }
    if (!values.title) errors.title = 'Введите заголовок'
    if (!values.text) errors.text = 'Введите текст'
    if (!values.count_hours) errors.count_hours = 'Укажите количество часов'
    return errors
}

export const initialTrainingProgramsFields = (education_course_id: number | string): TrainingProgramsFormFields => ({
    education_course_id: +education_course_id || 0,
    title: '',
    text: '',
    count_hours: 0,
    number: 0,
})

export const clearTrainingProgramsErrors = {
    education_course_id: '',
    title: '',
    text: '',
    count_hours: '',
    number: '',
}
