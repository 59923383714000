import React from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { SalaryFormFields } from '../../../../../types/formFieldTypes';
import { getAntField } from '../../../../../utils/form';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { salaryValidate, initialSalaryFields, clearSalaryErrors } from '../../formData/SalaryFormData';
import CourseFormInputs from '../SalaryFormInputs/SalaryFormInputs'
import { SwitchSalaryItems } from '../../../../../types/propsTypes';
import { queryKeysSalary } from '../../../../../static/data/queryKeys';

type SalaryAddFormProps = {
    switchDataItems: SwitchSalaryItems
}

const SalaryAddForm: React.FC<SalaryAddFormProps> = React.memo(({switchDataItems}) => {

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange, setFieldValue,
        submitForm, setValues, setErrors,
    } = useFormik<SalaryFormFields>({
        initialValues: initialSalaryFields,
        validateOnChange: false,
        onSubmit: (values) => {
            mutate(values)
        },
        validate: salaryValidate
    })

    const antField = getAntField<SalaryFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        api.salaries.create,
        {
            mutationKey: 'salary-add',
            onSuccess() {
                invalidateQueries(queryKeysSalary[0])
                formStatus.setMessage('Объект успешно добавлен')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка создания', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            },
        }
    )

    const clearForm = () => {
        setValues(initialSalaryFields)
        setErrors(clearSalaryErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setTimeout(formStatus.reset, 300)
    }

    return (
        <Form fields={[
            antField('salary'),
            antField('course_id', {withFalsy: true}),
            antField('text'),
        ]}>
            <CourseFormInputs
                switchDataItems={switchDataItems}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                clearForm={clearForm}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default SalaryAddForm