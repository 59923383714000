import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { TypeOfTrainingObj, ObjDB } from '../../../../../types/apiTypes';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import EditPlaceholder from '../../../../../components/common/EditPlaceholder/EditPlaceholder';
import { getAntField } from '../../../../../utils/form';
import { TypeOfTrainingFormFields } from '../../../../../types/formFieldTypes';
import { errorNotification, invalidateQueries } from '../../../../../utils/rest';
import { clearTypeOfTrainingErrors, initialTypeOfTrainingFields, typeOfTrainingValidate } from '../../formData/TypeOfTrainingPageFormData';
import TypeOfTrainingFormInputs from '../TypeOfTrainingFormInputs/TypeOfTrainingFormInputs';
import { queryKeysTypeOfTraining } from '../../../../../static/data/queryKeys';

type TypeOfTrainingEditFormProps = {
    editItem: ObjDB<TypeOfTrainingObj> | null
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
    setEditItem: React.Dispatch<React.SetStateAction<ObjDB<TypeOfTrainingObj> | null>>
}

const TypeOfTrainingEditForm: React.FC<TypeOfTrainingEditFormProps> = React.memo((props) => {
    const {
        editItem, setOpenedForm, setEditItem,
    } = props

    const formStatus = useFormStatus()

    const {
        errors, values, touched,
        handleChange,
        submitForm, setValues, setErrors,
    } = useFormik<TypeOfTrainingFormFields>({
        initialValues: initialTypeOfTrainingFields,
        onSubmit: (values) => {
            mutate(values)
        },
        validateOnChange: false,
        validate: typeOfTrainingValidate
    })

    const antField = getAntField<TypeOfTrainingFormFields>(values, errors, touched)

    const {
        mutate
    } = useMutation(
        async (values: TypeOfTrainingFormFields) => {
            if (editItem) return await api.typeOfTrainings.edit(values, editItem)
        },
        {
            mutationKey: 'type-training-edit',
            onSuccess() {
                invalidateQueries(queryKeysTypeOfTraining[0])
                formStatus.setMessage('Данные успешно изменены')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка редактирования', formStatus),
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            }
        }
    )

    const clearForm = () => {
        setValues(initialTypeOfTrainingFields)
        setErrors(clearTypeOfTrainingErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setOpenedForm(false)
        setTimeout(() => {
            setEditItem(null)
            formStatus.reset()
        }, 300)
    }

    const resetChanges = () => {
        if (editItem) {
            const { id, ...values } = editItem
            setValues(values)
            setErrors(clearTypeOfTrainingErrors)
        }
    }

    useEffect(() => {
        if (editItem) return resetChanges()
        clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    if (!editItem) return <EditPlaceholder />

    return (
        <Form
            fields={[ antField('name') ]}
        >
            {
                editItem &&
                <p className="uif-ant-form-title">
                    ID редактируемого объекта: <span>{editItem.id}</span>
                </p>
            }

            <TypeOfTrainingFormInputs
                handleChange={handleChange}
                resetChanges={resetChanges}
                submitForm={submitForm}
            />

            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default TypeOfTrainingEditForm