import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import React from 'react'
import './TableActionButtons.scss'
import { Popconfirm } from 'antd';
import { confirmIcon } from '../../../../../utils/jsxUtils';

type TableActionButtonsProps = {
    onEdit?: () => void
    onDelete?: () => void
}

const TableActionButtons: React.FC<TableActionButtonsProps> = (props) => {
    const {
        onEdit, onDelete,
    } = props

    return (
        <>
            {
                onEdit &&
                <button
                    onClick={onEdit}
                    className="table-action-button uif-btn uif-btn-white"
                >
                    <EditOutlined style={{ color: '#1d89e6' }} />
                </button>
            }
            {
                onDelete &&
                <Popconfirm
                    title="Удалить объект?"
                    onConfirm={onDelete}
                    okText="Да"
                    cancelText="Нет"
                    icon={confirmIcon()}
                >
                    <button className="table-action-button uif-btn uif-btn-white">
                        <DeleteOutlined style={{ color: 'red' }} />
                    </button>
                </Popconfirm>
            }
        </>
    )
}

export default TableActionButtons