import { Image } from 'antd';
import React from 'react'
import { HelprerContentItem, ImageFieldHelper, TitleFieldHelper } from '../../../../../types/propsTypes';
import './FieldHelperContent.scss'

type FieldHelperContentProps = {
    data: Array<HelprerContentItem>
}

const FieldHelperContent: React.FC<FieldHelperContentProps> = ({ data }) => {

    const isImg = (item: HelprerContentItem): item is ImageFieldHelper => typeof item === 'object' && item.type === 'image'
    const isTitle = (item: HelprerContentItem): item is TitleFieldHelper => typeof item === 'object' && item.type === 'title'

    return (
        <div className="field-helper-content">
            {
                data.map((item, idx) => {
                    if (typeof item === 'string') return (
                        <p
                            key={idx}
                            className="field-helper-content__text"
                        >
                            {item}
                        </p>
                    )

                    if (isTitle(item)) return (
                        <p
                            key={idx}
                            className="field-helper-content__title"
                        >
                            {item.text}
                        </p>
                    )

                    if (isImg(item)) return (
                        <div
                            key={idx}
                            className="field-helper-content__img-block"
                        >
                            <Image
                                src={item.imgSrc}
                                alt="."
                                className="field-helper-content__img"
                            />
                            {
                                item.description &&
                                <p className="field-helper-content__img-description">{item.description}</p>
                            }
                        </div>
                    )
                    return null
                })
            }
        </div>
    )
}


export default FieldHelperContent