export const tableLocale = {
    filterTitle: 'Фильтр',
    filterConfirm: 'Ок',
    filterReset: 'Сброс',
    filterEmptyText: 'Нет фильтров',
    selectAll: 'Выбрать страницу',
    selectInvert: 'Инвертировать страницу',
    selectionAll: 'Выбрать всё',
    sortTitle: 'Sort',
    expand: 'Развернуть строку',
    collapse: 'Свернуть строку',
    triggerDesc: 'Сортировать по убыванию',
    triggerAsc: 'Сортировать по возрастанию',
    cancelSort: 'Отменить сортировку',
}