import { TrainingStreamsFormFields } from "../../../../types/formFieldTypes"
import { sortByDate } from '../../../../utils/form'

export const trainingStreamsValidate = (values: TrainingStreamsFormFields) => {
    const errors = {} as { [key: string]: string }

    if (!values.name) errors.name = 'Укажите имя'
    if (!values.course_id) errors.course_id = 'Выберите направление'
    if (!values.education_course_id) errors.education_course_id = 'Выберите курс обучения'
    if (values.city_id === null) errors.city_id = 'Выберите город'
    if (!values.date_from) errors.date_from = 'Выберите дату начала'
    if (!values.date_to) errors.date_to = 'Выберите дату окончания'
    if (values.date_from && values.date_to) {
        if (sortByDate(values.date_from, values.date_to) >= 0) {
            errors.date_to = 'Дата окончания наступит раньше даты начала, введите корректные даты'
        }
    }
    return errors
}

export const initialTrainingStreamsFields: TrainingStreamsFormFields = {
    name: '',
    course_id: 0,
    city_id: null,
    date_from: '',
    date_to: '',
    education_course_id: 0,
}

export const clearTrainingStreamsErrors = {
    name: '',
    course_id: '',
    date_from: '',
    date_to: '',
    city_id: '',
    education_course_id: '',
}
