import React, { useState } from 'react'
import { isOpenedMenuStorage } from '../../../utils/styleUtils'
import MemoChildren from '../../hoc/MemoChildren'
import NavbarHeader from './components/NavbarHeader/NavbarHeader'
import NavbarSideMenu from './components/NavbarSideMenu/NavbarSideMenu'
import './NavbarLayout.scss'

type NavbarLayoutProps = {}

const NavbarLayout: React.FC<NavbarLayoutProps> = React.memo((props) => {
    const {
        children
    } = props

    const [isOpenedSideMenu, setOpenedSideMenu] = useState<boolean>(isOpenedMenuStorage())

    const handleOpenMenu = (val: boolean) => {
        setOpenedSideMenu(val)
        if (val) return localStorage.setItem('showSideMenu', 'true')
        localStorage.setItem('showSideMenu', '')
    }

    return (
        <>
            <NavbarHeader
                isOpenedSideMenu={isOpenedSideMenu}
                setOpenedSideMenu={handleOpenMenu}
            />

            <div className="uif-nav-layout">
                <NavbarSideMenu
                    isOpen={isOpenedSideMenu}
                    setOpen={handleOpenMenu}
                />

                <div className={
                    isOpenedSideMenu
                        ? "uif-nav-layout__page-container"
                        : "uif-nav-layout__page-container collapse-menu"
                    }
                >
                    <MemoChildren memoChildren={children}/>
                </div>
            </div>
        </>
    )
})


export default NavbarLayout