import React, { useEffect } from 'react'
import { Form } from 'antd';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { api } from '../../../../../api/api';
import { CourseBGObj, ObjDB, UploadObj } from '../../../../../types/apiTypes';
import FormStatus from '../../../../../components/form/FormStatus/FormStatus';
import { useFormStatus } from '../../../../../utils/hooks/useFormStatus';
import { CourseBGFormFields } from '../../../../../types/formFieldTypes';
import EditPlaceholder from '../../../../../components/common/EditPlaceholder/EditPlaceholder';
import { CourseBGFormInputs } from '../CourseBGPageFormInputs/CourseBGPageFormInputs';
import { clearCourseBGErrors, courseBGValidate, initialCourseBGFields } from '../../formData/CourseBGPageFormData';
import { errorNotification, invalidateQueries, previewQueryOptions } from '../../../../../utils/rest';
import { queryKeysCourseBG } from '../../../../../static/data/queryKeys';

type CourseBGEditFormProps = {
    editItem: ObjDB<CourseBGObj> | null
    setOpenedForm: React.Dispatch<React.SetStateAction<boolean>>
    setEditItem: React.Dispatch<React.SetStateAction<ObjDB<CourseBGObj> | null>>
}

const CourseBGEditForm: React.FC<CourseBGEditFormProps> = React.memo((props) => {
    const {
        editItem, setOpenedForm, setEditItem
    } = props

    const formStatus = useFormStatus()

    const {
        errors, values,
        setFieldValue,
        submitForm, setValues, setErrors,
    } = useFormik<CourseBGFormFields>({
        initialValues: initialCourseBGFields,
        onSubmit: (values: CourseBGFormFields) => {
            mutate(values)
        },
        validate: courseBGValidate,
        validateOnChange: false,
    })

    const {
        mutate
    } = useMutation(
        async (values: CourseBGFormFields) => {
            if (editItem) return await api.courseBG.edit(values, editItem)
        },
        {
            mutationKey: 'course-bg-edit',
            onSuccess() {
                invalidateQueries(queryKeysCourseBG[0])
                formStatus.setMessage('Данные успешно изменены')
                formStatus.setStatus('success')
                setTimeout(clearEditItem, 1000)
            },
            onError(err: Error) {
                console.error('ERROR', err)
                formStatus.setMessage(`Ошибка: ${err.message}`)
                formStatus.setStatus('error')
                setTimeout(clearEditItem, 5000)
            },
            onMutate() {
                formStatus.setMessage('Отправка данных')
                formStatus.setStatus('fetching')
            },
        }
    )

    const avatarMutate = useMutation<ObjDB<UploadObj>, Error, number, void>(
        'avatar-mutate-edit',
        api.file.get,
        {...previewQueryOptions(formStatus)}
    )

    const mutationFile = useMutation(
        api.file.add,
        {
            mutationKey: 'avatar-add-edit',
            onSuccess(data: ObjDB<UploadObj>) {
                setFieldValue('background_file_id', data.id)
                setFieldValue('_imgSrc', data.path)
            },
            onError: (err: Error) => {
                errorNotification(err, 'Ошибка при загрузке файла, загрузите файл заново')
                setFieldValue('background_file_id', 0)
                setFieldValue('_imgSrc', '')
            },
        }
    )

    const clearForm = () => {
        setValues(initialCourseBGFields)
        setErrors(clearCourseBGErrors)
    }

    const clearEditItem = () => {
        clearForm()
        setOpenedForm(false)
        setTimeout(() => {
            setEditItem(null)
            formStatus.reset()
        }, 300)
    }

    const resetChanges = () => {
        if (editItem) {
            setValues({
                ...editItem,
                _imgSrc: editItem.img.path,
            })
            setErrors(clearCourseBGErrors)
        }
    }

    const onChangeImg = (file: File | null) => {
        if (file) mutationFile.mutate(file)
    }

    useEffect(() => {
        if (editItem) return resetChanges()
        clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editItem])

    if (!editItem) return <EditPlaceholder />

    return (
        <Form>
            {
                editItem &&
                <p className="uif-ant-form-title">
                    ID редактируемого объекта: <span>{editItem.id}</span>
                </p>
            }
            <CourseBGFormInputs
                values={values}
                errors={errors}
                defaultImgSrc={avatarMutate.data?.path}
                isFetchingImg={mutationFile.isLoading}
                onChangeImg={onChangeImg}
                resetChanges={resetChanges}
                submitForm={submitForm}
            />
            <FormStatus formStatus={formStatus} />
        </Form>
    )
})

export default CourseBGEditForm