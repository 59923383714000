import React, { useState } from 'react'
import './AuthPage.scss'
import {
    Form,
    Input
} from 'antd';
import { useFormik } from 'formik';
import { AuthFormFields } from '../../types/formFieldTypes';
import { getAntField } from '../../utils/form';
import { useMutation } from 'react-query';
import { authApi } from '../../api/authApi';
import { Spin } from 'antd';
import { BulbOutlined, LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../store/auth';
import { toggleTheme } from '../../utils/styleUtils';

type AuthPageProps = {}

const AuthPage: React.FC<AuthPageProps> = observer((props) => {

    const [formError, setFormError] = useState<string>('')
    const [showError, setShowError] = useState<boolean>(false)

    const {
        values, errors, touched,
        setFieldValue, submitForm,
    } = useFormik<AuthFormFields>({
        initialValues: {
            login: '',
            password: ''
        },
        validate(values) {
            const errors = {} as { [key: string]: string }
            if (!values.login) errors.login = 'Введите логин'
            if (values.password.length < 6) errors.password = 'Минимум 6 символов'
            return errors
        },
        validateOnChange: false,
        onSubmit(values) {
            authMutate.mutate(values)
        }
    })

    const antField = getAntField<AuthFormFields>(values, errors, touched)

    const authMutate = useMutation(
        authApi.login,
        {
            mutationKey: 'auth-login',
            onSuccess(data) {
                resetFormError()
                authStore.login(data)
            },
            onError(err: Error) {
                console.warn('login error', err)
                setShowError(true)
                if (err.message.includes('401')) return setFormError('Неверный логин или пароль')
                setFormError('Возникла ошибка')
            },
            onMutate() {
                resetFormError()
            }
        }
    )

    const resetFormError = () => {
        setShowError(false)
        setTimeout(() => setFormError(''), 100)
    }

    return (
        <section className="uif-auth-page">
            <div className="uif-container">
                <div className="uif-auth-page__form-wrapper">
                    <p className="uif-auth-page__title">UIF Admin</p>
                    <Form
                        fields={[
                            antField('login'),
                            antField('password'),
                        ]}
                        className="uif-auth-page__form"
                    >

                        <div className="uif-auth-page__form-inputs">
                            <Form.Item
                                name="login"
                                label="Логин"
                                className="uif-ant-form-item"
                            >
                                <Input
                                    size="large"
                                    onChange={e => setFieldValue('login', e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label="Пароль"
                                className="uif-ant-form-item"
                            >
                                <Input.Password
                                    size="large"
                                    onChange={e => setFieldValue('password', e.target.value)}
                                />
                            </Form.Item>

                            <Spin
                                indicator={
                                    <LoadingOutlined spin />
                                }
                                className={
                                    authMutate.isLoading
                                        ? "uif-auth-page__loader show"
                                        : "uif-auth-page__loader"
                                }
                            />
                            <p className={
                                showError
                                    ? "uif-auth-page__form-error show"
                                    : "uif-auth-page__form-error"
                            }
                            >
                                {formError}
                            </p>
                        </div>

                        <button
                            className="uif-auth-page__submit-btn uif-btn"
                            onClick={submitForm}
                        >
                            Войти
                        </button>
                    </Form>
                </div>
            </div>
            <button className="uif-theme-btn uif-btn" onClick={toggleTheme}>
                <BulbOutlined />
            </button>
        </section>
    )
})


export default AuthPage