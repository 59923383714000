import { HelprerContentItem } from "../../../../types/propsTypes";

export const directionGroupsHelpers = {
    pageTitle: [
        {
            type: 'title',
            text: 'Группы направлений'
        },
        'Отобажается на главной странице. Также выступает в качестве фильтра для каталога курсов',
        {
            type: 'image',
            imgSrc: '/static/img/helper/page-title/direction-groups.jpg',
            description: 'Блок на главной странице',
        },
        {
            type: 'image',
            imgSrc: '/static/img/helper/page-title/direction-groups-filter.jpg',
            description: 'Фильтр в каталоге курсов',
        }
    ] as HelprerContentItem[]
}