import React, { Profiler, useMemo, useState } from 'react'
import NavbarLayout from '../../../components/layout/NavbarLayout/NavbarLayout'
import './PricePage.scss'
import SoloCollapse from '../../../components/ui/SoloCollapse/SoloCollapse';
import PageTitle from '../../../components/common/PageTitle/PageTitle';
import {
    ColumnsType,
} from 'antd/lib/table/interface';
import TableObjects from '../../../components/ui/TableObjects/TableObjects';
import { useTableObject } from '../../../utils/hooks/useTableObject';
import { addCollapseIcon, editCollapseIcon } from '../../../components/ui/SoloCollapse/components/icons';
import TableActionButtons from '../../../components/ui/TableObjects/components/TableActionButtons/TableActionButtons';
import { useMutation, useQuery } from 'react-query';
import { CityObj, CourseWithSummaryObj, ObjDB, PriceObj } from '../../../types/apiTypes';
import { api } from '../../../api/api';
import { getSelectedItems } from '../../../utils/parsers';
import { openNotification, tableLinkId } from '../../../utils/jsxUtils';
import { RouteComponentProps, useParams } from 'react-router';
import { errorNotification, invalidateQueries, optionsQueryGetAll } from '../../../utils/rest';
import { sortByDate } from '../../../utils/form';
import { SwitchPriceItems } from '../../../types/propsTypes';
import ParsedDate from '../../../components/table/ParsedDate/ParsedDate';
import PriceAddForm from './components/PriceAddForm/PriceAddForm';
import PriceEditForm from './components/PriceEditForm/PriceEditForm';
import { filterTableLink, sortTableLinkByName } from '../../../utils/table';
import { queryKeysPrice } from '../../../static/data/queryKeys';
import useScrollMount from '../../../utils/hooks/useScrollMount';

interface PricePageProps extends RouteComponentProps {}

type TableItem = ObjDB<PriceObj>


const PricePage: React.FC<PricePageProps> = () => {

    const [openedAddForm, setOpenedAddForm] = useState<boolean>(false)
    const [openedEditForm, setOpenedEditForm] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<TableItem | null>(null)
    const [tableHeadLoader, setTableHeadLoader] = useState<boolean>(false)

    const params = useParams<{ id: string }>()

    const {
        selectedRowKeys,
        searchText,
        setSelectedRowKeys,
        getColumnSearchProps,
    } = useTableObject({initialSearchText: params.id})

    const queryCourse = useQuery<CourseWithSummaryObj[], Error, ObjDB<CourseWithSummaryObj>[]>(
        queryKeysPrice[0],
        api.courses.getAll,
        { ...optionsQueryGetAll }
    )

    const queryCity = useQuery<undefined, Error, ObjDB<CityObj>[]>(
        queryKeysPrice[1],
        api.cities.getAll,
        { ...optionsQueryGetAll }
    )

    const switchData: SwitchPriceItems = {
        courses: queryCourse.data || [],
        cities: queryCity.data || [],
    }

    const queryAll = useQuery<TableItem[], Error, TableItem[]>(
        queryKeysPrice[2],
        api.prices.getAll,
        { ...optionsQueryGetAll }
    )

    const queryDelete = useMutation(
        'price-delete',
        api.prices.delete,
        {
            onSuccess() {
                openNotification('success', 'Объект успешно удален')
                invalidateQueries(queryKeysPrice[2])
            },
            onError: (err: Error) => errorNotification(err, 'Ошибка при удалении объекта')
        }
    )

    const columns: ColumnsType<TableItem> = useMemo(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                sorter: {
                    compare: (a, b) => a.id - b.id,
                },
                ...getColumnSearchProps('id'),
                defaultFilteredValue: params.id === searchText ? [params.id] : undefined,
            },
            {
                title: 'Название',
                dataIndex: 'name',
                sorter: { compare: (a, b) => a.name.localeCompare(b.name) },
                ...getColumnSearchProps('name'),
            },
            {
                title: 'Курс',
                dataIndex: 'course_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.course_id, b.course_id, queryCourse.data || []) },
                ...getColumnSearchProps('course_id'),
                onFilter: (val, record) => filterTableLink(val, record.course_id, queryCourse.data || []),
                render: (id) => tableLinkId(queryCourse.data || [], id, 'course'),
            },
            {
                title: 'Город',
                dataIndex: 'city_id',
                sorter: { compare: (a, b) => sortTableLinkByName(a.city_id, b.city_id, queryCity.data || []) },
                ...getColumnSearchProps('city_id'),
                onFilter: (val, record) => filterTableLink(val, record.city_id, queryCity.data || []),
                render: (id: number) => tableLinkId(queryCity.data || [], id, 'cities'),
                width: 150,
            },
            {
                title: 'Цена',
                dataIndex: 'price',
                sorter: { compare: (a, b) => a.price - b.price },
                ...getColumnSearchProps('price'),
            },
            {
                title: 'Дата начала',
                dataIndex: 'date_from',
                sorter: { compare: (a, b) => sortByDate(a.date_from, b.date_from) },
                render: (val: string) => <ParsedDate timeDB={val} />
            },
            {
                title: 'Дата окончания',
                dataIndex: 'date_to',
                sorter: { compare: (a, b) => sortByDate(a.date_to, b.date_to) },
                render: (val: string) => <ParsedDate timeDB={val} />
            },
            {
                title: 'Действие',
                dataIndex: '',
                align: 'right',
                render: (value: TableItem) => (
                    <TableActionButtons
                        onEdit={() => handleEditItem(value)}
                        onDelete={() => queryDelete.mutate(value)}
                    />
                )
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumnSearchProps])

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const handleTableAdd = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setOpenedAddForm(true)
    }

    const handleEditItem = (value: TableItem) => {
        setEditItem(value)
        setOpenedEditForm(true)
        setOpenedAddForm(false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleTableDelete = async () => {
        try {
            setTableHeadLoader(true)
            const deleteItems = getSelectedItems<TableItem>(selectedRowKeys, queryAll.data || [])
            await api.prices.deleteMany(deleteItems)
            invalidateQueries(queryKeysPrice[2])
            openNotification(
                'success',
                'Выбранные объекты успешно удалены'
            )
            setSelectedRowKeys([])
        } catch (err) {
            errorNotification(err, 'Ошибка при удалении объектов')
        }
        finally { setTableHeadLoader(false) }
    }

    const handleTableRefresh = () => invalidateQueries(queryKeysPrice)
    useScrollMount()

    return (
        <NavbarLayout>
            <Profiler id="23123" onRender={() => { }}>
                <section className="uif-price-page">
                    <PageTitle text="Цены | Prices" />

                    <div className="uif-price-page__collapses">
                        <SoloCollapse
                            header="Создать новый объект"
                            keyCollapse="add"
                            show={openedAddForm}
                            icon={addCollapseIcon}
                            setShow={setOpenedAddForm}
                        >
                            <PriceAddForm
                                switchData={switchData}
                                setOpenedForm={setOpenedAddForm}
                            />
                        </SoloCollapse>
                        <SoloCollapse
                            header="Редактировать объект"
                            keyCollapse="edit"
                            show={openedEditForm}
                            icon={editCollapseIcon}
                            setShow={setOpenedEditForm}
                        >
                            <PriceEditForm
                                editItem={editItem}
                                switchData={switchData}
                                setOpenedForm={setOpenedEditForm}
                                setEditItem={setEditItem}
                            />
                        </SoloCollapse>
                    </div>

                    <TableObjects
                        selectedRowKeys={selectedRowKeys}
                        count={queryAll.data?.length || 0}
                        columns={columns}
                        dataSource={queryAll.data}
                        rowSelection={{
                            selectedRowKeys: selectedRowKeys,
                            onChange: onSelectChange
                        }}
                        onDelete={handleTableDelete}
                        onAdd={handleTableAdd}
                        onRefresh={handleTableRefresh}
                        rowKey="id"
                        isFetching={queryAll.isFetching}
                        headerLoader={tableHeadLoader}
                        scroll={{ x: 1000 }}
                    />
                </section>
            </Profiler>
        </NavbarLayout >
    )
}

export default PricePage