import React, { Profiler } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@styles/index.scss'
import 'antd/dist/antd.css';
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter } from 'react-router-dom'
import { ReactQuery } from './components/hoc/ReactQuery';
registerLocale('ru', ru)

const app = (
    <BrowserRouter>
        <React.StrictMode>
            <ReactQuery>
                <Profiler id="dd" onRender={() => {}}>
                    <App />
                </Profiler>
            </ReactQuery>
        </React.StrictMode>
    </BrowserRouter>
)

ReactDOM.render(app, document.getElementById('root'))
reportWebVitals()
