import { EditOutlined } from '@ant-design/icons'
import React from 'react'

const EditPlaceholder: React.FC<{}> = () => {
    return (
        <p>Выберите из таблицы объект для редактирования, нажав <EditIcon /> на объекте</p>
    )
}

const EditIcon = () => <EditOutlined style={{ color: '#1d89e6' }} />

export default EditPlaceholder