import React from 'react'
import './SoloCollapse.scss'
import { Collapse, CollapseProps } from 'antd';
const { Panel } = Collapse;

interface SoloCollapseProps extends CollapseProps {
    header: string
    show: boolean
    setShow: (value: boolean) => void
    keyCollapse: string
    icon?: React.ReactNode
}

const SoloCollapse: React.FC<SoloCollapseProps> = React.memo((props) => {
    const {
        show, keyCollapse, children,
        icon, header,
        setShow,
        ...collapseProps
    } = props

    const handleChange = (value: string | string[]) => {
        if (Array.isArray(value) && value.length) return setShow(true)
        setShow(false)
    }

    return (
        <Collapse
            activeKey={show ? [keyCollapse] : []}
            expandIconPosition="right"
            className="uif-collapse"
            onChange={handleChange}
            {...collapseProps}
        >
            <Panel
                header={header}
                key={keyCollapse}
                extra={icon}
                className="uif-collapse__item"
            >
                {children}
            </Panel>
        </Collapse>
    )
})

export default SoloCollapse