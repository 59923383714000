import axios from "axios";
import { loginUrl, refreshUrl, logoutUrl } from "../config/urls";
import { accessTokenCookieName, refreshTokenCookieName } from "../config/vars";
import { AuthResponseData } from "../types/apiTypes";
import { AuthFormFields } from "../types/formFieldTypes";
import { getCookie } from "../utils/cookie";
import { authOptions } from "../utils/rest";

export const authApi = {
    async login(values: AuthFormFields) {
        const res = await axios.post(loginUrl, values)
        const data = res.data as AuthResponseData
        return data
    },
    async refresh(refresh_token: string) {
        const res = await axios.post(refreshUrl, {refresh_token})
        const data = res.data as AuthResponseData
        return data
    },
    async logout() {
        const access = getCookie(accessTokenCookieName)
        const refresh = getCookie(refreshTokenCookieName)
        if (access && refresh) {
            const body = { refresh_token: refresh }
            await axios.post(logoutUrl, body, authOptions(access))
        }
    }
}